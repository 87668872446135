import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MatchHelper from 'components/helpers/match-helper';
import styles from './hockey-match-widget.module.less';
import SportEventService from 'services/sport-event-service';
import TeamsAndResultBar from 'components/block-elements/teams-and-result-bar/teams-and-result-bar';
import DoughnutChart from 'components/block-elements/doughnut-chart/doughnut-chart';
import HorizontalStackedBarChart from 'components/block-elements/horizontal-stacked-bar-chart/horizontal-stacked-bar-chart';
import BarChart from 'components/block-elements/bar-chart/bar-chart';
import goalIcon from 'styles/icons/icons/goal-hockey.png';
import goalieIcon from 'styles/icons/icons/goalie-hockey.png';
import matchNotStartedIcon from 'styles/icons/icons/match_not_started_icon.svg';
import { liveUpdateInterval } from 'components/stores/live-matches-context';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import EventAggregator from 'components/helpers/event-aggregator';
import ColumnChart from '../column-chart/column-chart';
import CircularProgress from 'components/blocks/circle-progress-bar/circle-progress-bar';
import Timeline from 'components/block-elements/timeline/timeline';
import LandInformationWidget from 'components/block-elements/land-information-widget/land-information-widget';

class HockeyMatchWidget extends Component {
  static propTypes = {
    matchId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.matchId = props.matchId;
    this._isMounted = false;
    this.widgetLightRedColor = '#C96A78';
    this.widgetDarkRedColor = '#AC1A2F';
    const HANDELSER_VALUE = 'HANDELSER';
    const LIVESTATISTIK_VALUE = 'LIVESTATISTIK';
    const REFERAT_VALUE = 'REFERAT';

    this.buttonAlterantives = [
      {
        buttonText: 'Händelser',
        value: HANDELSER_VALUE,
      },
      {
        buttonText: 'LIVESTATISTIK',
        value: LIVESTATISTIK_VALUE,
      },
      // {
      //   buttonText: "REFERAT",
      //   value: REFERAT_VALUE,
      // },
    ];
    this.state = {
      homeTeamStatistics: '',
      awayTeamStatistics: '',
      lenups: {},
      homeTeamLineups: [],
      guestTeamLineups: [],
      maxLines: 0,
      activeTab: HANDELSER_VALUE,
      venue: {},
      timelines: [],
      periodScores: [],
      timeoutId: null
    };
  }

  async componentDidMount() {

    await Promise.all([
      SportEventService.getTimelines(this.matchId, this.props.sportId)
      .then(res => {
        this.setState({ timelines: res.data })
      }),
      SportEventService.GetSportEventPeriodScore(this.props.matchId)
    .then(res => {
      this.setState({ periodScores: res.data });
    }).catch(err => {

    }),
    SportEventService.sportEventVenue(this.matchId)
    .then(res => {
      const venue = {...res.data}
      this.setState({venue: venue})
    })
    ]);

    const timelineTimeoutId = setInterval(() => {
      SportEventService.getTimelines(this.matchId, this.props.sportId)
      .then(res => {
        this.setState({ timelines: res.data })
      });
    }, 5000);

    this.setState({timeoutId: timelineTimeoutId});

    this._isMounted = true;
    this.updateHockeyWidget();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.timeoutId);
  }

  async updateHockeyWidget() {
    // if (!this._isMounted) return;
    SportEventService.sportEventHockeyStats(this.matchId)
      .then((res) => {
        const matchInfo = { ...res.data };
        this.setState(
          { homeTeamStatistics: matchInfo.teams[0].statistics, awayTeamStatistics: matchInfo.teams[1].statistics },
          () => {
            !this.isMatchClosed() && setTimeout(() => this.updateHockeyWidget(), liveUpdateInterval);
          }
        );
      })
      .catch((error) => {})
  }

  isMatchClosed() {
    return  this.props.status && MatchHelper.isMatchClosed(this.props.status);
  }

  renderPuckPossession(homePuckPossession, awayPuckPossession) {
    return (
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
        <img className={styles.puckPossessionImageWrapper} src={`/images/sportsicons/Ishockey.svg`} alt='puckImage' />
        <div className={styles.titleLabel}>Puckinnehav</div>
        <div className={styles.puckPossessionInnerWrapper}>
          <div className={[styles.valueLabel, styles.homeResultLabel].join(' ')}>{homePuckPossession}%</div>
          <div className={styles.horizontalBarChartWrapper}>
            <HorizontalStackedBarChart
              data={[homePuckPossession, awayPuckPossession]}
              colors={[this.widgetDarkRedColor, this.widgetLightRedColor]}></HorizontalStackedBarChart>
          </div>
          <div className={[styles.valueLabel, styles.awayResultLabel].join(' ')}>{awayPuckPossession}%</div>
        </div>
      </div>
    );
  }

  renderShotsOnGoal(homeShootingPercentage, awayShootingPercentage, homeGoals, awayGoals) {
    // const homeShootingPercentage = homeGoals/homeShotsOnGoal*100;
    // const awayShootingPercentage = awayGoals/awayShotsOnGoal*100;
    return (
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
        <div className={styles.titleLabel}>Skott på mål</div>
        <div className={styles.goalShotsOuterWrapper}>
          {this.renderDoughnutChart(homeShootingPercentage, 'EFFEKTIVITET\nI PROCENTT')}
          <div className={styles.goalImageWrapper}>
            <img className={styles.image} src={goalIcon} alt='goalImage'></img>
            <div className={styles.goalShotsInnerWrapper}>
              <span className={styles.shotsNumber}>{homeGoals}</span>
              <span className={styles.shotsNumber}>{awayGoals}</span>
            </div>
          </div>
          {this.renderDoughnutChart(awayShootingPercentage, 'EFFEKTIVITET\nI PROCENTT')}
        </div>
      </div>
    );
  }

  renderDoughnutChart(dataPercentage, label) {
    return (
      <div className={[styles.doughnutChartOuterWrapper].join(' ')}>
        <div className={`${styles.doughnutChartInnerWrapper} ${styles.mobileChart}`}>
          <CircularProgress
            little
            percentagePlus
            size={65}
            strokeWidth={10}
            percentage={dataPercentage?.toFixed(2)}
            color='#ef1816'
          />
        </div>

        <div className={`${styles.doughnutChartInnerWrapper} ${styles.desktopChart}`}>
          <CircularProgress
            size={100}
            percentagePlus
            strokeWidth={15}
            percentage={dataPercentage?.toFixed(2)}
            color='#ef1816'
          />
        </div>
        {/* <div className={[styles.doughnutChartInnerLabel].join(' ')}>{Math.round(dataPercentage*100)/100}%</div> */}
        <div className={styles.doughnutChartOuterLabel} style={{ marginTop: '4px' }}>
          {label}
        </div>
      </div>
    );
  }

  renderSavedShots(homeSavePercentage, awaySavePercentage, homeRescues, awayRescues) {
    // const homeSavePercentage = (1-(awayGoals/awayShotsOnGoal))*100;
    // const awaySavePercentage = (1-(homeGoals/homeShotsOnGoal))*100;
    return (
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
        <div className={styles.titleLabel} style={{ marginBottom: '10px' }}>
          Målvakter
        </div>
        <div className={styles.goalShotsOuterWrapper}>
          <div className={styles.flexCol}>
            <div>{this.renderDoughnutChart(homeSavePercentage, 'RÄDDNINGS-\nPROCENT')}</div>
            <div style={{ marginTop: '14px' }}>
              <div className={styles.drawingsNumber}>{homeRescues}</div>
              <div className={styles.drawingsText}>{`ANTAL\nRÄDDNINGAR`}</div>
            </div>
          </div>
          <div className={styles.goalieImageWrapper}>
            <img className={styles.image} src={goalieIcon} alt='goalieImage'></img>
          </div>
          <div className={styles.flexCol}>
            <div>{this.renderDoughnutChart(awaySavePercentage, 'RÄDDNINGS-\nPROCENT')}</div>
            <div style={{ marginTop: '14px' }}>
              <div className={styles.drawingsNumber}>{awayRescues}</div>
              <div className={styles.drawingsText}>{`ANTAL\nRÄDDNINGAR`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBarChart(dataValue1, dataValue2, label) {
    return (
      <div>
        <div className={`${styles.barChartInnerWrapper} ${styles.mobileChart}`}>
          <ColumnChart
            mobile
            firstTeamScore={dataValue1 ? dataValue1 : 0}
            secondTeamScore={dataValue2 ? dataValue2 : 0}
          />
        </div>

        <div className={`${styles.barChartInnerWrapper} ${styles.desktopChart}`}>
          <ColumnChart firstTeamScore={dataValue1 ? dataValue1 : 0} secondTeamScore={dataValue2 ? dataValue2 : 0} />
        </div>

        <div style={{ marginTop: '10px' }} className={styles.barChartLabel}>
          {label.toUpperCase()}
        </div>
      </div>
    );
  }

  renderDrawings(homePrecent, awayPrecent, homeDrawings, guestDrawings) {
    return (
      <div className={styles.drawingsMainContainer}>
        <div className={styles.titleLabel}>TEKNINGSPROCENT</div>
        <div className={styles.drawingsContainer}>
          <div className={styles.drawingsPercentContainer}>
            <div className={styles.drawingsNumber}>{homeDrawings}</div>
            <div className={styles.drawingsText}>{`ANTAL VUNNA\nTEKNINGAR`}</div>
          </div>

          <div className={styles.drawingsPercentCircle}>
            <div style={{ marginRight: '10px' }} className={styles.drawingsPercentText}>
              {homePrecent}
            </div>
            <div>
              <div className={`${styles.doughnutChartInnerWrapper} ${styles.mobileChart}`}>
                <CircularProgress
                  size={70}
                  isBallPercent
                  strokeWidth={10.5}
                  percentage={awayPrecent}
                  text={''}
                  mobile
                  color='#ef1816'
                />
              </div>

              <div className={`${styles.doughnutChartInnerWrapper} ${styles.desktopChart}`}>
                <CircularProgress
                  size={100}
                  isBallPercent
                  strokeWidth={15}
                  percentage={awayPrecent}
                  text={''}
                  mobile
                  color='#ef1816'
                />
              </div>
            </div>
            <div style={{ marginLeft: '10px' }} className={styles.drawingsPercentText}>
              {awayPrecent}
            </div>
          </div>

          <div className={styles.drawingsPercentContainer}>
            <div className={styles.drawingsNumber}>{guestDrawings}</div>
            <div className={styles.drawingsText}>{`ANTAL VUNNA\nTEKNINGAR`}</div>
          </div>
        </div>
      </div>
    );
  }

  renderSpecialTeams(
    homePowerPlayPercent,
    awayPowerPlayPercent,
    homePowerPlay,
    awayPowerPlpay,
    homeDrawings,
    awayDrawings
  ) {
    return (
      <div className={styles.drawingsMainContainer} style={{ marginBottom: '44px', marginTop: '24px' }}>
        <div className={styles.titleLabel}>SPECIAL TEAMS</div>
        <div className={styles.powerPlayContainer}>
          <div className={styles.drawingsPercentContainer} style={{ marginRight: '25px' }}>
            <div className={styles.drawingsNumber} style={{ marginBottom: '16px' }}>
              {this.renderBarChart(homePowerPlay, awayPowerPlpay, 'MÅL I\nPOWER PLAY')}
            </div>
            <div>{this.renderDoughnutChart(homePowerPlayPercent, 'POWER PLAY\nEFFEKTIVITET')}</div>
          </div>

          <div className={styles.drawingsPercentContainer}>
            <div className={styles.drawingsNumber} style={{ marginBottom: '16px' }}>
              {this.renderBarChart(homeDrawings, awayDrawings, 'ANTAL\nUTVISNINGSMIN')}
            </div>
            <div>{this.renderDoughnutChart(awayPowerPlayPercent, 'POWER PLAY\nEFFEKTIVITET')}</div>
          </div>
        </div>
      </div>
    );
  }

  renderOtherStatistics(homeStatistics, awayStatistics) {
    return (
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
        <div className={styles.titleLabel}>Övrig statistik</div>
        <div className={[styles.barChartsGroupWrapper, 'block__padded'].join(' ')}>
          <div className={styles.barChartOuterWrapper}>
            {this.renderBarChart(homeStatistics.penalties, awayStatistics.penalties, 'Utvisningar\nantal')}
          </div>
          <div className={styles.barChartOuterWrapper}>
            {this.renderBarChart(homeStatistics.penaltyMinutes, awayStatistics.penaltyMinutes, 'Utvisningar\nminuter')}
          </div>
          <div className={styles.barChartOuterWrapper}>
            {this.renderBarChart(homeStatistics.saves, awayStatistics.saves, 'Straffar\nmål')}
          </div>
          <div className={styles.barChartOuterWrapper}>
            {this.renderBarChart(homeStatistics.powerPlays, awayStatistics.powerPlays, 'Power play\nantal')}
          </div>
          <div className={styles.barChartOuterWrapper}>
            {this.renderBarChart(homeStatistics.goalsInPowerPlay, awayStatistics.goalsInPowerPlay, 'Power play\nmål')}
          </div>
          <div className={styles.barChartOuterWrapper}>
            {this.renderBarChart(
              homeStatistics.goalsWhileShortHanded,
              awayStatistics.goalsWhileShortHanded,
              'Box play\nmål'
            )}
          </div>
        </div>
      </div>
    );
  }

  isMatchLiveOrClosed(status) {
    return status && (MatchHelper.isMatchLive(status) || MatchHelper.isMatchClosed(status));
  }

  render() {
    const {
      wrapperClassName,
      title,
      periodScores,
    } = this.props;
    const homeScore = this.props?.homeScore;
    const awayScore = this.props.awayScore;
    const status = this.props?.status;
    const matchIsLiveOrClosed = this.isMatchLiveOrClosed(status);
    const competitorHome = this.props?.competitorHome;
    const competitorAway = this.props?.competitorAway;
    const homeTeamName = this.props?.homeTeamName;
    const awayTeamName = this.props?.awayTeamName;

    return (
      <div className={wrapperClassName}>
        {(this.state.venue !== undefined && this.state.venue !== null && this.state.venue.name) ? <LandInformationWidget isHockey={true} {...this.state} referee={this.props?.referee} spectators={this.props?.spectators}/> : null}
        <div className={styles.buttonWrapper} style={{ marginTop: '30px' }}>
          {this.buttonAlterantives.map((button, index) => (
            <UiBtn
              key={index}
              label={button.buttonText}
              onClick={() => {
                this.setState({ activeTab: button.value });
                EventAggregator.publish('trackEvent', {
                  category: 'Tournament tab',
                  action: this.tournamentName,
                  label: button.buttonText,
                });
              }}
              className={[
                this.state.activeTab === button.value ? styles.activeButton : styles.inactiveButton,
                index === 0 && styles.firstButton,
                styles.alternativeButton,
              ].join(' ')}
            />
          ))}
        </div>
        {
          <div>
            {title && this.state.activeTab == 'LIVESTATISTIK' && (
              <div className={'block__padded block__max-width'}>
                <h4 className={[styles.title].join(' ')}>{title}</h4>
              </div>
            )}
            {!matchIsLiveOrClosed ? (
              <div className={styles.matchNotStartedContainer}>
                <img className={styles.matchNotStartedImage} src={matchNotStartedIcon} alt='matchNotStartedImage'></img>
                <div className={styles.matchNotStartedText}>Matchen har inte börjat</div>
              </div>
            ) : (
              <>
                {this.state.activeTab == 'HANDELSER' && <Timeline key={this.state.timelines.length} periodScores={this.state.periodScores} {...this.props} timeline={this.state.timelines} />}
                {this.state.activeTab == 'LIVESTATISTIK' && (
                  <>
                    {this.props?.tournamentId !== 'e:tournament:9957' ? (
                      <div>
                        <TeamsAndResultBar
                          props={{
                            competitorHome,
                            competitorAway,
                            homeTeamName,
                            awayTeamName,
                            homeScore,
                            awayScore,
                            status,
                            periodScores,
                          }}
                        />
                        {/* {this.renderPuckPossession(this.state.homeTeamStatistics.puckPossession, this.state.awayTeamStatistics.puckPossession)} */}
                        {this.renderShotsOnGoal(
                          this.state.homeTeamStatistics.shooting_percentage,
                          this.state.awayTeamStatistics.shooting_percentage,
                          this.state.homeTeamStatistics.shoton,
                          this.state.awayTeamStatistics.shoton
                        )}
                        {this.renderSavedShots(
                          this.state.homeTeamStatistics.goalie_save_percent,
                          this.state.awayTeamStatistics.goalie_save_percent,
                          this.state.homeTeamStatistics.goalie_saves,
                          this.state.awayTeamStatistics.goalie_saves
                        )}
                        {/* { this.renderOtherStatistics(this.state.homeTeamStatistics, this.state.awayTeamStatistics) } */}
                        {this.renderSpecialTeams(
                          this.state.homeTeamStatistics.powerplay_percentage,
                          this.state.awayTeamStatistics.powerplay_percentage,
                          this.state.homeTeamStatistics.powerplay_goals,
                          this.state.awayTeamStatistics.powerplay_goals,
                          this.state.homeTeamStatistics.penalties_in_minutes,
                          this.state.awayTeamStatistics.penalties_in_minutes
                        )}
                        {this.renderDrawings(
                          this.state.homeTeamStatistics.faceoffs_percentage,
                          this.state.awayTeamStatistics.faceoffs_percentage,
                          this.state.homeTeamStatistics.faceoffs_won,
                          this.state.awayTeamStatistics.faceoffs_won
                        )}
                      </div>
                    ) : (
                      <div>Statisics is not available for this league</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        }
      </div>
    );
  }
}

export default HockeyMatchWidget;

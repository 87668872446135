import React, { Component } from 'react';
import styles from './total-list.module.less';
import TranslationStore from 'components/stores/translation-store';
import LogoHelper from 'components/helpers/logo-helper';
import EventAggregator from 'components/helpers/event-aggregator';

export default class TotalList extends Component {

  constructor(props) {
  super(props);

  this.state = {
    activeTab: props.list[1].overUnderValue
  }
  }

  getOdds(bet){
    if(bet.currentOdds){
      return bet.currentOdds.substr(0,4);
    }
    else if(bet.openingOdds){
      return bet.openingOdds.substr(0,4);
    }

    return "";
  }

  getTrend(bet){
    if(bet === false){
      return <span className={styles.triangleDown}></span>
    }
    else if(bet === true){
      return <span className={styles.triangleUp}></span>
    }

    return <span className={styles.triangleFiller}></span>
  }

  render(){
    if(!this.props.list || this.props.list.length === 0){
      return <p className={styles.description}>{TranslationStore.getTranslation("match_odds_comparsion_odds_not_available")}</p>;
    }

      return (
        <div className={styles.mainContainer}>
        {
          this.props.isSportHockey ? <p className={styles.description}>{TranslationStore.getTranslation("match_odds_comparsion_2way_description_hockey")}</p> : <p className={styles.description}>Tippa totalt antal mål i matchen</p>
        }

          <div className={styles.radioMainContainer}>
            <div className={styles.radioContainer}>
                { this.props.list.map((odd, index) => (
                  <div key={index} onClick={() => {this.setState({ activeTab: odd.overUnderValue }); EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: odd.overUnderValue });}} 
                      className={styles.radioBox}>                    
                    <div 
                    key={index}
                    className={[
                      styles.radioButtons,
                        ].join(' ')}
                        >
                        <div 
                        className={[
                          this.state.activeTab === odd.overUnderValue ? styles.activeRadioButton : styles.inactiveRadioButton,
                        ]}>
                        </div>
                      </div>
                        <span className={styles.radioButtonText}>{odd.overUnderValue}</span>
                  </div>
                )) }
            </div>
          </div>

        {this.props.list.map(el => 
            <div key={el.id} className={styles.mainClass}>
              { el.overUnderValue == this.state.activeTab &&
              <>
                <div className={styles.mainTopBlockContainer}>
                  <div className={styles.rowTopBlockContainer}>
                      <div className={styles.colTopBlockContainer}>
                        <div className={styles.column}>
                          <div className={styles.rowTopBlockContainer}>
                            <span className={styles.overUnderText}>Över {el.overUnderValue}</span>
                          </div>
                          <a href={this.props.betLinks[`${el.topBookieNameOver}`]} target="_blank" rel="noopener noreferrer"><img src={LogoHelper.getBettingCompanyLogoUrlByName(el.topBookieNameOver)} alt="" className={styles.betLogo2way} /></a>
                          <div className={styles.rowTopBlockContainer}>
                              {el.topOddOver}
                          </div>
                        </div>
                        <div className={styles.column}>
                            <div className={styles.rowTopBlockContainer}>
                              <span className={styles.overUnderText}>Under {el.overUnderValue}</span>
                            </div>
                            <a href={this.props.betLinks[`${el.topBookieNameUnder}`]} target="_blank" rel="noopener noreferrer"><img src={LogoHelper.getBettingCompanyLogoUrlByName(el.topBookieNameUnder)} alt="" className={styles.betLogo2way} /></a>
                            <div className={styles.rowTopBlockContainer}>
                              {el.topOddUnder}
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className={styles.preTableText}>
                  <span>Bäst odds just nu</span>
                </div>
                    <ul className={styles.bestOddsListHeader}>
                      <div className={styles.leftColumns}>
                        <li className={styles.bestOddsHeaderNumbers}>{el.overUnderValue}</li>
                        <li className={styles.bestOddsHeaderPayout}>Payout</li>
                      </div>
                      <div className={styles.rightColumns}>
                        <li className={styles.bestOddsHeaderPayout}>
                          Över
                        </li>
                        <li className={styles.bestOddsHeaderPayout}>
                          Under
                        </li>
                      </div>
                    </ul>

                    <ul className={styles.list2way}>
                    {
                      el.odds.map((li,i) => <li className={styles.listItem2way} key={"list_" + i}>
                      <ul className={styles.bestOddsListBody}>
                          <div className={styles.leftColumns}>
                              <li className={styles.bestOddsHeaderLabel}>
                                  <a href={this.props.betLinks[`${li.bookieName}`]} target="_blank" rel="noopener noreferrer"><img src={LogoHelper.getBettingCompanyLogoUrlByName(li.bookieName)} alt="" className={styles.betLogo2way} /></a>
                              </li>

                              <li className={styles.bestOddsHeaderPayout}>
                                <span className={styles.payoutText}>{`${li.payoutPercentage?.toFixed(2) ?? 0}%`}</span>
                              </li>
                          </div>
                          <div className={styles.rightColumns}>
                              <li className={`${styles.bestOddsHeaderNumbers} ${li.overOdd == el.topOddOver ? styles.bestOdd : ''}`}>
                                  <a href={this.props.betLinks[`${li.bookieName}`]} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                                    <span className={styles.listItemBet2wayOdds}>{li.overOdd.toFixed(2)}</span>
                                    {this.getTrend(li.overOddIncreased)}
                                  </a>
                              </li>

                              <li className={`${styles.bestOddsHeaderNumbers} ${li.underOdd == el.topOddUnder ? styles.bestOdd : ''}`}>
                                  <a href={this.props.betLinks[`${li.bookieName}`]} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                                    <span className={styles.listItemBet2wayOdds}>{li.underOdd.toFixed(2)}</span>
                                    {this.getTrend(li.underOddIncreased)}
                                  </a>
                              </li>
                          </div>
            
                      </ul>
                        </li>)
                    }
                    </ul>
              </>
        }
        </div>
        )}
        </div>
      )
  }
}
import React, { Component } from 'react';
import styles from './soccer-substitute-wdiget.module.less';
import SubstituteIn from "static/Substitute-IN.png"

class SoccerSubstituteWidget extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className={styles.appContainer}>
                <div className={styles.mainContainer}>
                    <div className={styles.title}>
                        {this.props.sportId == "e:sport:1" && <h4>Avbytare</h4>}
                        {this.props.sportId == "e:sport:5" && <h4>Extraspelare</h4>}
                    </div>

                    <div className={styles.teamsContainer}>
                        <div className={styles.homeTeam}>
                            <div className={styles.homeTeamLogo}>
                                <img src={this.props?.homeTeamLogo} className={styles.teamLogo}></img>
                            </div>
                        </div>

                        <div className={styles.homeTeam}>
                            <div className={styles.guestTeamLogo}>
                                <img src={this.props?.awayTeamLogo} className={styles.teamLogo}></img>
                            </div>
                        </div>
                    </div>

                    <div className={styles.teamsContainer}>
                        <ul className={styles.teamMainContainer} style={{padding: 0, width: '50%', paddingRight: 10}}>
                            { this.props.homeTeam?.length > 0 && this.props.homeTeam.map(el => (    
                            <li key={el.jerseyNumber} className={styles.teamCol}>
                                <div className={styles.teamRow}>
                                <div><span className={styles.jerseyNumber}>{el.jerseyNumber}</span></div>
                                <div style={{textAlign: 'start'}}><span className={[`${styles.playerName}`] }>{el.fullName?.length > 20 ? `${(el.name?.substring(0, 1))}. ${el.fullName?.split(' ')[1]}` : el.fullName}</span></div>
                                </div>
                                <div className={`${styles.teamRow} ${styles.mt015rem}`}>
                                <div className={styles.mr04rem}><span className={styles.jerseyNumberX}>{ el.displayType?.length > 0 ? el.displayType : '-' }</span></div>
                                <div className={styles.mr04rem} style={{width: '0.75rem', height: '0.75rem', alignSelf: "center"}}>{
                                        el.substInMinute != null ?
                                        <img src={SubstituteIn}></img>
                                        : null
                                    }</div>
                                <div className={styles.eventTime}><span className={styles.playerName}>{el.substInMinute != null ? ` (${el.substInMinute}')` : null}</span></div>
                                </div>
                            </li> 
                            )) }
                        </ul>

                        <ul className={styles.teamMainContainer} style={{padding: 0, width: '50%', paddingLeft: 10}}>
                        { this.props.awayTeam?.length > 0 && this.props.awayTeam.map(el => (    
                            <li key={el.jerseyNumber} className={styles.teamCol} style={{alignItems: 'end'}}>
                                <div className={styles.teamRow}>
                                <div style={{textAlign: 'start'}}><span className={[`${styles.playerName} ${el?.fullName?.length > 14 ? styles.playerNameShort : null}`] }>{el.fullName}</span></div>
                                <div><span className={styles.jerseyNumber}>{el.jerseyNumber}</span></div>
                                </div>
                                <div className={`${styles.teamRow} ${styles.mt015rem}`}>
                                <div className={styles.eventTime}><span className={styles.playerName}>{el.substInMinute != null ? ` (${el.substInMinute}')` : null}</span></div>
                                <div className={styles.ml04rem} style={{width: '0.75rem', height: '0.75rem', alignSelf: "center"}}>{
                                    el.substInMinute != null ?
                                    <img src={SubstituteIn}></img>
                                    : null
                                }</div>
                                <div><span className={styles.jerseyNumberXAway}>{el.displayType?.length > 0 ? el.displayType : '-'}</span></div>
                                </div>
                            </li> 
                            )) }
                        </ul>
                    </div>


                </div>
            </div>
        )
    }
}

export default SoccerSubstituteWidget;
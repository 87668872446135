import React, { Component } from 'react';
import styles from './hcp-list.module.less';
import TranslationStore from 'components/stores/translation-store';
import firstImage from 'static/1.png'
import secondImage from 'static/2.png'
import versusImage from 'static/X.png'
import LogoHelper from 'components/helpers/logo-helper';
import EventAggregator from 'components/helpers/event-aggregator';

export default class HCPList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sortHandicapList: [],
      activeTab: props.list[0].handicapValue,
      bettingCompanies: []
    }
  }

  getOdds(bet){
    if(bet.currentOdds){
      return bet.currentOdds.substr(0,4);
    }
    else if(bet.openingOdds){
      return bet.openingOdds.substr(0,4);
    }

    return "";
  }

  getTrend(bet){
    if(bet === false){
      return <span className={styles.triangleDown}></span>
    }
    else if(bet === true){
      return <span className={styles.triangleUp}></span>
    }

    return <span className={styles.triangleFiller}></span>
  }

  async componentDidMount() {

    let plusArr = [];
    let minusArr = [];

    this.props.list.map(odd => {
      if(odd.handicapValue == '1x0' ||
      odd.handicapValue == '2x0')
      {
        plusArr.push(odd);
      }

      else if(odd.handicapValue == '0x1' ||
      odd.handicapValue == '0x2')
      {
        minusArr.push(odd)
      }
    });

    plusArr = plusArr.sort((function(a, b){
      if(a.handicapValue < b.handicapValue) { return -1; }
      if(a.handicapValue > b.handicapValue) { return 1; }
      return 0;
    }));

    minusArr = minusArr.sort((function(a, b){
      if(a.handicapValue < b.handicapValue) { return -1; }
      if(a.handicapValue > b.handicapValue) { return 1; }
      return 0;
  }));

  
  let mainArr = plusArr.concat(minusArr);

  this.setState({ activeTab: mainArr[0].handicapValue});

  this.setState({sortHandicapList: mainArr});
  }


  render(){
    if(!this.props.list || this.props.list.length === 0){
      return <p className={styles.description}>{TranslationStore.getTranslation("match_odds_comparsion_odds_not_available")}</p>;
    }

      return (
        <div className={styles.mainContainer}>
          {
            this.props.isSportHockey ? <p className={styles.description}>{TranslationStore.getTranslation("match_odds_comparsion_2way_description_hockey")}</p> : <p className={styles.description}>Det ena laget får ett antal måls försprång</p>
          }

          <div className={styles.radioMainContainer}>
            <div className={styles.radioContainer}>
                { this.state.sortHandicapList.map((odd, index) => (
                  <div
                  onClick={() => {this.setState({ activeTab: odd.handicapValue }); EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: odd.handicapValue });}} 
                      className={styles.radioBox}>                    
                    <div 
                    key={index}
                    className={[
                      styles.radioButtons,
                        ].join(' ')}
                        >
                        <div 
                        className={[
                          this.state.activeTab === odd.handicapValue ? styles.activeRadioButton : styles.inactiveRadioButton,
                        ]}>
                        </div>
                      </div>
                        <div className={styles.radioButtonText}>
                          <span>{ odd.handicapValue == '1x0' ? '+1' : null }</span>
                          <span>{ odd.handicapValue == '2x0' ? '+2' : null }</span>
                          <span>{ odd.handicapValue == '0x1' ? '-1' : null }</span>
                          <span>{ odd.handicapValue == '0x2' ? '-2' : null }</span>
                          </div>
                  </div>
                )) }
            </div>
          </div>

        {this.state.sortHandicapList.map(el => (
          <div key={el.id}>
            {el.handicapValue == this.state.activeTab &&
              <>
              <div className={styles.mainTopBlockContainer}>
                <div className={styles.rowTopBlockContainer}>
                    <div className={styles.colTopBlockContainer}>

                      <div className={styles.column}>
                        <div className={styles.rowTopBlockContainer}>
                          <img src={firstImage}></img>
                        </div>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${el.topBookieHomeWinId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${el.topBookieHomeWinId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                        <div className={styles.rowTopBlockContainer}>
                            {el.topHomeWinOdd.toFixed(2)}
                        </div>
                      </div>

                      <div className={styles.column}>
                        <div className={styles.rowTopBlockContainer}>
                          <img src={versusImage}></img>
                        </div>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${el.topBookieDrawId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${el.topBookieDrawId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                        <div className={styles.rowTopBlockContainer}>
                            {el.topDrawOdd.toFixed(2)}
                        </div>
                      </div>

                      <div className={styles.column}>
                          <div className={styles.rowTopBlockContainer}>
                            <img src={secondImage}></img>
                          </div>
                          <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${el.topBookieAwayWinId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${el.topBookieAwayWinId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                          <div className={styles.rowTopBlockContainer}>
                            {el.topAwayWinOdd.toFixed(2)}
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className={styles.preTableText}>
                <span>Bäst odds just nu</span>
              </div>
                  <ul className={styles.bestOddsListHeader}>
                    <div className={styles.leftColumns}>
                      <li className={styles.bestOddsHeaderNumbers}>                          
                          <span>{ el.handicapValue == '1x0' ? '+1' : null }</span>
                          <span>{ el.handicapValue == '2x0' ? '+2' : null }</span>
                          <span>{ el.handicapValue == '0x1' ? '-1' : null }</span>
                          <span>{ el.handicapValue == '0x2' ? '-2' : null }</span></li>
                      <li className={styles.bestOddsHeaderPayout}>Payout</li>
                    </div>
                    <div className={styles.rightColumns}>
                      <li className={styles.bestOddsHeaderNumbers}>
                        1
                      </li>
                      <li className={styles.bestOddsHeaderNumbers}>
                        X
                      </li>
                      <li className={styles.bestOddsHeaderNumbers}>
                        2
                      </li>
                    </div>
                  </ul>

                  <ul className={styles.list2way}>
                  {
                    el.odds.map((li,i) => <li className={styles.listItem2way} key={"list_" + i}>
                    <ul className={styles.bestOddsListBody}>
                        <div className={styles.leftColumns}>
                            <li className={styles.bestOddsHeaderLabel}>
                            <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                            </li>

                            <li className={styles.bestOddsHeaderPayout}>
                              <span className={styles.payoutText}>{`${li.payoutPercentage?.toFixed(2) ?? 0}%`}</span>
                            </li>
                        </div>
                        <div className={styles.rightColumns}>
                            <li className={`${styles.bestOddsHeaderNumbers} ${li.homeWinOdd == el.topHomeWinOdd ? styles.bestOdd : ''}`}>
                                <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                                  <span className={styles.listItemBet2wayOdds}>{li.homeWinOdd.toFixed(2)}</span>
                                  {this.getTrend(li.homeWinOddIncreased)}
                                </a>
                            </li>

                            <li className={`${styles.bestOddsHeaderNumbers} ${li.drawOdd == el.topDrawOdd ? styles.bestOdd : ''}`}>
                                <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                                  <span className={styles.listItemBet2wayOdds}>{li.drawOdd.toFixed(2)}</span>
                                  {this.getTrend(li.drawOddIncreased)}
                                </a>
                            </li>

                            <li className={`${styles.bestOddsHeaderNumbers} ${li.awayWinOdd == el.topAwayWinOdd ? styles.bestOdd : ''}`}>
                                <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                                  <span className={styles.listItemBet2wayOdds}>{li.awayWinOdd.toFixed(2)}</span>
                                  {this.getTrend(li.awayWinOddIncreased)}
                                </a>
                            </li>
                        </div>

                    </ul>
                      </li>)
                  }
                  </ul>
            </>

            }
          </div>
        ))}
        </div>
      )
  }
}
import styles from './timeline.module.less';
import React from 'react';
import TranslationStore from 'components/stores/translation-store';
import MatchEventsConfigurationStore from 'components/stores/match-events-configuration-store';
import MatchHelper from 'components/helpers/match-helper';
import matchNotStartedIcon from 'styles/icons/icons/match_not_started_icon.svg';
import { staticTimeLineImages } from 'static/staticImagesLinks';

const getIcon = (item, sportId, eventIconForSport) => {
  return (
    <div className={[styles.eventItem__edge, getHomeOrAwayClass(item.team)].join(' ')}>
      {renderMatchTimeForCurrentSport(item, sportId)}
      {eventIconForSport && (
        <img className={styles.eventItem__icon} src={eventIconForSport} />
      )}
    </div>
  );
};

const getEventItemMarkup = (item, sportId) => {
  if(!(item.type == 'period_start' && item.period == 1 && sportId == 'e:sport:5')) {
      switch(item.type) {
        
        case 'injury_time_shown':
          return (
            <div className={[styles.eventItem, 'block__padded', item.team === 'away' ? styles._away : ''].join(' ')}>
              {getIcon(item, sportId, staticTimeLineImages.clockIcon)}
              <div className={styles.eventItem__center}>
                <span className={styles.eventItem__player}>
                {`Stopptid visad (+${item.injuryTimeAnnounced})`}
                </span>
              </div>
            </div>
          );
    
        case 'match_started':
          return (
            <div className={[styles.eventItem, 'block__padded'].join(' ')}>
              {getIcon(item, sportId, staticTimeLineImages.whistleImage)}
              <span className={styles.eventItem__matchEvent}>
                  Matchen har startat
              </span>
            </div>
          );
    
        case 'match_ended':
          return (
            <div className={[styles.eventItem, 'block__padded'].join(' ')}>
              {getIcon(item, sportId, staticTimeLineImages.whistleImage)}
              <span className={styles.eventItem__matchEvent}>
                Matchen slut
              </span>
            </div>
          );
    
        case 'break_start':
          return (
            <div className={[styles.eventItem, 'block__padded'].join(' ')}>
              {getIcon(item, sportId, staticTimeLineImages.cupIcon)}
              <span className={styles.eventItem__matchEvent}>
                Paus
              </span>
            </div>
          );
    
        case 'period_start':
          return (
            <div className={[styles.eventItem, 'block__padded'].join(' ')}>
              <span className={styles.eventItem__matchEvent}>

              {sportId == 'e:sport:5' ? ((item.period == 1 || item.period == 2 || item.period == 3) ? `${item.period}:a perioden har startat`
              : item.period == 4 ? 'Förlängningen startar'
              : item.period == 5 ? 'Straffläggningen börjar' : `${item.period}:a perioden har startat`)
              : (item.period == 1 || item.period == 2) ? 'Andra halvlek har startat'
              : item.period == 3 ? 'Första förlängningskvarten startar'
              : item.period == 4 ? 'Andra förlängningskvarten startar'
              :  item.period == 5 ? 'Straffläggning börjar'
              : 'Andra halvlek har startat'}
              </span>
            </div>
          );
    
        case 'yellow_card':
          return (
            <div
              className={[
              styles.eventItem,
              'block__padded',
              eventGradient(item, sportId),
              item.team === 'away' ? styles._away : '',
            ].join(' ')}>
            <div className={styles.eventItem__center}>
              {getIcon(item, sportId, staticTimeLineImages.yellowCardIcon)}
              <span className={styles.eventItem__type}>{item.playerName ? <div>{item.playerName}</div> : ''}</span>
            </div>
          </div>
          );
    
        case 'red_card':
          return (
            <div
              className={[
              styles.eventItem,
              'block__padded',
              eventGradient(item, sportId),
              item.team === 'away' ? styles._away : '',
            ].join(' ')}>
            <div className={styles.eventItem__center}>
              {getIcon(item, sportId, staticTimeLineImages.redCardImage)}
              <span className={styles.eventItem__type}>{item.playerName ? <div>{item.playerName}</div> : ''}</span>
            </div>
          </div>
          );
    
        case 'yellow_red_card':
          return (
            <div
              className={[
              styles.eventItem,
              'block__padded',
              eventGradient(item, sportId),
              item.team === 'away' ? styles._away : '',
            ].join(' ')}>
            <div className={styles.eventItem__center}>
              {getIcon(item, sportId, staticTimeLineImages.yellowRedCardImage)}
              <span className={styles.eventItem__type}>{item.playerName ? <div>{item.playerName}</div> : ''}</span>
            </div>
          </div>
          );
    
        case 'cancelled_video_assistant_referee':
          return (
            <div
              className={[
                styles.eventItem,
                'block__padded',
                eventGradient(item, sportId),
                item.team === 'away' ? styles._away : '',
              ].join(' ')}>
              <div className={styles.eventItem__center}>
                {getIcon(item, sportId, staticTimeLineImages.varIcon)}
                {item.periodType === 'penalties' ? (
                  (item.shootoutHomeScore === 0 || item.shootoutHomeScore > 0) &&
                  (item.shootoutAwayScore === 0 || item.shootoutAwayScore > 0) ? (
                    <span className={styles.eventItem__score}>
                      ${item.shootoutHomeScore} - ${item.shootoutAwayScore}
                    </span>
                  ) : (
                    ''
                  )
                ) : (
                  <span className={styles.eventItem__matchEvent}>
                    VAR - Mål underkänt
                  </span>
                )}
              </div>
            </div>
          );
    
        case 'penalty_shootout':
          return (
            <div
              className={[
                styles.eventItem,
                'block__padded',
                eventGradient(item, sportId),
                item.team === 'away' ? styles._away : '',
              ].join(' ')}>
              <div className={styles.eventItem__center}>
                {getIcon(item, sportId, staticTimeLineImages.greenCheckmarkIcon)}
                  <div>                  
                    <span className={styles.eventItem__score}>
                      {item.homeScore}-{item.awayScore}
                  </span></div>
                <span className={styles.eventItem__player}>
                  {item.playerName ? item.playerName : ''}{' '}
                  {/* {contentfulItem && contentfulItem.title ? ` (${contentfulItem.title})` : ''} */}
                </span>
              </div>
            </div>
          );
    
        case 'penalty_awarded':
          return (
            <div
              className={[
                styles.eventItem,
                'block__padded',
                eventGradient(item, sportId),
                item.team === 'away' ? styles._away : '',
              ].join(' ')}>
              <div className={styles.eventItem__center}>
                {getIcon(item, sportId, null)}
                  <div>                  
                    <span className={styles.eventItem__score}>
                      {item.homeScore}-{item.awayScore}
                  </span></div>
                <span className={styles.eventItem__player}>
                  {item.playerName ? item.playerName : ''}{' '}
                  Straff
                </span>
              </div>
            </div>
          );
    
        case 'penalty_missed':
          return (
            <div
              className={[
                styles.eventItem,
                'block__padded',
                eventGradient(item, sportId),
                item.team === 'away' ? styles._away : '',
              ].join(' ')}>
              <div className={styles.eventItem__center}>
                {getIcon(item, sportId, staticTimeLineImages.redCrossIcon)}
                <span className={styles.eventItem__type}>
                  Straffmiss
                </span>
                <span className={styles.eventItem__type}>{item.playerName ? `(${item.playerName})` : ''}</span>
              </div>
            </div>
          );
    
        case 'suspension':
          return (
            <div
              className={[
                styles.eventItem,
                'block__padded',
                eventGradient(item, sportId),
                item.team === 'away' ? styles._away : '',
              ].join(' ')}>
              <div className={styles.eventItem__center}>
              {getIcon(item, sportId, null)}
                <span className={styles.eventItem__type}>
                  {item.playerName ? <div>{item.playerName}</div> : ''}
                  Utvisning
                  {item.suspensionMinutes ? ` (${item.additionSuspensionMinutes ?? item.suspensionMinutes} min)` : ''}
                </span>
              </div>
            </div>
          );
    
        case 'score_change':
          return (
            <div
              className={[
                styles.eventItem,
                'block__padded',
                eventGradient(item, sportId),
                item.team === 'away' ? styles._away : '',
              ].join(' ')}>
              <div className={styles.eventItem__center}>
                {getIcon(item, sportId, sportId == 'e:sport:5' ? staticTimeLineImages.washerImage : item.goalScorerMethod === 'own_goal' ? staticTimeLineImages.redBallIcon : staticTimeLineImages.defaultBallIcon)}
                {item.periodType === 'penalties' ? (
                  (item.shootoutHomeScore === 0 || item.shootoutHomeScore > 0) &&
                  (item.shootoutAwayScore === 0 || item.shootoutAwayScore > 0) ? (
                    <span className={styles.eventItem__score}>
                      ${item.shootoutHomeScore} - ${item.shootoutAwayScore}
                    </span>
                  ) : (
                    ''
                  )
                ) : (
                  <span className={styles.eventItem__score}>
                    {item.homeScore}-{item.awayScore}
                  </span>
                )}
                <span className={styles.eventItem__player}>
                  {item.goalScorerPlayerName}
                  {item.goalScorerMethod === 'own_goal'
                    ? ` (Självmål)`
                    : null}
                  {item.goalScorerMethod === 'penalty' || item.periodType === 'penalties'
                    ? ` (Straff)`
                    : null}
                </span>
                {MatchHelper.isMatchHockey(sportId) && item.goalScorerMethod !== 'penalty'
                  ? getAssists(item)
                  : getAssists(item)}
              </div>
            </div>
          );
    }
  }
};

const eventGradient = (item, sportId) => {
  if (item.type === 'score_change') {
    return item.team === 'away' ? styles.gradientAwayGoal : styles.gradientHomeGoal;
  }

  if (
    (MatchHelper.isMatchHockey(sportId) && item.type === 'suspension') ||
    (MatchHelper.isMatchFootball(sportId) && item.type === 'red_card') ||
    (MatchHelper.isMatchFootball(sportId) && item.type === 'yellow_red_card')
  ) {
    return item.team === 'away' ? styles.gradientAwayPenalty : styles.gradientHomePenalty;
  }

  if (MatchHelper.isMatchFootball(sportId) && item.type === 'yellow_card') {
    return item.team === 'away' ? styles.gradientAwayWarning : styles.gradientHomeWarning;
  }

  return '';
};

const reverseName = (playerName = '') => {
  let words = typeof playerName === 'string' && playerName.length ? playerName.split(',') : '';
  words.reverse();

  return words.join(' ').trim();
};

const reverseNameFirstNameSingleCharacter = (playerName = '') => {
  let name = reverseName(playerName);
  name = name.split(' ');
  if (name instanceof Array && name.length > 1) {
    name = name[0].charAt(0) + ' ' + name[1];
  }

  return name;
};

const getAssists = (item) => {
  let assists = '';
  if (item && item.assists) {
    assists = JSON.parse(item.assists);
    if (assists instanceof Array) {
      let foundPrimaryAssist = assists.find((assist) => assist.Type === 'primary');
      let foundSecondaryAssist = assists.find((assist) => assist.Type === 'secondary');

      if (foundPrimaryAssist) {
        return foundSecondaryAssist ? (
          <span className={styles.eventItem__assists}>
            ({reverseNameFirstNameSingleCharacter(foundPrimaryAssist.Name)},{' '}
            {reverseNameFirstNameSingleCharacter(foundSecondaryAssist.Name)})
          </span>
        ) : (
          <span className={styles.eventItem__assists}>({reverseName(foundPrimaryAssist.Name)})</span>
        );
      } else {
        // return <span className={styles.eventItem__assists}>({(TranslationStore.getTranslation("time_line_no_assist"))})</span>
      }
    }
  }

  // return <span className={styles.eventItem__assists}>({(TranslationStore.getTranslation("time_line_no_assist"))})</span>
};

const getHomeOrAwayClass = (team) => {
  if (team === 'home') {
    return styles.team__home;
  } else if (team === 'away') {
    return styles.team__away;
  }
  return '';
};

const renderMatchTimeForCurrentSport = (item, sportId) => {
  if (MatchHelper.isMatchHockey(sportId)) {
    if (item.matchClock) {
      let str = item.matchClock.split(':')[1];
      if (str == '0' && str.length < 2) {
        item.matchClock = item.matchClock + '0';
      }

      if (str.length < 2) {
        item.matchClock = item.matchClock.split(':')[0] + ':0' + item.matchClock.split(':')[1];
      }
    }
  }
  return MatchHelper.isMatchHockey(sportId) ? (
    item.matchClock ? (
      <span>{item.matchClock}</span>
    ) : null
  ) : item.matchTime ? (
    <span>{item.stoppageTime ? `${item.matchTime}+${item.stoppageTime}'` : `${item.matchTime}'`}</span>
  ) : null;
};

const isMatchLiveOrClosed = (status) => {
  return status && (!MatchHelper.isMatchUpcoming(status));
};

const sortHockeyTimelines = (timeline) => {
  let matchEndElement = timeline.find((tl) => tl.type == 'match_ended'); // match end
  let matchEndedElementIndex = timeline.findIndex((tl) => tl.type == 'match_ended');

  if (matchEndElement) {
    timeline = timeline.filter((tl) => tl.type != 'match_ended');
    timeline.unshift(matchEndElement);
  }

  let secondPeriodStart = timeline.find((tl) => tl.type == 'period_start' && tl.period == 2); // second period
  let lastFirstTimeEventElementIndex = timeline.findIndex((tl) => tl.matchTime <= 1200 && tl.period == 1);

  if (secondPeriodStart) {
    timeline = timeline.filter((el) => !(el.type == 'period_start' && el.period == 2));
    timeline.splice(lastFirstTimeEventElementIndex, 0, secondPeriodStart);
  }

  let firstPeriodBreakStart = timeline.find((tl) => tl.type == 'break_start' && tl.period == 1); // second period
  let lastFirstPeriodBreakStartIndex = timeline.findIndex((tl) => tl.matchTime <= 1200 && tl.period == 1);

  if (firstPeriodBreakStart) {
    timeline = timeline.filter((el) => !(el.type == 'break_start' && el.period == 1));
    timeline.splice(lastFirstPeriodBreakStartIndex, 0, firstPeriodBreakStart);
  }

  let thirdPeriodStart = timeline.find((tl) => tl.type == 'period_start' && tl.period == 3); // third period
  let lastThirdTimeEventElementIndex = timeline.findIndex((tl) => tl.matchTime <= 2400 && tl.period == 2);

  if (thirdPeriodStart) {
    timeline = timeline.filter((el) => !(el.type == 'period_start' && el.period == 3));
    timeline.splice(lastThirdTimeEventElementIndex, 0, thirdPeriodStart);
  }

  let secondPeriodBreakStart = timeline.find((tl) => tl.type == 'break_start' && tl.period == 2); // second period
  let lastSecondPeriodBreakStartIndex = timeline.findIndex((tl) => tl.matchTime <= 2400 && tl.period == 2);

  if (secondPeriodBreakStart) {
    timeline = timeline.filter((el) => !(el.type == 'break_start' && el.period == 2));
    timeline.splice(lastSecondPeriodBreakStartIndex, 0, secondPeriodBreakStart);
  }

  let overtimePeriodStart = timeline.find((tl) => tl.type == 'period_start' && tl.periodName == 'Overtime'); // overtime
  let lastovertimeTimeEventElementIndex = timeline.findIndex((tl) => tl.matchTime <= 3600 && tl.period == 3);

  if (overtimePeriodStart) {
    let overtimeElementIndex = timeline.findIndex((tl) => tl.type == 'period_start' && tl.periodName == 'Overtime');
    timeline.splice(overtimeElementIndex, 1);
    timeline.splice(lastovertimeTimeEventElementIndex, 0, overtimePeriodStart);
  }

  let thirdPeriodBreakStart = timeline.find((tl) => tl.type == 'break_start' && tl.period == 3); // second period
  let lastThirdPeriodBreakStartIndex = timeline.findIndex((tl) => tl.matchTime <= 3600 && tl.period == 3);

  if (thirdPeriodBreakStart) {
    timeline = timeline.filter((el) => !(el.type == 'break_start' && el.period == 3));
    timeline.splice(lastThirdPeriodBreakStartIndex, 0, thirdPeriodBreakStart);
  }

  let penaltiesPeriodStart = timeline.find((tl) => tl.type == 'period_start' && tl.periodName == 'Penalties'); // Penalties
  let lastpenaltiesTimeEventElementIndex = timeline.findIndex((tl) => tl.matchTime <= 3900 && tl.period == 3);

  if (penaltiesPeriodStart) {
    let penaltiesElementIndex = timeline.findIndex((tl) => tl.type == 'period_start' && tl.periodName == 'Penalties');
    timeline.splice(penaltiesElementIndex, 1);
    timeline.splice(lastpenaltiesTimeEventElementIndex, 0, penaltiesPeriodStart);

    let newPenaltiesElementIndex = timeline.findIndex(
      (tl) => tl.type == 'period_start' && tl.periodName == 'Penalties'
    );

    if (timeline[newPenaltiesElementIndex - 1].periodName == 'Overtime') {
      timeline.splice(newPenaltiesElementIndex, 1);
      timeline.splice(newPenaltiesElementIndex - 1, 0, penaltiesPeriodStart);
    }
  }

  let matchStart = timeline.find((tl) => tl.type == 'match_started');

  if (matchStart) {
    timeline = timeline.filter((tl) => tl.type != 'match_started');
    timeline.push(matchStart);
  }

  return timeline;
};

const sortSoccerTimelines = (timeline) => {

    let firstTimeEvents = timeline.filter(tl => tl.period == 1 && !(tl.type == 'break_start' || tl.type == 'period_start' || tl.type == 'match_started' || tl.type == 'match_ended'));
    let secondTimeEvents = timeline.filter(tl => tl.period == 2 && !(tl.type == 'break_start' || tl.type == 'period_start' || tl.type == 'match_started' || tl.type == 'match_ended'));
    let thirdTimeEvents = timeline.filter(tl => tl.period == 3 && !(tl.type == 'break_start' || tl.type == 'period_start' || tl.type == 'match_started' || tl.type == 'match_ended'));
    let fourthTimeEvents = timeline.filter(tl => tl.period == 4 && !(tl.type == 'break_start' || tl.type == 'period_start' || tl.type == 'match_started' || tl.type == 'match_ended'));
    let fifthTimeEvents = timeline.filter(tl => tl.period == 5 && !(tl.type == 'break_start' || tl.type == 'period_start' || tl.type == 'match_started' || tl.type == 'match_ended'));

    let soccerMatchEndElement = timeline.find((tl) => tl.type == 'match_ended');
    let penaltiesPeriodStart = timeline.find((tl) => tl.type == 'period_start' && tl.period == 5);
    let awaitingPenaltiesStart = timeline.find((tl) => tl.type == 'break_start' && tl.period == 4);
    let secondOvertimeStart = timeline.find((tl) => tl.type == 'period_start' && tl.period == 4);
    let secondPeriodBreackStart = timeline.find((tl) => tl.type == 'break_start' && tl.period == 3);
    let firstOvertimeStart = timeline.find((tl) => tl.type == 'period_start' && tl.period == 3);
    let firstPeriodBreackStart = timeline.find((tl) => tl.type == 'break_start' && tl.period == 2);
    let secondPeriodStart = timeline.find((tl) => tl.type == 'period_start' && tl.period == 2);
    let soccerBreakStart = timeline.find((tl) => tl.type == 'break_start' && tl.period == 1);
    let soccerMatchStartElement = timeline.find((tl) => tl.type == 'match_started');
    let newTimeLines = [];

    if (soccerMatchEndElement) {
      // newTimeLines = newTimeLines.filter(tl => !(tl.type == 'match_ended'))
        newTimeLines.push(soccerMatchEndElement);
    }

    newTimeLines.push(...fifthTimeEvents);

    if (penaltiesPeriodStart) {
      // newTimeLines = newTimeLines.filter(tl => !(tl.type == 'period_start' && tl.period == 5))
        newTimeLines.push(penaltiesPeriodStart);
    }

    if (awaitingPenaltiesStart) {
      // newTimeLines = newTimeLines.filter(tl => !(tl.type == 'break_start' && tl.period == 4))
        newTimeLines.push(awaitingPenaltiesStart);
    }

    newTimeLines.push(...fourthTimeEvents);

    if (secondOvertimeStart) {
      // newTimeLines = newTimeLines.filter(tl => !(tl.type == 'period_start' && tl.period == 4))
        newTimeLines.push(secondOvertimeStart);
    }

    if (secondPeriodBreackStart) {
      // newTimeLines = newTimeLines.filter(tl => !(tl.type == 'break_start' && tl.period == 3))
        newTimeLines.push(secondPeriodBreackStart);
    }

    newTimeLines.push(...thirdTimeEvents);

    if (firstOvertimeStart) {
      // newTimeLines = newTimeLines.filter(tl => !(tl.type == 'period_start' && tl.period == 3))
        newTimeLines.push(firstOvertimeStart);
    }

    if (firstPeriodBreackStart) {
        newTimeLines = newTimeLines.filter(tl => !(tl.type == 'break_start' && tl.period == 2))
        newTimeLines.push(firstPeriodBreackStart);
    }

    newTimeLines.push(...secondTimeEvents);

    if (secondPeriodStart) {
        newTimeLines = newTimeLines.filter(tl => !(tl.type == 'period_start' && tl.period == 2))
        newTimeLines.push(secondPeriodStart);
    }

    if (soccerBreakStart) {
      newTimeLines = newTimeLines.filter(tl => !(tl.type == 'break_start' && tl.period == 1))
        newTimeLines.push(soccerBreakStart);
    }

    newTimeLines.push(...firstTimeEvents);

    if (soccerMatchStartElement) {
      newTimeLines = newTimeLines.filter(tl => !(tl.type == 'match_started'))
        newTimeLines.push(soccerMatchStartElement);
    }


    return newTimeLines;
};

const Timeline = (props) => {
  const sportId = props.sportId;
  const status = props && props.status ? props.status : '';
  const matchIsLiveOrClosed = isMatchLiveOrClosed(status);
  var { timeline, periodScores } = props;
  const attendanceTitle = TranslationStore.getTranslation('time_line_attendance');
  const homeTeamName = props.homeTeamDisplayName ? props.homeTeamDisplayName : props.homeTeamName ? props.homeTeamName : props.competitorNameHome;
  const awayTeamName = props.awayTeamDisplayName ? props.awayTeamDisplayName : props.awayTeamName ? props.awayTeamName : props.competitorNameAway;
  let homeScore = '';
  let awayScore = '';

  if (timeline instanceof Array) {
    if (sportId == 'e:sport:5') {
      timeline = sortHockeyTimelines(timeline);
    } else if (sportId == 'e:sport:1') {
      timeline = sortSoccerTimelines(timeline);
    }
  }

  if (props && (props.homeScore !== undefined || props.homeScore !== null)) {
    homeScore =
    props.finalHomeScore === 0 || props.finalHomeScore ? props.finalHomeScore : props.homeScore;
  }

  if (props && (props.awayScore !== undefined || props.awayScore !== null)) {
    awayScore =
    props.finalAwayScore === 0 || props.finalAwayScore ? props.finalAwayScore : props.awayScore;
  }

  return (
    <div className={['block__max-width', styles.container].join(' ')}>
      <div className={[styles.topSection, 'block__padded'].join(' ')}>
        <h4>{TranslationStore.getTranslation('time_line_header')}</h4>
        {props?.attendance && attendanceTitle ? (
          <span className={'p_2'}>
            {attendanceTitle}: <span className={[styles.attendance]}>{props?.attendance}</span>
          </span>
        ) : null}
      </div>

      {!matchIsLiveOrClosed ? (
        <div className={styles.matchNotStartedContainer}>
          <img className={styles.matchNotStartedImage} src={matchNotStartedIcon} alt='matchNotStartedImage'></img>
          <div className={styles.matchNotStartedText}>Matchen har inte börjat</div>
        </div>
      ) : (
        <div>
          <div className={[styles.teams, 'block__padded'].join(' ')}>
            <div className={[styles.team].join(' ')}>
              {props.competitorHome ? <img src={props.homeTeamLogo} className={styles.teamLogo} /> : ''}
              <h6 className={styles.teamName}>{homeTeamName}</h6>
            </div>
            <div className={styles.scores}>
              <span className={'h2'}>
                {homeScore}
                <span className={styles.scoreDivider}>-</span>
                {awayScore}
              </span>
              {periodScores instanceof Array && periodScores.length ? (
                <ul className={styles.periodScoresList}>
                  {periodScores.map((periodScore, i) => (
                    <li key={'period_score_' + i} className={styles.periodScoresListItem}>
                      {i === 0 ? '(' : ''}
                      {periodScore.homeScore}-{periodScore.awayScore}
                      {i === periodScores.length - 1 ? ')' : ''}
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}

              <span className={['font-tiny-bold', styles.matchStatus].join(' ')}>
                {MatchHelper.getMatchStatusText(props.status)}
              </span>
            </div>
            <div className={[styles.team, styles._away].join(' ')}>
              {props.competitorAway ? <img src={props.awayTeamLogo} className={styles.teamLogo} /> : ''}
              <h6 className={styles.teamName}>{awayTeamName}</h6>
            </div>
          </div>
          <ul className={['p_2', styles.eventList].join(' ')}>
            {timeline instanceof Array && timeline.length > 0 ? (
              timeline.map((item) => {
                return <li key={item.timelineId}>{getEventItemMarkup(item, sportId)}</li>;
              })
            ) : (
              <li />
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Timeline;

import React, { useState } from "react";
import styles from './column-chart.module.less';

const ColumnChart = ({ firstTeamScore, secondTeamScore, mobile }) => { 
    const maxScore = firstTeamScore >= secondTeamScore ? firstTeamScore : secondTeamScore;
    return (
        <div className={styles.mainContainer}>
            <div className={styles.chartContainer}>
                <div style={{height: `${firstTeamScore > 0 ? (firstTeamScore * (mobile ? 40 : 110) / maxScore) : 5}px`}} className={styles.homeChart}>

                </div>
                <div>
                    <span className={styles.HomeScoreNumber}>{firstTeamScore}</span>
                </div>
            </div>

            <div className={styles.chartContainer}>
                <div style={{height: `${secondTeamScore > 0 ? (secondTeamScore * (mobile ? 40 : 110) / maxScore) : 5}px`}} className={styles.awayChart}>

                </div>
                <div>
                    <span className={styles.AwayScoreNumber}>{secondTeamScore}</span>
                </div>

            </div>
        </div>
    )
};

export default ColumnChart;
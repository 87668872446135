import React, { Component } from 'react';
import styles from './match-bet-suggestion.module.less';
import DateHelper from 'components/helpers/date-helper';
import get from 'lodash/get';
import BetShowCaseItem from 'components/block-elements/bet-showcase-item/bet-showcase-item';
import OddsForSuggestion from 'components/block-elements/odds-for-suggestion/odds-for-suggestion';
import TranslationStore from 'components/stores/translation-store';
import Cookies from 'universal-cookie';
import OddsService from 'services/odds-service';

const EbettingType = [
  {id: 0, name: 'Outright'}, 
  {id: 1, name: '1-X-2'},
  {id: 4, name: 'Över/under'},
  {id: 8, name: 'Handicap'},
  {id: 10, name: 'Dubbelchans'},
  {id: 12, name: 'HT/FT'},
  {id: 17, name: 'DNB'},
  {id: 18, name: 'Both Teams To Score'},
  // {id: 32, name: 'Num Of Corners', values: null},
  {id: 999, name: 'Moneyline'}
];

const EbettingLevel = [
  {id: 0, name: 'Låg'}, 
  {id: 1, name: 'Medel'},
  {id: 2, name: 'Hög'},
  {id: 3, name: 'Max'},
]

class MatchBetSuggestion extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    cookies.set(props.matchId, props.alias, { path: '/', maxAge: 345600 });
    this.state = {
      highestOdds: '',
      allOdds: [],
      betSuggestion: {}
    };

    this.id = get(props, 'matchId');
    this.createdAt = new Date(props.matchAnalysisData.updatedAt);
    this.showBetSuggestion = this.state.betSuggestion?.isBetShow;
    this.betType = EbettingType.find(ebt => ebt.id == this.state.betSuggestion?.bettingData?.type).name;
    this.sportAndTournament = get(props, 'matchAnalysisData.sportAndTournament');
    this.betItem = props?.betInfo?.betItemsCount;
    this.suggestedTeam = get(props, 'matchAnalysisData.suggestedTeam');
    this.mcmPlayTo = props?.betInfo?.mcmBetTo;
    this.estimationInPercent = this.state.betSuggestion?.percentValuation;
    this.playableFrom = this.state.betSuggestion?.coefficient;
    this.betLevel = EbettingLevel.find(ebl => ebl.id == this.state.betSuggestion?.stakeLevel).name;
    this.betValue = this.state.betSuggestion?.bettingData?.value;
    this.betSuggestionText = this.state.betSuggestion?.description;
    this.odds = null;
    this.homeTeam = get(props, 'matchAnalysisData.homeTeam');
    this.awayTeam = get(props, 'matchAnalysisData.awayTeam');
    this.tournamentId = get(props, 'matchAnalysisData.tournamentId');

    this.betDetailsLabel = TranslationStore.getTranslation('match_bet_suggestion_bet_details');
    this.createdAtLabel = TranslationStore.getTranslation('match_bet_suggestion_created');
    this.betTypeLabel = TranslationStore.getTranslation('match_bet_suggestion_bet_type');
    this.sportAndTournamentLabel = TranslationStore.getTranslation('match_bet_suggestion_sport_tournament');
    this.betItemLabel = TranslationStore.getTranslation('match_bet_suggestion_bet_object');
    this.suggestedTeamLabel = TranslationStore.getTranslation('match_bet_suggestion_suggestion');
    this.mcmPlayToLabel = TranslationStore.getTranslation('match_bet_suggestion_mcm_play_to');
    this.playableFromLabel = TranslationStore.getTranslation('match_bet_suggestion_playable_from');
    this.betLevelLabel = TranslationStore.getTranslation('match_bet_suggestion_stake_level');
    this.highestOddsNow = TranslationStore.getTranslation('match_bet_suggestion_highest_odds_at_moment');
    this.oddsNowAtLabel = TranslationStore.getTranslation('match_bet_suggestion_odds_now_at');
    this.estimationInPercentLabel = TranslationStore.getTranslation('match_bet_suggestion_estimation_in_percent');
    this.betSuggestionLabel = TranslationStore.getTranslation('match_bet_suggestion_bet_suggestion');
  }

  async componentDidMount() {
    OddsService.getBetSuggestionByMatchId(get(this.matchData, 'matchId'))
    .then(res => {
      this.setState({ betSuggestion: res.data });
    }).catch(err => {
    })
  }

  render() {
    return (
      <div className={['block_max-width block__padded', styles.matchAnalysis].join(' ')}>
      <div className={styles.topBlock}>
          {this.betDetailsLabel && 
          <div className={styles.topTextContainer}>
            <span className={styles.topText}>{this.betDetailsLabel}</span>
          </div>}
          <div className={styles.contentContainer}>
            <div>
              <ul className={[styles.betDetailsList].join(' ')}>
                {this.suggestedTeam && (
                  <li className={styles.betDetailsList__item}>
                    <span className={styles.leftText}>{this.suggestedTeamLabel}:</span> <span className={styles.leftText}>{this.suggestedTeam}</span>
                  </li>
                )}
                {this.mcmPlayTo && (
                  <li className={styles.betDetailsList__item}>
                  <span className={styles.leftText}>{this.mcmPlayToLabel}:</span> <span className={styles.leftText}>{this.mcmPlayTo}</span>
                  </li>
                )}
                <li className={styles.betDetailsList__item}>
                <span className={styles.leftText}>{this.highestOddsNow}:</span> <span className={styles.leftText}>{this.props.betInfo?.bettingData?.maxCoefficient}</span>
                </li>
                {this.playableFrom && (
                  <li className={styles.betDetailsList__item}>
                  <span className={styles.leftText}>{this.playableFromLabel}:</span> <span className={styles.leftText}>{this.playableFrom}</span>
                  </li>
                )}
                {this.estimationInPercent && (
                  <li className={styles.betDetailsList__item}>
                  <span className={styles.leftText}>{this.estimationInPercentLabel}:{' '}</span>
                    <span className={styles.leftText}>{this.estimationInPercent}</span>
                  </li>
                )}
                {this.betLevel && (
                  <li className={styles.betDetailsList__item}>
                  <span className={styles.leftText}>{this.betLevelLabel}:</span> <span className={styles.leftText}>{this.betLevel}</span>
                  </li>
                )}
                
                {this.createdAtLabel && (
                  <li className={styles.betDetailsList__item}>
                  <span className={styles.leftText}>{this.createdAtLabel}:{' '}</span>
                    <span className={styles.leftText}>
                    {`${DateHelper.toStringDDMonth(this.createdAt)} ${DateHelper.toStringTimeHHMM(this.createdAt)}`}
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <div>
                {this.betValue && (
                    <BetShowCaseItem className={styles.matchBetItem} label={this.betType} value={this.betItem} />
                  )}
            </div>
          </div>
      </div>
        <div className={styles.betTypeSuggestionContainer}>
          {/* {this.betType && (
            <BetShowCaseItem className={styles.matchBetItem} label={this.betTypeLabel} value={this.betType} betType />
          )} */}
          {/* {this.betValue && (
            <BetShowCaseItem className={styles.matchBetItem} label={this.betType} value={this.betItem} />
          )} */}
        </div>
        {this.state.allOdds && this.state.allOdds.length > 0 && (
          <div className={styles.oddsRightNow}>
            <h4>{`${this.oddsNowAtLabel} ${this.betValue}`}</h4>
            <OddsForSuggestion odds={this.state.allOdds} />
          </div>
        )}
        {this.betSuggestionText && (
          <div className={styles.matchAnalysisTextBlock}>
            {this.betSuggestionLabel && <h4>{this.betSuggestionLabel}</h4>}
            <div className={styles.textBody} dangerouslySetInnerHTML={{ __html: this.betSuggestionText }} />
          </div>
        )}
      </div>
    );
  }
}

export default MatchBetSuggestion;

import axios from 'components/helpers/axios';

const url = 'api/statistics';

class StatisticService {
  getParticipantPlayed(participant, matchId) {
    return axios.get(`${url}/participant-played?participant=${participant}&sportEventId=${matchId}`);
  }

  getParticipiantH2H(homeParticipant, awayParticipant) {
    return axios.get(`${url}/participants-h2h?participant1=${homeParticipant}&participant2=${awayParticipant}`)
  }
}

const service = new StatisticService();
export default service;

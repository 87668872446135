import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import styles from './bar-chart.module.less';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }
    
  componentDidUpdate() {
    this.myChart.data.datasets[0].data = this.props.data;
    var maxValue = this.props.data[0] > this.props.data[1] ? this.props.data[0] : this.props.data[1];
    this.myChart.data.datasets[1].data = (!this.props.data[0] && !this.props.data[1]) ? [100, 100] : [maxValue-this.props.data[0], maxValue-this.props.data[1]];
    this.myChart.update();
  }
    
  componentDidMount() {
    
    var maxValue = this.props.data[0] > this.props.data[1] ? this.props.data[0] : this.props.data[1];
    var dataSet2 = (!this.props.data[0] && !this.props.data[1]) ? [100, 100] : [maxValue-this.props.data[0], maxValue-this.props.data[1]];
    this.myChart = new Chart(this.chartRef.current, {
      type: 'bar',
      data: {
        labels: ['', ''],
        datasets: [
          {
            label: '1',
            data: this.props.data,
            backgroundColor: [this.props.colors[0], this.props.colors[1]],
            barPercentage: 1,
            categoryPercentage: 0.9,
          },
          {
            label: '2',
            data: dataSet2,
            backgroundColor: [ '#FFFFFF' , '#FFFFFF' ],
            barPercentage: 1,
            categoryPercentage: 0.9,
          },
        ]},
        options: { 
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false},
            tooltip: {enabled: false},
          },
          scales: {
            x: {
              stacked: true,
              display: false,
              grid: {display:false}
            },
            y: {
              stacked: true,
              beginAtZero: true,
              display: false,
              grid: {display:false},
            }
          }
        } 
      }
    );
  }
    
  render() {
    return <div className={styles.canvasContainer}><canvas ref={this.chartRef} /></div>;
  }
}

export default BarChart;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './jersey-element.module.less';
import { ReactSVG } from 'react-svg';
import shadow from 'static/ice_shadow.svg';
import shadowJersey from 'static/jersey_shadow.svg';

class LineupWidget extends Component {
  static propTypes = {
    jerseyColor: PropTypes.string.isRequired,
    jerseyStrypeColor: PropTypes.string.isRequired,
    jerseyStrypeExtraColor: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    peayerNumber: PropTypes.string.isRequired,
    peayerFullName: PropTypes.string.isRequired,
    jerseyScoreColor: PropTypes.string.isRequired,
    jerseyScoreBorderColor: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  jersey(width, height, fill) {
    return (<svg width={width} className={styles.jerseyMain} height={height} fill={fill} version="1.1" id="Jersey"
    xmlns="http://www.w3.org/2000/svg" style={{enableBackground: 'new 0 0 182.9 159.1'}} xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" x="0px" y="0px" viewBox="0 0 182.9 159.1">
 <path id="Jersey_1_" class='st0' d="M161.4,105.7c-0.3-7.6-0.4-13.4-1.1-23.3c-0.5-7-1.2-14.1-1.6-19.2c-0.3-5.1-1.4-20-3.1-27.2
   c-1.6-6.9-3.6-9.8-4.7-11.5c-3-4.7-5.9-6.8-10.9-10c-7.4-4.8-13.8-6.7-21.7-9.1c-2.3-0.7-3.6-1.4-5.2-1.1c-3.6,0.7-8.5,1.5-13.5,2.3
   c-4,0.6-6.7,0.6-8.8,0.5c-2.1,0.1-4.8,0.1-8.8-0.5c-4.9-0.7-9.9-1.6-13.5-2.3c-1.6-0.3-2.9,0.4-5.2,1.1c-7.9,2.3-14.3,4.2-21.7,9.1
   c-4.9,3.2-7.9,5.3-10.9,10c-1.1,1.7-3,4.6-4.7,11.5c-1.7,7.2-2.8,22-3.1,27.2c-0.3,5.1-1,12.3-1.6,19.2c-0.8,10-0.8,15.8-1.1,23.3
   c-0.6,13.4-2,17.3-1.4,19.7c0.6,2.3,3.7,3.3,8.2,4.3c3.5,0.8,12.1-0.4,13.6-2c1.5-1.6,0.9-5.6,1.3-9.9c0.5-4.3,1.2-9.3,2.5-16.1
   c1.1-6,1.6-7.7,4-17c3.9-15.2,3.9-20.5,4.6-20.4c0.4,0.1,1.1,5.8,0.7,29.4c-0.2,12-0.8,21.1-1.1,25c-0.7,7.6-1.3,13.7-2.2,17.9
   c-0.1,0.5-0.9,2.1-0.5,3.5c0.4,1.5,3.8,2.8,6.7,3.7c2.9,0.8,12.6,2.7,19.1,3.2c4.6,0.4,7.9,0.7,15.1,0.8c7.2,0,10.5-0.4,15.1-0.8
   c6.4-0.5,16.1-2.4,19.1-3.2c2.9-0.8,6.3-2.2,6.7-3.7c0.4-1.4-0.4-3-0.5-3.5c-0.9-4.2-1.5-10.3-2.2-17.9c-0.3-3.9-0.9-13-1.1-25
   c-0.4-23.6,0.3-29.4,0.7-29.4c0.7-0.1,0.8,5.1,4.6,20.4c2.4,9.3,2.9,11,4,17c1.2,6.8,2,11.9,2.5,16.1c0.5,4.3-0.2,8.2,1.3,9.9
   c1.5,1.6,10.1,2.9,13.6,2c4.5-1.1,7.6-2,8.2-4.3C163.5,123,162,119.1,161.4,105.7z"/>
 </svg>)
  }

//   jerseyShadow(width, height) {
//     return(<svg version="1.1" id="Shadow_bottom" width={width} height={height}
// 	 xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 182.9 159.1"
// 	 style={{enableBackground: 'new 0 0 182.9 159.1', marginLeft: `-${width}px`}} xmlSpace="preserve">
// <radialGradient id="Shadow_bottom_2_" cx="124.8105" cy="3203.3428" r="286.4177" gradientTransform="matrix(0.3183 0 0 -5.600943e-02 51.717 322.6592)" gradientUnits="userSpaceOnUse">
// 	<stop  offset="0.3432" style={{stopColor: '#00040E'}}/>
// 	<stop  offset="0.8087" style={{stopColor:'#708B99', stopOpacity: '0'}}/>
// 	<stop  offset="1" style={{stopColor: '#FFFFFF', stopPpacity: '0' }}/>
// </radialGradient>
// <ellipse id="Shadow_bottom_1_" class="st0" cx="91.4" cy="143.2" rx="91.4" ry="15.9"/>
// </svg>)
//   }

  frontSVG(width, height, fill) {
    return(<svg width={width} className={styles.frontSVGMain} height={height} fill={fill} version="1.1" id="Sleeves_and_stripes"
    xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 182.9 159.1">
 <path id="Sleeves_and_stripe_7_" class="st0" d="M112.8,4.3c-3.6,0.7-8.3,1.5-13.1,2.2c-4,0.6-6.7,0.6-8.8,0.5
   c-2.1,0.1-4.8,0.1-8.8-0.5C77.4,5.8,72.6,5,69.1,4.3c1.1-1.5,3-3.6,5.5-4.1c4-0.8,9.3,0.6,12.2,0.7c1.3,0,2.7,0.1,4.1,0.1
   c1.4,0,2.8,0,4.1-0.1c2.9-0.1,8.2-1.4,12.2-0.7C109.8,0.7,111.7,2.8,112.8,4.3z M131.3,136.5c-0.4-1.8-0.7-3.9-1-6.3
   c-5.9,2.1-20.1,6.1-39.4,6.2c-19.3-0.1-33.5-4-39.4-6.2c-0.3,2.4-0.6,4.5-1,6.3c0,0.1-0.1,0.3-0.1,0.4c5.1,2.2,17.5,6.4,40.5,6.4
   s35.4-4.1,40.5-6.4C131.4,136.8,131.3,136.6,131.3,136.5z M23,64.9c-0.4,4.9-1,11.2-1.4,17.4c-0.4,5.4-0.6,9.5-0.8,13.4
   c3.5,1.2,13.2,4,24.4,2.4c0.7-3.6,1.5-6.3,3.3-13.5c2.1-8.3,3.1-13.6,3.7-16.8C49.1,68.5,35.1,70.9,23,64.9z M161.1,95.7
   c-0.2-3.9-0.4-8-0.8-13.4c-0.5-6.2-1.1-12.5-1.4-17.4c-12,6-26.1,3.5-29.2,2.9c0.6,3.1,1.5,8.4,3.7,16.8c1.8,7.3,2.6,9.9,3.3,13.5
   C147.9,99.7,157.6,96.9,161.1,95.7z"/>
 </svg>)
  }

  iceShadowSvg() {
    return(<img className={styles.shadow} src={shadow} />)
  }

  iceShadowJerseySvg() {
    return(<img className={styles.shadowJersey} src={shadowJersey} />)
  }

  growSVG(width, height, fill) {
    return(<svg version="1.1" className={styles.growMainSvg} id="Extra_stripes" width={width} height={height} fill={fill}
    xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 182.9 159.1">
    <path id="Extra_stripes_00000093858571216849485590000009146837911229536384_" class="st0" d="M90.9 128.4c19-.1 32.5-4 38.5-6.2.1 1 .7 7.1.9 8-5.9 2.1-20.1 6.1-39.4 6.2-19.3-.1-33.5-4-39.4-6.2.1-.9.7-7 .9-8 6.1 2.2 19.6 6.1 38.5 6.2zM51.7 70.3c.3-1.6.8-4 1-5-3.1.6-17.4 3-29.5-2.9-.1 1.4-.3 3.9-.4 5.5 6.2 2.2 17.3 5.3 28.9 2.4zM20.9 92.9c0 1-.2 4.8-.2 5.7 3.5 1.2 12.8 3.9 24 2.3.2-1 .9-4.7 1.2-5.6-10.4 2-19-.2-25-2.4zm138.2-25.2c-.1-1.5-.3-4.1-.4-5.5-12 6-26.4 3.6-29.5 2.9.2 1.1.6 3.4.9 5 11.7 3.1 22.7 0 29-2.4zm-23.2 27.5c.2 1 1 4.6 1.2 5.6 11.2 1.6 20.5-1.1 24-2.3 0-.9-.2-4.8-.3-5.7-6 2.3-14.6 4.5-24.9 2.4z"/>
 </svg>)
  }

  playerNumber(number, color, borderColor) {
      return(
          <div style={{color: color}} className={styles.playerNumberContainer}>
              <span className={styles.playerNumber}>{number}</span>
          </div>
      )
  }


  render() {

    const { jerseyColor, jerseyStrypeColor, jerseyStrypeExtraColor, width, height, peayerNumber, peayerFullName, jerseyScoreColor, jerseyScoreBorderColor} = this.props;
    return (
      <div style={{maxWidth: `${width}px`, height: `136px`, display: 'inline-block', textAlign: 'center'}} className={styles.mainContainer}>
        {this.jersey(width, height, jerseyColor)}
        {this.iceShadowJerseySvg()} 
        {this.frontSVG(width, height, jerseyStrypeColor)}
        {this.growSVG(width, height,jerseyStrypeExtraColor)}
        {this.playerNumber(peayerNumber, jerseyScoreColor, jerseyScoreBorderColor)}
        {this.iceShadowSvg()}
        <div className={styles.playerNameContainer}><span>{peayerFullName}</span></div>
      </div>
    );
  }
};

export default LineupWidget;

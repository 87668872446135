import React, { Component } from 'react';
import styles from './three-way-list.module.less';
import TranslationStore from 'components/stores/translation-store';
import LogoHelper from 'components/helpers/logo-helper';
import firstImage from 'static/1.png';
import secondImage from 'static/2.png';
import versusImage from 'static/X.png';

export default class ThreeWayList extends Component {

  getOdds(bet){
    if(bet.currentOdds){
      return bet.currentOdds.substr(0,4);
    }
    else if(bet.openingOdds){
      return bet.openingOdds.substr(0,4);
    }

    return "";
  }

  getTrend(bet){
    if(bet === false){
      return <span className={styles.triangleDown}></span>
    }
    else if(bet === true){
      return <span className={styles.triangleUp}></span>
    }

    return <span className={styles.triangleFiller}></span>
  }

  render(){
    if(!this.props.list || this.props.list?.odds?.length === 0){
      return <p className={styles.description}>{TranslationStore.getTranslation("match_odds_comparsion_odds_not_available")}</p>;
    }

      return (
        <>
        <div className={styles.topText}>
          <span>Tippa rätt tecken</span>
        </div>
        <div className={styles.mainTopBlockContainer}>
          <div className={styles.rowTopBlockContainer}>
              <div className={styles.colTopBlockContainer}>
                <div className={styles.column}>
                  <div className={styles.rowTopBlockContainer}>
                    <img src={firstImage}></img>
                  </div>
                  <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameHomeId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameHomeId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                  <div className={styles.rowTopBlockContainer}>
                      {this.props.list?.topHomeWinOdd?.toFixed(2)}
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={styles.rowTopBlockContainer}>
                    <img src={versusImage}></img>
                  </div>
                  <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                  <div className={styles.rowTopBlockContainer}>
                      {this.props.list?.topDrawOdd?.toFixed(2)}
                  </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.rowTopBlockContainer}>
                      <img src={secondImage}></img>
                    </div>
                    <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameAwayId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameAwayId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                    <div className={styles.rowTopBlockContainer}>
                      {this.props.list?.topAwayWinOdd?.toFixed(2)}
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div className={styles.preTableText}>
          <span>Bäst odds just nu</span>
        </div>
          <ul className={styles.bestOddsListHeader}>
            <div className={styles.leftColumns}>
              <li className={styles.bestOddsHeaderLabel}></li>
              <li className={styles.bestOddsHeaderPayout}>Payout</li>
            </div>
            <div className={styles.rightColumns}>
              <li className={styles.bestOddsHeaderNumbers}>
                1
              </li>
              <li className={styles.bestOddsHeaderNumbers}>
                X
              </li>
              <li className={styles.bestOddsHeaderNumbers}>
                2
              </li>
            </div>
          </ul>

          <ul className={styles.list2way}>
          {
            this.props.list?.odds?.map((li,i) => <li className={styles.listItem2way} key={"list_" + i}>
            <ul className={styles.bestOddsListBody}>
                <div className={styles.leftColumns}>
                    <li className={styles.bestOddsHeaderLabel}>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                    </li>

                    <li className={styles.bestOddsHeaderPayout}>
                      <span className={styles.payoutText}>{`${li.payoutPercentage?.toFixed(2) ?? 0} %`}</span>
                    </li>
                </div>
                <div className={styles.rightColumns}>
                    <li className={`${styles.bestOddsHeaderNumbers} ${li.homeWinOdd == this.props.list.topHomeWinOdd ? styles.bestOdd : ''}`}>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                          <span className={styles.listItemBet2wayOdds}>{li.homeWinOdd.toFixed(2)}</span>
                          {this.getTrend(li.homeWinOddIncreased)}
                        </a>
                    </li>

                    <li className={`${styles.bestOddsHeaderNumbers} ${li.drawOdd == this.props.list.topDrawOdd ? styles.bestOdd : ''}`}>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                          <span className={styles.listItemBet2wayOdds}>{li.drawOdd.toFixed(2)}</span>
                          {this.getTrend(li.drawOddIncreased)}
                        </a>
                    </li>

                    <li className={`${styles.bestOddsHeaderNumbers} ${li.awayWinOdd == this.props.list.topAwayWinOdd ? styles.bestOdd : ''}`}>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                          <span className={styles.listItemBet2wayOdds}>{li.awayWinOdd.toFixed(2)}</span>
                          {this.getTrend(li.awayWinOddIncreased)}
                        </a>
                    </li>
                </div>
   
            </ul>
              </li>)
          }
          </ul>
        </>
      )
  }
}
import React, { Component } from 'react';
import styles from './two-way-list.module.less';
import TranslationStore from 'components/stores/translation-store';
import LogoHelper from 'components/helpers/logo-helper';
import firstImage from 'static/1.png';
import secondImage from 'static/2.png';
import versusImage from 'static/X.png';

export default class TwoWayList extends Component {

  getOdds(bet){
    if(bet.currentOdds){
      return bet.currentOdds.substr(0,4);
    }
    else if(bet.openingOdds){
      return bet.openingOdds.substr(0,4);
    }

    return "";
  }

  getTrend(bet){
    if(bet === false){
      return <span className={styles.triangleDown}></span>
    }
    else if(bet === true){
      return <span className={styles.triangleUp}></span>
    }

    return <span className={styles.triangleFiller}></span>
  }

  render(){
    if(!this.props.list || this.props.list.odds.length === 0){
      return <p className={styles.description}>{TranslationStore.getTranslation("match_odds_comparsion_odds_not_available")}</p>;
    }

      return (
        <>
        <div className={styles.topText}>
          <span>{this.props.isSportHockey ? 'Inklusive övertid' : 'Pengarna tillbaka vid oavgjort resultat'}</span>
        </div>
        <div className={styles.mainTopBlockContainer}>
          <div className={styles.rowTopBlockContainer}>
              <div className={styles.colTopBlockContainer}>
                <div className={styles.column}>
                  <div className={styles.rowTopBlockContainer}>
                    <img src={firstImage}></img>
                  </div>
                  <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameHomeId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameHomeId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                  <div className={styles.rowTopBlockContainer}>
                      {this.props.list.topOddHome.toFixed(2)}
                  </div>
                </div>

                <div className={styles.column}>
                    <div className={styles.rowTopBlockContainer}>
                      <img src={secondImage}></img>
                    </div>
                    <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameAwayId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${this.props.list.topBookieNameAwayId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                    <div className={styles.rowTopBlockContainer}>
                      {this.props.list.topOddAway.toFixed(2)}
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div className={styles.preTableText}>
          <span>Bäst odds just nu</span>
        </div>
          <ul className={styles.bestOddsListHeader}>
            <div className={styles.leftColumns}>
              <li className={styles.bestOddsHeaderLabel}></li>
              <li className={styles.bestOddsHeaderPayout}>Payout</li>
            </div>
            <div className={styles.rightColumns}>
              <li className={styles.bestOddsHeaderNumbers}>
                1
              </li>
              <li className={styles.bestOddsHeaderNumbers}>
                2
              </li>
            </div>
          </ul>

          <ul className={styles.list2way}>
          {
            this.props.list.odds.map((li,i) => <li className={styles.listItem2way} key={"list_" + i}>
            <ul className={styles.bestOddsListBody}>
                <div className={styles.leftColumns}>
                    <li className={styles.bestOddsHeaderLabel}>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} target="_blank" rel="noopener noreferrer"><img src={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.logo} alt="" className={styles.betLogo2way} /></a>
                    </li>

                    <li className={styles.bestOddsHeaderPayout}>
                      <span className={styles.payoutText}>{`${li.payoutPercentage?.toFixed(2) ?? 0}%`}</span>
                    </li>
                </div>
                <div className={styles.rightColumns}>
                    <li className={`${styles.bestOddsHeaderNumbers} ${li.homeOdd == this.props.list.topOddHome ? styles.bestOdd : ''}`}>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                          <span className={styles.listItemBet2wayOdds}>{li.homeOdd.toFixed(2)}</span>
                          {this.getTrend(li.homeOddIncreased)}
                        </a>
                    </li>

                    <li className={`${styles.bestOddsHeaderNumbers} ${li.awayOdd == this.props.list.topOddAway ? styles.bestOdd : ''}`}>
                        <a href={(this.props.betLinks.find(bl => bl.id == `e:provider:${li.bookieId}`))?.url} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                          <span className={styles.listItemBet2wayOdds}>{li.awayOdd.toFixed(2)}</span>
                          {this.getTrend(li.awayOddIncreased)}
                        </a>
                    </li>
                </div>
   
            </ul>
              </li>)
          }
          </ul>
        </>
      )
  }
}
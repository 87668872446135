import React, { Component } from 'react';
import OddsService from 'services/odds-service';
import DateHelper from 'components/helpers/date-helper';
import ThreeWayList from 'components/block-elements/match-odds-comparsion/three-way-list/three-way-list';
import TotalList from 'components/block-elements/match-odds-comparsion/total-list/total-list';
import TwoWayList from 'components/block-elements/match-odds-comparsion/two-way-list/two-way-list';
import SpreadList from 'components/block-elements/match-odds-comparsion/spread-list/spread-list';
import HcpList from 'components/block-elements/match-odds-comparsion/hcp-list/hcp-list';
import TranslationStore from 'components/stores/translation-store';
import MatchHelper from 'components/helpers/match-helper';
import styles from './match-odds-comparsion.module.less';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import EventAggregator from 'components/helpers/event-aggregator';
import Loader from 'components/block-elements/loader-css/loader-css';
import OddsHeader from './header/odds-header';
import Cookies from 'universal-cookie';
// import odds from 'static/odds.json' // как только запрос будет работать расскоментить это (смотри стр. 90)

export default class MatchOddsComparsion extends Component {
  constructor(props) {
    super(props);
    // this._isMounted = false;
    this.isSportHockey = MatchHelper.isMatchHockey(this.props.sportId);
    this.timeoutId = null;
    const cookies = new Cookies();
    cookies.set(props.matchId, props.alias, { path: '/', maxAge: 345600 });

    this.oddsList = [
      {
        label: '1x2 TREVÄGS',
        value: '3way',
        isDisabled: false,
      },
      {
        label: 'ÖVER/ UNDER',
        value: 'total',
        isDisabled: false,
      },
      {
        label: props.sportId == "e:sport:5" ? 'Money-\nline' : 'DRAW NO\u00A0BET',
        value: '2way',
        isDisabled: false,
      },

      {
        label: 'DUBBEL- CHANS',
        value: 'spread',
        isDisabled: false,
      },
      {
        label: 'Hcp TREVÄGS',
        value: 'handicap',
        isDisabled: false,
      },
    ];

    this.state = {
      odds: [],
      bettingCompanies: [],
      selectedOdds: this.oddsList[0],
      currentTime: '',
      isLoading: false,
      timerId: null,
      activeTab: '3way',
    };
  }

  getOdds() {
    OddsService.odds(this.props.id, this.props.sportId).then((res) => {
      this.setState({
        currentTime: DateHelper.moment().format('HH:mm'),
        odds: res.data,
      });
  }).catch(err => {
    console.log(err);
  })
  }

  async componentDidMount() {
    const timerId = setInterval(() => { this.getOdds() }, 5000);


    this.setState({ timerId: timerId });

    this.setState({ isLoading: true });
    await Promise.all([
        OddsService.getBettingCompanies('', 0, 100)
        .then(res => {
          this.setState({ bettingCompanies: res.data.data })
        }).catch(err => {
    
        }),
        OddsService.odds(this.props.id, this.props.sportId).then((res) => {
              this.setState({
                currentTime: DateHelper.moment().format('HH:mm'),
                isLoading: false,
                odds: res.data,
              });
          })
    ]).then(() => {
      this.setState({isLoading: false});
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  getOddsListItemHtml(props) {
    return (
      <span
        className={[
          styles.filterBtn,
          'ui-btn--small',
          props.value >= 0 &&
          props.value === this.state.selectedOdds.value &&
          props.label === this.state.selectedOdds.label
            ? styles.selected
            : '',
        ].join(' ')}>
        <span className={'ui-btn__label'}>{props.label}</span>
      </span>
    );
  }

  renderOddsType() {

    if(this.state.isLoading) {
        return (
          <div className={'loader-container'}>
            <Loader />
          </div>
        );
    }

    if (this.state.odds !== undefined) {
      if (this.state.odds && this.state.bettingCompanies) {
        switch (this.state.activeTab) {
          case '2way':
            return this.state.odds.drawNoBet?.odds?.length > 0 ? <TwoWayList list={this.state.odds.drawNoBet} isSportHockey={this.isSportHockey} betLinks={this.state.bettingCompanies}/> : <></>;
          case '3way':
            return this.state.odds.threeWay?.odds?.length > 0 ? <ThreeWayList list={this.state.odds.threeWay} betLinks={this.state.bettingCompanies}/> : <></>;
          case 'total':
            return this.state.odds.overUnder?.length > 0 ? <TotalList list={this.state.odds.overUnder} betLinks={this.state.bettingCompanies} /> : <></>;
          case 'spread':
            return this.state.odds.doubleChance?.length > 0 ? <SpreadList list={this.state.odds.doubleChance} betLinks={this.state.bettingCompanies} /> : <></>;
          case 'handicap':
            return this.state.odds.handicap?.length > 0 ? <HcpList list={this.state.odds.handicap} betLinks={this.state.bettingCompanies} /> : <></>;
          default:
            return '';
        }
      }
    }
  }

  render() {
    let listOfOddsCurrentSelection = [];
    if (!this.props.isOddsLoading && this.state.odds instanceof Array && this.state.selectedOdds.value) {
      listOfOddsCurrentSelection = this.state.odds.filter((li) => this.state.selectedOdds.value === li.oddsTypeName);
    }

    return (
      <div>
          <div className={styles.maxWidth}>
            <OddsHeader currentTime={DateHelper.moment().format('HH:mm')} />
            <div className={styles.buttonWrapper} style={{ marginTop: '30px' }}>
              {this.oddsList.map((button, index) => (
                <UiBtn
                  isTwiceLine
                  key={index}
                  label={button.label}
                  onClick={() => {
                    this.setState({ activeTab: button.value });
                    EventAggregator.publish('trackEvent', {
                      category: 'Tournament tab',
                      action: this.tournamentName,
                      label: button.label,
                    });
                  }}
                  className={[
                    this.state.activeTab === button.value ? styles.activeButton : styles.inactiveButton,
                    index === 0 && styles.firstButton,
                    styles.alternativeButton,
                  ].join(' ')}
                />
              ))}
            </div>

            {this.renderOddsType()}
          </div>
      </div>
    );
  }
}

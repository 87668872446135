import axios from 'components/helpers/axios';

const url = 'api/comments';

class CommentsService {
  getComments(sportEventId) {
    return axios.get(`${url}/${sportEventId}`);
  }
  
}

const singleton = new CommentsService();
export default singleton;
import React from 'react';
import TranslationStore from 'components/stores/translation-store';
import styles from './odds-header.module.less'

const OddsHeader = ({ currentTime }) => (
    <div className={styles.oddsHeaderWrapper}>
        <h4 className={styles.header}>{TranslationStore.getTranslation("match_odds_comparsion_header")}</h4>
        <div className={styles.currentTime}>{currentTime}</div>
    </div>
);

export default OddsHeader;
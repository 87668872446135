import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import UiSelect from 'components/block-elements/ui-select/ui-select';
import TabTransition from 'components/block-elements/tab-transition/tab-transition';
import styles from './match-tabs.module.less';
import LiveMatchContext from 'components/stores/live-matches-context';
import EventAggregator from 'components/helpers/event-aggregator';

export default class MatchTabs extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        props: PropTypes.shape({
          label: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
  };

  constructor(props) {
    super(props);
    this.defaultTabIndex = 0;

    if(this.props.selectMatchTabByAlias){
      let foundIndex = this.props.children.findIndex(child => child.props.alias === this.props.selectMatchTabByAlias);
      
      if(foundIndex >= 0){
        this.defaultTabIndex = foundIndex;
      }
    }
     
    this.state = {
      activeTab: parseInt(props.children[this.defaultTabIndex].key),
      animationDirection: 1,
    };

    this.ref_tabSelect = React.createRef();
  }

  formatToSelectOptions(children) {
    return (
      children &&
      children.map(item => ({
        key: parseInt(item.key),
        value: item.props,
        text: item.props.label,
      }))
    );
  }

  onChangeSelectTab(tab) {
    if (tab && tab.key > -1) {
      const animationDirection = this.state.activeTab < tab.key ? 1 : -1;
      this.setState({ activeTab: tab.key, animationDirection });
      EventAggregator.publish("trackEvent", { category: 'Match tab', action: 'Viewed', label: tab.text });
    }
  }

  onTabBtnClick(direction) {
    const props = this.props;
    let tabKey = parseInt(this.state.activeTab) + direction;
    if (tabKey > props.children.length - 1) {
      tabKey = props.children[0].key;
    } else if (tabKey < 0) {
      tabKey = props.children[props.children.length - 1].key;
    }

    this.setState({ activeTab: tabKey, animationDirection: direction }, () => {
      const activeChild = props.children.find(item => parseInt(item.key) === parseInt(tabKey));
      activeChild &&
        this.ref_tabSelect.current.setState({
          activeOption: {
            key: parseInt(activeChild.key),
            value: activeChild.props,
            text: activeChild.props.label,
          },
        });
        this.context.setChosenTab(props.matchId, activeChild.props.alias);
        EventAggregator.publish("trackEvent", { category: 'Match tab', action: 'Viewed', label: activeChild.props.label });
    });
  }

  render() {
    const {
      props: { children },
      state: { activeTab, animationDirection },
    } = this;

    const activeChild = children.find(item => parseInt(item.key) === parseInt(activeTab));
    const selectOptions = this.formatToSelectOptions(children);
    
    return (
      <div className={styles.tabs}>
        <div className={styles.tabsWrapper}>
          <UiBtn
            className={['ui-btn ui-btn--medium ui-btn--medium-icon-square waves-effect', styles.arrowButton].join(' ')}
            iconLeft={{ icon: 'icon-keyboard_arrow_left', align: 'center' }}
            onClick={() => this.onTabBtnClick(-1)}
          />
          <UiSelect
            ref={this.ref_tabSelect}
            icon={{ icon: '' }}
            wrapperClassName={styles.selectWrapper}
            buttonClassName={['ui-btn--medium ui-btn--primary waves-effect', styles.selectButton].join(' ')}
            options={selectOptions}
            defaultOption={selectOptions[this.defaultTabIndex]}
            onChange={val => this.onChangeSelectTab(val)}
          />
          <UiBtn
            className={['ui-btn ui-btn--medium ui-btn--medium-icon-square waves-effect', styles.arrowButton].join(' ')}
            iconRight={{ icon: 'icon-keyboard_arrow_right', align: 'center' }}
            onClick={() => this.onTabBtnClick(1)}
          />
        </div>
        {activeChild && <TabTransition direction={animationDirection}>{[activeChild]}</TabTransition>}
      </div>
    );
  }
}

MatchTabs.contextType = LiveMatchContext;
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styles from './match-page.module.less';
import Page from 'components/page';
import LiveScoreMatchItem from 'components/block-elements/live-score-match-item/live-score-match-item';
import MatchTabs from 'components/block-elements/match-tabs/match-tabs';
import MatchBetSuggestion from 'components/block-elements/match-bet-suggestion/match-bet-suggestion';
import MatchAnalysis from 'components/block-elements/match-analysis/match-analysis';
import MatchPlayerInfo from 'components/block-elements/match-player-info/match-player-info';
import MatchOddsComparsion from 'components/block-elements/match-odds-comparsion/match-odds-comparsion';
import MatchWidget from 'components/block-elements/match-widget/match-widget';
import MatchDetailsTab from 'components/block-elements/match-details-tab/match-details-tab';
import TournamentStandingsWrapper from 'components/block-elements/tournament-standings-wrapper/tournament-standings-wrapper';
import TournamentScoringWrapper from 'components/block-elements/tournament-scoring-wrapper/tournament-scoring-wrapper';
import TranslationStore from 'components/stores/translation-store';
import MatchEventsConfigurationStore from 'components/stores/match-events-configuration-store';
import LanguageHelper from 'components/helpers/language-helper';
import MatchHelper from 'components/helpers/match-helper';
import ThemeHelper from 'components/helpers/theme-helper.js';
import TeamHelper from 'components/helpers/team-helper';
import SportEventService from 'services/sport-event-service';
import { liveUpdateInterval } from 'components/stores/live-matches-context';
import StatisticWidget from 'components/block-elements/match-statistic-widget/match-statistic-widget';
// import FootballEnetpuleWidget from 'components/block-elements/football-enetpulse-widget/football-enetpulse-widget';
import Cookies from 'universal-cookie';
import Loader from 'components/block-elements/loader-css/loader-css';
import OddsService from 'services/odds-service';
import 'static/quill.css'
import LogoHelper from 'components/helpers/logo-helper';

export default class MatchPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { matchInfo: null, customTexts: null, isLoading: true, odds: null, isOddsLoading: true, betSuggestion: null, sportEvent: {} };
    const matchData = get(props, 'data.contentfulMatch');

    matchData.homeTeamName = matchData.homeTeamDisplayName
      ? matchData.homeTeamDisplayName
      : matchData.competitorNameHome;
    matchData.awayTeamName = matchData.awayTeamDisplayName
      ? matchData.awayTeamDisplayName
      : matchData.competitorNameAway;

    LanguageHelper.setLocale(get(props, 'data.contentfulMatch.node_locale'));

    MatchEventsConfigurationStore.init(get(props, 'data.contentfulMatchEventsConfiguration.eventsList'));
    ThemeHelper.setTheme('mcm');
    //if(typeof window !== 'undefined'){
    this.matchData = TeamHelper.updateMatchDataWithAttributesFromTeamStore(matchData);
    //}

    if (typeof window !== 'undefined' && window.location.search) {
      const pageParams = {};

      window.location.search.substr(1).split`&`.forEach((item) => {
        let [k, v] = item.split`=`;
        v = v && decodeURIComponent(v);
        (pageParams[k] = pageParams[k] || []).push(v);
      });
      if (pageParams['match_tab_alias'] instanceof Array && pageParams['match_tab_alias'].length) {
        this.match_tab_alias = pageParams['match_tab_alias'][0];
      }
    }
  }

  createContentPagePropsFromMatch() {
    // Create props similar to ContentPage props so <Page> component can be used for both ContentPage and Match
    this.showBetSuggestion = get(this.matchData, 'showBetSuggestion');
    this.betAnalysisText =
      get(this.matchData, 'betAnalysisText.childMarkdownRemark.html') ?? this.state.customTexts?.matchAnalizeText;
    this.thinkAboutText =
      get(this.matchData, 'thinkAboutText.childMarkdownRemark.html') ?? this.state.customTexts?.thinkOfText;

    const homeTeam = get(this.matchData, 'homeTeamName');
    const awayTeam = get(this.matchData, 'awayTeamName');
    const tournament = get(this.matchData, 'tournamentName');
    const tournamentId = get(this.matchData, 'tournamentId');
    const sportId = get(this.matchData, 'sportId');

    return {
      id: get(this.matchData, 'id'),
      matchId: get(this.matchData, 'matchId'),
      title: `${homeTeam} - ${awayTeam}`,
      description: '',
      keywords: '',
      excludePageFromExternalSearch: false,
      showScrollToTopButton: true,
      slug: get(this.matchData, 'pageContext.slug'),
      matchAnalysisInfo: {
        betItem: `${homeTeam} - ${awayTeam}`,
        sportAndTournament: `${get(this.matchData, 'sport')}, ${tournament}`,
        updatedAt: get(this.matchData, 'updatedAt'),
        betType: get(this.matchData, 'betType'),
        betValue: get(this.matchData, 'betValue'),
        suggestedTeam: get(this.matchData, 'suggestedTeam'),
        mcmPlayTo: get(this.matchData, 'mcmPlayTo'),
        estimationInPercent: get(this.matchData, 'estimationInPercent'),
        playableFrom: get(this.matchData, 'playableFrom'),
        betLevel: get(this.matchData, 'betLevel'),
        betSuggestionText:
          get(this.matchData, 'betSuggestionText.childMarkdownRemark.html') ??
          this.state.customTexts?.betSuggestionsText,
        betAnalysisText: this.betAnalysisText,
        beforeMatchText:
          get(this.matchData, 'beforeMatchText.childMarkdownRemark.html') ??
          this.state.customTexts?.beforeTheGameStartsText,
        thinkAboutText: this.thinkAboutText ?? this.state.customTexts?.thinkOfText,
        buzzAboutText:
          get(this.matchData, 'buzzAboutText.childMarkdownRemark.html') ?? this.state.customTexts?.talkingAboutText,
        homeTeamAbsence: get(this.matchData, 'injuredPlayersHomeTeam'),
        awayTeamAbsence: get(this.matchData, 'injuredPlayersAwayTeam'),
        homeTeam: homeTeam,
        awayTeam: awayTeam,
        tournamentId: tournamentId,
        sportId,
        homeTeamLogo: get(this.matchData, 'homeTeamLogo.file.url'),
        competitorIdHome: get(this.matchData, 'competitorIdHome'),
        awayTeamLogo: get(this.matchData, 'awayTeamLogo.file.url'),
        competitorIdAway: get(this.matchData, 'competitorIdAway'),
      },
      standingsInfo: {
        tournamentId: tournamentId,
        sportId,
        tournamentName: tournament,
        homeTeamId: get(this.matchData, 'competitorIdHome'),
        awayTeamId: get(this.matchData, 'competitorIdAway'),
      },
    };
  }

  async componentDidMount() {
    this.liveUpdate();
  }

  componentWillUnmount() {
    
  }

  isMatchClosed() {
    return (
      MatchHelper.isMatchClosed(this.state.sportEvent.status)
    );
  }

  liveUpdate() {
    SportEventService.getSportEvent(this.matchData.matchId)
      .then((res) => {
        this.setState({ sportEvent: res.data, isLoading: false })

          !this.isMatchClosed() && setTimeout(() => this.liveUpdate(), liveUpdateInterval);
      })
      .catch((error) => {})
  }

  // getMappedOdds(apiResponse) {
  //   const odds = {};
  //   odds.drawNoBet = { ...apiResponse.drawNoBet, logo: LogoHelper.getBettingCompanyLogoUrl(apiResponse.drawNoBet) };
  //   odds.threeWay = { ...apiResponse.threeWay, logo: LogoHelper.getBettingCompanyLogoUrl(apiResponse.threeWay) };
  //   odds.overUnder = apiResponse.overUnder;
  //   odds.doubleChance = apiResponse.doubleChance;
  //   odds.handicap = apiResponse.handicap;
  //   return odds;
  // }

  renderHiddenMatchWidget() {
    return !this.isMatchClosed() ? (
      <MatchWidget
        {...this.matchData}
        {...this.state.sportEvent}
        {...this.state.venue}
        wrapperClassName={'visibility-hidden'}
        elementId={'hidden-live-match-tracker'}
        widgetType={'match.lmtEssential'}
      />
    ) : null;
  }

  renderTopSection() {
    return <LiveScoreMatchItem isMatchPage={true} {...this.state.sportEvent} />;
  }

  renderTabs() {
    const components = [
      {
        name: MatchDetailsTab,
        props: {
          alias: 'matchEvents',
          label: TranslationStore.getTranslation('match_tabs_match_events'),
          ...this.matchData,
          ...this.state.sportEvent,
        },
      },
      {
        name: TournamentStandingsWrapper,
        props: {
          alias: 'matchStandingStatistics',
          isMatchPage: true,
          label: TranslationStore.getTranslation('match_tabs_standing_statistics'),
          liveUpdate: !this.isMatchClosed(),
          ...this.props.data.contentfulContentPage.standingsInfo,
          ...this.state.sportEvent,
        },
      },
      {
        name: TournamentScoringWrapper,
        props: {
          alias: 'matchScoringStatistics',
          isMatchPage: true,
          label: TranslationStore.getTranslation('match_tabs_scoring_statistics'),
          ...this.props.data.contentfulContentPage.standingsInfo,
          ...this.state.sportEvent,
        },
      },
      {
        name: StatisticWidget,
        props: {
          alias: 'matchStatistics',
          isMatchPage: true,
          label: TranslationStore.getTranslation('match_tabs_statistics'),
          elementId: 'headToHead',
          widgetType: 'match.headToHead',
          ...this.matchData,
          homeTeamLogo: this.state.sportEvent.homeTeamLogo,
          awayTeamLogo: this.state.sportEvent.awayTeamLogo
        },
      },
      {
        name: MatchPlayerInfo,
        props: {
          alias: 'matchPlayerStatistics',
          label: TranslationStore.getTranslation('match_tabs_player_statistics'),
          ...this.matchData,
          ...this.state.sportEvent,
        },
      },
      {
        name: MatchOddsComparsion,
        props: {
          alias: 'oddsComparsion',
          label: TranslationStore.getTranslation('match_tabs_odds_comparsion'),
          ...this.matchData,
          isMatchPage: true,
          matchId: this.props.data.contentfulContentPage.matchId,
          matchAnalysisData: this.props.data.contentfulContentPage.matchAnalysisInfo,
          ...this.matchData,
          ...this.state.sportEvent,
          odds: this.state.odds,
          isOddsLoading: this.state.isOddsLoading
        },
      },
    ];
    this.state.sportEvent.isHasCustomTexts &&
      components.unshift({
        name: MatchAnalysis,
        props: {
          alias: 'matchAnalysis',
          isMatchPage: true,
          label: TranslationStore.getTranslation('match_analysis'),
          matchId: this.props.data.contentfulContentPage.matchId,
          matchAnalysisData: this.props.data.contentfulContentPage.matchAnalysisInfo,
          ...this.matchData,
          ...this.state.sportEvent,
          odds: this.state.odds,
          isOddsLoading: this.state.isOddsLoading
        },
      });

    this.state.sportEvent.isHasBetSuggestion &&
      components.unshift({
        name: MatchBetSuggestion,
        props: {
          alias: 'matchBetSuggestion',
          isMatchPage: true,
          label: TranslationStore.getTranslation('match_tabs_bet_suggestion'),
          matchId: this.props.data.contentfulContentPage.matchId,
          matchAnalysisData: this.props.data.contentfulContentPage.matchAnalysisInfo,
          betInfo: this.state.betSuggestion
        },
      });

    return components.map((item, i) => {
      const Block = item.name;
      return <Block key={i} {...item.props} />;
    });
  }

  render() {
    let props = this.props;
    if (props.data && this.state.isLoading === false) {
      props.data.contentfulContentPage = this.createContentPagePropsFromMatch();
      const cookies = new Cookies();
      this.match_tab_alias = this.match_tab_alias ?? cookies.get(props.data.contentfulContentPage.matchId);
    }
    return (
      <div>
        {(!this.state.isLoading && this.state.sportEvent) ? (
          <Page {...props}>
            <div
              className={['match-page', styles.wrapper].join(' ')}
              data-contentful-id={this.matchData && this.matchData.contentful_id ? this.matchData.contentful_id : ''}>
              <section className={styles.matchSection}>
                {this.renderTopSection()}
                <MatchTabs
                selectMatchTabByAlias={this.match_tab_alias}
                matchId={props.data.contentfulContentPage.matchId}>
                {!this.state.isLoading && this.renderTabs()}
              </MatchTabs>

              </section>
            </div>
            {/* {this.renderHiddenMatchWidget()} */}
          </Page>
        ) : <Loader/>}
      </div>
    );
  }
}

export const pageQuery = graphql`
  query MatchById($matchId: String!) {
    contentfulMatch(matchId: { eq: $matchId }) {
      id
      contentful_id
      updatedAt
      node_locale
      matchId
      showBetSuggestion
      betType
      betValue
      suggestedTeam
      mcmPlayTo
      estimationInPercent
      playableFrom
      betLevel
      injuredPlayersAwayTeam {
        id
        absenceReason
        artistName
        name
        position
        rating
        shirtNumber
      }
      injuredPlayersHomeTeam {
        id
        absenceReason
        artistName
        name
        position
        rating
        shirtNumber
      }
      betSuggestionText {
        childMarkdownRemark {
          html
        }
      }
      betAnalysisText {
        childMarkdownRemark {
          html
        }
      }
      beforeMatchText {
        childMarkdownRemark {
          html
        }
      }
      thinkAboutText {
        childMarkdownRemark {
          html
        }
      }
      buzzAboutText {
        childMarkdownRemark {
          html
        }
      }
      scheduled
      tournamentName
      competitorNameHome
      homeTeamDisplayName
      homeTeamLogo {
        file {
          url
        }
      }
      competitorNameAway
      awayTeamDisplayName
      awayTeamLogo {
        file {
          url
        }
      }
      competitorIdHome
      competitorIdAway
      venueName
      city
      country
      sport
      sportId
      seasonId
      tournamentId
      tvLogo {
        file {
          url
        }
      }
    }

    contentfulMatchEventsConfiguration {
      eventsList {
        type
        eventType
        periodNames
        title
        hideInEventsList
        pushNotification
        icon {
          file {
            url
          }
        }
        iconOverrideSport_1 {
          file {
            url
          }
        }
        iconOverrideSport_5 {
          file {
            url
          }
        }
        iconOverrideSport_15 {
          file {
            url
          }
        }
        iconOverrideSport_6 {
          file {
            url
          }
        }
        iconOverrideSport_7 {
          file {
            url
          }
        }
      }
    }
  }
`;

import React, { Component } from 'react';
import styles from './tournament-standings-wrapper.module.less';
import get from 'lodash/get';
import TranslationStore from 'components/stores/translation-store';
import TournamentStandingsService from 'services/tournament-standings-service';
import { liveUpdateInterval } from 'components/stores/live-matches-context';
import TournamentStandingsList from 'components/block-elements/tournament-standings-list/tournament-standings-list';
import StandingsScoringLeagueHockey from 'components/block-elements/standings-scoring-league-hockey/standings-scoring-league-hockey';
import StandingsScoringLeagueSoccer from 'components/block-elements/standings-scoring-league-soccer/standings-scoring-league-soccer';
import StandingsBookingsLeague from 'components/block-elements/standings-bookings-league/standings-bookings-league';
import StandingsBookingsLeagueHockey from 'components/block-elements/standings-bookings-league-hockey/standings-bookings-league-hockey';
import MatchHelper from 'components/helpers/match-helper';
import Loader from 'components/block-elements/loader-css/loader-css';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import LogoHelper from 'components/helpers/logo-helper';
import EventAggregator from 'components/helpers/event-aggregator';
import StandingLiveWidget from 'components/block-elements/standings-table-soccer/standings-table-soccer';
import StandingsHockeyWidget from 'components/block-elements/standings-table-hockey/standings-table-hockey';
import mock from '../../../../src/components/helpers/mock-helper';
import UiRadio from 'components/block-elements/ui-radio/ui-radio';
import Cookies from 'universal-cookie';

const HOME_VALUE = 'HOME';
const AWAY_VALUE = 'AWAY';
const TOTAL_VALUE = 'TOTAL';

const League = "League";
const Conference = "Conference";
const Division = "Division";

const SCORING_LEAGUE_VALUE = 'SCORING_LEAGUE_VALUE';
const BOOKING_LEAGUE_VALUE = 'BOOKING_LEAGUE_VALUE';

export default class TournamentStandingsWrapper extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    cookies.set(props?.id, props.alias, { path: '/', maxAge: 345600 });

    this.buttonLigues = [
      {
        buttonText: Division,
        value: Division,
      },
      {
        buttonText: Conference,
        value: Conference,
      },
      {
        buttonText: League,
        value: League,
      },
    ]

    this.buttonAlterantives = [
      {
        buttonText: TranslationStore.getTranslation("standing_table_total_header_filter"),
        value: TOTAL_VALUE,
        label: TranslationStore.getTranslation("standing_table_total_header_filter")
      },
      {
        buttonText: TranslationStore.getTranslation("standing_table_home_header_filter"),
        value: HOME_VALUE,
        label: TranslationStore.getTranslation("standing_table_home_header_filter")
      },
      {
        buttonText: TranslationStore.getTranslation("standing_table_away_header_filter"),
        value: AWAY_VALUE,
        label: TranslationStore.getTranslation("standing_table_away_header_filter")
      },
    ];

    if (!this.props.isMatchPage) {
      this.buttonAlterantives.push({
        buttonText: MatchHelper.isMatchHockey(this.props.sportId) ? 
        TranslationStore.getTranslation("standing_table_hockey_scoring_header_filter") : TranslationStore.getTranslation("standing_table_soccer_scoring_header_filter"),
        value: SCORING_LEAGUE_VALUE,
      });
      this.buttonAlterantives.push({
        buttonText:  MatchHelper.isMatchHockey(this.props.sportId) ? 
        TranslationStore.getTranslation("standing_table_hockey_booking_header_filter") : TranslationStore.getTranslation("standing_table_soccer_booking_header_filter"),
        value: BOOKING_LEAGUE_VALUE,
      });
    }

    this.state = {
      loadingStandings: true,
      loadingScoringLeague: true,
      loadingBookingsLeague: true,
      activeTab: TOTAL_VALUE,
      activeTabLeague: Division,
      error: '',
      standings: [],
      colors: [],
      homeStandings: [],
      guestStandings: [],
      conferences: {},
      divisions: {},
      conferencecHome: {},
      divisionHome: {},
      conferencesAway: {},
      divisionAway: {},
      allLeagues: []
    };

    this.tournamentId = get(props, 'tournamentId');
    this.sportsId = get(props, 'sportId');
    this.tournamentName = get(props, 'tournamentName');
    this.homeTeamId = get(props, 'homeTeamId');
    this.awayTeamId = get(props, 'awayTeamId');
    this.totalStandingGroups = [];
    this.homeStandingGroups = [];
    this.awayStandingGroups = [];
    this.scoringLeagueStanding = null;
    this.bookingsLeagueStanding = null;

    this.highlightedTeams = [this.homeTeamId, this.awayTeamId];

    this.loaderText = TranslationStore.getTranslation("standing_table_loading_text");
    this.logoClass = LogoHelper.getTournamentLogoClass(props.tournamentId);
  }

  async componentDidMount() {
    await TournamentStandingsService.getLeagueTable(this.props ? this.props.id.substring(this.props.id.lastIndexOf(':') + 1) : 
    this.props.groupId.substring(this.props.groupId.lastIndexOf(':') + 1), this.sportsId.substring(this.sportsId.lastIndexOf(':') + 1), this.props.fullTablePage)
      .then(response => {
        this.setState({standings: response.data.total, activeTab: TOTAL_VALUE, homeStandings: response.data.home, guestStandings: response.data.away, colors: response.data.colors});
        this.dataSet(response.data);

        var res = [].concat(response.data)[0];

        this.setState({ loadingStandings: false });
      })
      .catch(error => {
        this.setState({ error: 'Ingen tabell tillgänglig i denna turnering', loadingStandings: false}); });
    // this.setState({standings: mock.standingsMock, activeTab: TOTAL_VALUE, homeStandings: mock.homeStandingsMock, guestStandings: mock.guestStandingsMock})
    EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: 'Total' });
  }

  dataSet(res) {
    let LeaguesFull = [];

    let data = res;

    res.total.forEach(el => {
      if(LeaguesFull.indexOf(el.divisionName) == -1) {
        LeaguesFull.push(el.divisionName)
      }
      if(LeaguesFull.indexOf(el.conferenceName) == -1 ) {
        LeaguesFull.push(el.conferenceName)
      }
    })

    return this.setState({ 
      conferences: this.getConferenceListTeams(data.total), 
      divisions: this.getDivisionListTeams(data.total), 
      conferencecHome: this.getConferenceListTeams(data.home, true), 
      divisionHome: this.getDivisionListTeams(data.home,  true), 
      divisionAway: this.getDivisionListTeams(data.away, true), 
      conferencesAway: this.getConferenceListTeams(data.away, true), 
      allLeagues: LeaguesFull
    })
  }

  getDivisionListTeams(data, isSortByPoints = false) {
    let divisions = [];

    data.forEach(el => {
      if(divisions.indexOf(el.divisionName) == -1) {
        divisions.push(el.divisionName)
      }
    })

    let divisionTeams = [];

    divisions.forEach(el => {
      let d = {
       standings: data.filter(tot => tot.divisionName == el).sort(function(a, b) {
        return !isSortByPoints ? a.divisionPosition - b.divisionPosition : b.points - a.points;
      }),
       leagueName: el
      }
      divisionTeams.push(d);
    })
    return divisionTeams;
  }

  getConferenceListTeams(data, isSortByPoints = false) {
    let conferencec = [];
    data.forEach(el => {
      if(conferencec.indexOf(el.conferenceName) == -1 ) {
        conferencec.push(el.conferenceName)
      }
    })

    let conferencesTeams = [];
    
    conferencec.forEach(el => {
      let b = { 
        standings: data.filter(tot => tot.conferenceName == el).sort(function(a, b) {
          return !isSortByPoints ? a.conferencePosition - b.conferencePosition : b.points - a.points;
        }),
        leagueName: el
      }
      conferencesTeams.push(b)
    })

    return conferencesTeams;
  }

  compareDivision(a, b) {
    if (a.divisionName < b.divisionName) {
      return -1;
    }
    if (a.divisionName > b.divisionName) {
      return 1;
    }
    // a должно быть равным b
    return 0;
  }

  compareConference(a, b) {
    if (a.conferenceName < b.conferenceName) {
      return -1;
    }
    if (a.conferenceName > b.conferenceName) {
      return 1;
    }
    // a должно быть равным b
    return 0;
  }

  compareLeague(a, b) {
    if(a.ranck < b.ranck) {
      return -1;
    }
    if(a.ranck > b.ranck) {
      return -1;
    }

    return 0;
  }
  

  async liveUpdate() {
    await TournamentStandingsService.getLeagueTable(this.props ? this.props.id.substring(this.props.id.lastIndexOf(':') + 1) : 
    this.props.groupId.substring(this.props.groupId.lastIndexOf(':') + 1)    , this.sportsId.substring(this.sportsId.lastIndexOf(':') + 1), this.props.fullTablePage)
      .then(response => {
            if (!(response.data instanceof Array && response.data.length > 0)) {
              this.setState({ loadingStandings: false });
              return;
            }
                const totalResponse = response.data.find(element => element.standingType === 'total');
                const homeResponse = response.data.find(element => element.standingType === 'home');
                const awayResponse = response.data.find(element => element.standingType === 'away');
        
                this.totalStandingGroups = totalResponse.standingGroups;
                this.homeStandingGroups = homeResponse.standingGroups;
                this.awayStandingGroups = awayResponse.standingGroups;
                this.setState({ loadingStandings: false });
      })
      .catch(error => {})

    if (this.props.liveUpdate) {
      setTimeout(() => this.liveUpdate(true), liveUpdateInterval);
    }
  }

  renderActiveTable() {
    if (this.state.loadingStandings) {
      return (
        <div className={'loader-container'}>
          <Loader />
        </div>
      );
    }

    switch (this.state.activeTab) {
      case TOTAL_VALUE:
        return (
          (this.state.standings.length > 0 && this.state.error.length <= 1) ?  
          (this.props.tournamentId != "e:tournament:24" ?
            <StandingLiveWidget colors={this.state.colors} key={1} isTotalShow={true} {...this.props} standings={this.state.standings} teamsInLigue={3} dropOutTeams={2} /> :
            <StandingsHockeyWidget colors={this.state.colors} key={12} {...this.props} standings={this.state.standings} standingsData={this.state}/>) : 
            <div style={{textAlign: 'center'}}><span>{this.state.error}</span></div>
        );
      case HOME_VALUE:
        return (
          (this.state.homeStandings.length > 0 && this.state.error.length <= 0) ? 
          (this.props.tournamentId != "e:tournament:24" ?
          <StandingLiveWidget key={2}  {...this.props} standings={this.state.homeStandings} /> :
          <StandingsHockeyWidget key={12} {...this.props} standingsTotal={this.state.homeStandings} standingsData={this.state} />
          ) : 
          <div style={{textAlign: 'center'}}><span>{this.state.error}</span></div>
        );
      case AWAY_VALUE:
        return (
          (this.state.guestStandings.length > 0 && this.state.error.length <= 1) ? 
          (this.props.tournamentId != "e:tournament:24" ?
          <StandingLiveWidget key={3}  {...this.props} standings={this.state.guestStandings} /> :
          <StandingsHockeyWidget key={12} {...this.props} standingsTotal={this.state.guestStandings} standingsData={this.state} />) : 
          <div style={{textAlign: 'center'}}><span>{this.state.error}</span></div>
        );
      case SCORING_LEAGUE_VALUE:
        if (this.state.loadingScoringLeague) {
          return (
            <div className={'loader-container'}>
              <Loader />
            </div>
          );
        }
        return (
          MatchHelper.isMatchHockey(this.props.sportId) ?
            <StandingsScoringLeagueHockey key={"standing_scoring_leauge_hockey_3"} standings={this.scoringLeagueStanding} tournamentId={this.tournamentId} /> :
            <StandingsScoringLeagueSoccer key={3} standings={this.scoringLeagueStanding} tournamentId={this.tournamentId} />
        );
      case BOOKING_LEAGUE_VALUE:
        if (this.state.loadingBookingsLeague) {
          return (
            <div className={'loader-container'}>
              <Loader />
            </div>
          );
        }
        return (
          MatchHelper.isMatchHockey(this.props.sportId) ?
            <StandingsBookingsLeagueHockey key={4} standings={this.bookingsLeagueStanding} tournamentId={this.tournamentId} /> :
            <StandingsBookingsLeague key={4} standings={this.bookingsLeagueStanding} tournamentId={this.tournamentId} />
        );
      default:
        return (
          <TournamentStandingsList
            key={"tournament_standing_list_3_"+ this.state.activeTab}
            highlighted={this.highlightedTeams}
            colors={this.state.colors}
            tournamentId={this.tournamentId}
            sportsId={this.sportsId}
            standingGroups={this.totalStandingGroups}
            isMatchPage={this.props.isMatchPage}
          />
        );
    }
  }

  render() {
    return (
      <section className={'block__max-width'}>
        <div>
          {this.tournamentName &&
            <div className={[styles.tournamentHeaderWrapper, this.props.noPadding ? styles.wrapperSmallPadding : 'block__padded'].join(' ')}>
              <div>
                <h4>{this.tournamentName}<div className={styles.liveUpdateLabel}>Tabellen Uppdateras Live</div></h4>
              </div>
              <div className={this.logoClass} />
            </div>
          }


          <div className={styles.buttonWrapper}>
            {this.props.tournamentId == "e:tournament:24" && this.buttonLigues.map((button, index) => (
              <UiBtn
                key={index}
                label={button.buttonText}
                onClick={() => {this.setState({ activeTabLeague: button.value }); EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: button.buttonText });}}
                className={[
                  this.state.activeTabLeague === button.value ? styles.activeButton : styles.inactiveButton,
                  index === 0 && styles.firstButton, styles.alternativeButton,
                ].join(' ')}
              />
            ))}
          </div>
          { this.props.tournamentId != "e:tournament:24" &&
          <div className={styles.buttonWrapper}>
            {this.buttonAlterantives.map((button, index) => (
              <UiBtn
                key={index}
                label={button.buttonText}
                onClick={() => {this.setState({ activeTab: button.value }); EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: button.buttonText });}}
                className={[
                  this.state.activeTab === button.value ? styles.activeButton : styles.inactiveButton,
                  index === 0 && styles.firstButton, styles.alternativeButton,
                ].join(' ')}
              />
            ))}
          </div>
          }

          { this.props.tournamentId == "e:tournament:24" &&
            <div className={styles.radioMainContainer}>
            <div className={styles.radioContainer}>
                { this.buttonAlterantives.map((button, index) => (
                  <div
                  onClick={() => {this.setState({ activeTab: button.value }); EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: button.buttonText });}} 
                      className={styles.radioBox}>                    
                    <div 
                    key={index}
                    className={[
                      styles.radioButtons,
                        ].join(' ')}
                        >
                        <div 
                        className={[
                          this.state.activeTab === button.value ? styles.activeRadioButton : styles.inactiveRadioButton,
                        ]}>
                        </div>
                      </div>
                        <span>{button.buttonText}</span>
                  </div>
                )) }
            </div>
          </div>}

        </div>
        <div className={styles.standingsArea}>{this.renderActiveTable()}</div>
      </section>
    );
  }
}

import React, { Component } from 'react';
import styles from './statistic-match-box.module.less'

class StatisticMatchBox extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div>
        <div className={styles.devider}></div>
        <span className={styles.teamNameText}>{this.props.team.teamName}</span>
        <div className={styles.mainContainer} style={this.props.secondTeam ? {flexDirection: 'row-reverse'} : null}>

          <div className={this.props.secondTeam ? styles.mainboxSecond : styles.mainbox}>
            {this.props.team.enemyTeamScore == this.props.team.homeTeamScore ? 
            <div className={styles.resultBox} style={{background: '#FFC500'}}>O</div> : null}

            {this.props.team.enemyTeamScore < this.props.team.homeTeamScore ? 
            <div className={styles.resultBox} style={{background: '#00C197'}}>V</div> : null}

            {this.props.team.enemyTeamScore > this.props.team.homeTeamScore ? 
            <div className={styles.resultBox} style={{background: '#C1002A'}}>F</div> : null}
          </div>
          <div className={this.props.secondTeam ? styles.scoreboxSecond : styles.scorebox}>
            <span> {this.props.team.homeTeamScore}-{this.props.team.enemyTeamScore} </span>
          </div>

          <div className={this.props.secondTeam ? styles.landTypeSecond : styles.landType}>
            {this.props.team.isHomeMatch ? <span>(H)</span> : <span>(B)</span>}
          </div>
        </div>
        {this.props.secondTeam ? <div className={styles.timeAndLeagueRight}>
          <span className={styles.leagueMainName}>{this.props.team.leagueName}</span> 
          <div className={styles.divider}>|</div>
          <span className={styles.dateText}>{(this.props.team.eventTime).substr(0,10)}</span> 
        </div> : <div className={styles.timeAndLeague}>
          <span className={styles.dateText}>{(this.props.team.eventTime).substr(0,10)}</span> 
          <div className={styles.divider}>|</div>
          <span className={styles.leagueMainName}>{this.props.team.leagueName}</span> 
        </div>}
        {this.props.isLastElement ? <div className={styles.devider}></div> : null}
      </div>
                // teamName : res.homeTeamName,
                // enemyTeamScore: res.homeTeamScore,
                // isHomeMatch: false,
                // homeTeamScore: res.homeTeamScore
    );
  }
}

export default StatisticMatchBox;

import React, { Component } from 'react';
import StatisticWidget from 'components/block-elements/statistic/statistic-widget';
import StatisticH2HWidget from 'components/block-elements/statistic-h2h-widget/statistic-h2h-widget';
import styles from './match-statistic-widget.module.less';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import Cookies from 'universal-cookie';

class MatchStatisticWidget extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    cookies.set(props.matchId, props.alias, { path: '/', maxAge: 345600 });
    this.isFootballSportId = (props.sportId === "e:sport:1");
    this.state = {
      isPropsReady: false,
      buttons: ['Form', 'h2h'],
      currentButton: 'Form'
    };
}

    changeData(val) {
        this.setState({ currentButton: val });
    } 

  render() {

    return (
      <div>
         <div className={styles.buttonContainer}>
            {this.state.buttons ? this.state.buttons.map((val, index) => 
              <UiBtn
                key={index}
                label={val}
                onClick={() => this.setState({ currentButton: val })}
                className={[
                  this.state.currentButton === val ? styles.activeButton : styles.inactiveButton,
                  index === 0 && styles.firstButton,
                  styles.alternativeButton,
                ].join(' ')}
              />
            ) : null}
         </div>
         <div>
            {this.state.currentButton == this.state.buttons[0] ? <StatisticWidget {...this.props}/> : null}
            {this.state.currentButton == this.state.buttons[1] ? <StatisticH2HWidget {...this.props}/> : null}
         </div>
      </div>
    );
  }
}

export default MatchStatisticWidget;

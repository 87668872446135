const staticImagesLinks = {
    mcmLogo: "https://res.cloudinary.com/dzykt0nza/image/upload/v1689594928/MCM/mcmLogo_tbjusf.png",
    dataByEnetpulse: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704409222/MCM/tfo337fyv1wbnwas9zeo.svg",
    facebookIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704419492/MCM/s6mw2lkfojcwhfjpouai.svg",
    instagramIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704419491/MCM/iqkodto0xumqg53hrgv4.svg",
    twitterIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704419491/MCM/yhji1jklf79fmjgv6lqy.svg",
    mailIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704419492/MCM/dkanhqz6tfbaocop0gix.svg",
    landingIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704927213/MCM/hhi8dhbca5s14e7yajir.jpg",
    landFirstLeftImage: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704932155/MCM/bd7eeirp4gwupnxjl2vw.jpg",
    landFirstRightImage: "https://res.cloudinary.com/dzykt0nza/image/upload/v1704933556/MCM/awhm7spockk8bcksj95r.jpg",
};

const staticTimeLineImages = {
    whistleImage: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025385/MCM/nzhxka7g6sz4ktgmu0yx.png",
    redCardImage: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025224/MCM/jluiradftzv6wzgj6ssb.svg",
    yellowRedCardImage: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025223/MCM/gff45att0iu7exr3ggde.svg",
    washerImage: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025223/MCM/nx25sgrwmr0j8uiwrkvu.svg",
    cupIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025222/MCM/p5jn7zujgigbmgky7ebt.png",
    varIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025220/MCM/s78lo3dtohigq8tsvk8j.svg",
    defaultBallIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025218/MCM/qoxkdoxlltsflzj6vfta.svg",
    redCrossIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025218/MCM/hebaafqopjvatujcvtld.svg",
    redBallIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025217/MCM/i4bwfzovcspmbeespqqa.svg",
    yellowCardIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025217/MCM/bi2ubd1qbvgrnuotvxmi.svg",
    greenCheckmarkIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025217/MCM/sxiiodciomxm5bzf0ypv.svg",
    clockIcon: "https://res.cloudinary.com/dzykt0nza/image/upload/v1708025217/MCM/yfpn0jqba1lrxkgjenjs.svg"
}

export  {
    staticImagesLinks,
    staticTimeLineImages
}
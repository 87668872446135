import React, { Component } from 'react';
import styles from './match-analysis.module.less';
import get from 'lodash/get';
import AbsentPlayers from 'components/block-elements/absent-players/absent-players';
import TranslationStore from 'components/stores/translation-store';
import OddsService from 'services/odds-service';
import BestThreeWaysOdds from 'components/block-elements/best-three-ways-odds/best-three-ways-odds';
// import MatchWidget from '../match-widget/match-widget';
import firstImage from 'static/1X2-1.svg';
import secondImage from 'static/1X2-2.svg';
import versusImage from 'static/1X2-X.svg';
import SporteventService from 'services/sport-event-service';
import Cookies from 'universal-cookie';

class MatchAnalysis extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    cookies.set(props.matchId, props.alias, { path: '/', maxAge: 345600 });

    this.state = {
      betType: null,
      bettingCompanies: [],
      teams: [],
      odds: {},
      customTexts: {}
    };

    this.id = get(props, 'matchId');
    this.odds = null;
    this.homeTeamAbsence = get(props, 'matchAnalysisData.homeTeamAbsence');
    this.awayTeamAbsence = get(props, 'matchAnalysisData.awayTeamAbsence');
    this.homeTeam = get(props, 'matchAnalysisData.homeTeam');
    this.competitorIdHome = get(props, 'matchAnalysisData.competitorIdHome');
    this.awayTeam = get(props, 'matchAnalysisData.awayTeam');
    this.competitorIdAway = get(props, 'matchAnalysisData.competitorIdAway');
    this.sportId = get(props, 'matchAnalysisData.sportId');

    this.analysisLabel = TranslationStore.getTranslation('match_analysis_header');
    this.beforeMatchLabel = TranslationStore.getTranslation('match_analysis_before_match_header');
    this.buzzAboutLabel = TranslationStore.getTranslation('match_analysis_buzz_about_header');
    this.thinkAboutLabel = TranslationStore.getTranslation('match_analysis_think_about_header');
    this.absentPlayersLabel = TranslationStore.getTranslation('match_analysis_wounded_absnnt_header');
  }

  async componentDidMount() {
    await Promise.all([
      SporteventService.getWinnerPredictNumber(this.id)
      .then((res) => {
        this.setState({ betType: res.data });
      })
      .catch((err) => {}),
      SporteventService.GetCustomAdminTexts(this.id)
      .then((res) => {
        this.setState({ customTexts: res.data });
      })
      .catch((err) => {
      }),
      SporteventService.getSportEventTeams(this.id)
      .then((res) => {
        this.setState({teams: res.data})
      }).catch((err => {

      })),
      OddsService.getBettingCompanies('', 0, 100)
      .then((res) => {
        this.setState({ bettingCompanies: res.data.data })
      }).catch((err) => {
        console.log(err)
      }),
      OddsService.odds(this.id, this.sportId)
        .then((res) => {
          this.setState({ odds: res.data })
        }).catch((err) => {

        })
    ]);

  }

  render() {
    return (
      <div className={['block_max-width block__padded', styles.matchAnalysis].join(' ')}>
        {this.state.customTexts?.matchAnalizeText && (
          <div className={styles.matchAnalysisTextBlock}>
            <div className={styles.threeWaysLable}>
              <div>{this.analysisLabel && <h4>{this.analysisLabel}</h4>}</div>
              <div>
                {this.state.betType == 0 && <img className={styles.betImage} src={firstImage}></img>}
                {this.state.betType == 1 && <img className={styles.betImage} src={versusImage}></img>}

                {this.state.betType == 2 && <img className={styles.betImage} src={secondImage}></img>}
              </div>
            </div>
            <div className={styles.textBody} dangerouslySetInnerHTML={{ __html: this.state.customTexts?.matchAnalizeText }}></div>
          </div>
        )}
        {this.state.customTexts?.thinkOfText && (
          <div className={styles.matchAnalysisTextBlock}>
            {this.thinkAboutLabel && <h4>{this.thinkAboutLabel}</h4>}
            <div className={styles.textBody} dangerouslySetInnerHTML={{ __html: this.state.customTexts?.thinkOfText }}></div>
          </div>
        )}
        {this.state.customTexts?.talkingAboutText && (
          <div className={styles.matchAnalysisTextBlock}>
            {this.buzzAboutLabel && <h4>{this.buzzAboutLabel}</h4>}
            <div className={styles.textBody} dangerouslySetInnerHTML={{ __html: this.state.customTexts?.talkingAboutText }}></div>
          </div>
        )}
        {this.state.customTexts?.beforeTheGameStartsText && (
          <div className={styles.matchAnalysisTextBlock}>
            {this.beforeMatchLabel && <h4>{this.beforeMatchLabel}</h4>}
            <div className={styles.textBody} dangerouslySetInnerHTML={{ __html: this.state.customTexts?.beforeTheGameStartsText }}></div>
          </div>
        )}
        {(this.state.odds?.threeWay?.topBookieNameHomeWin != null
        || this.state.odds?.threeWay?.topDrawOddIncreased != null
        || this.state.odds?.threeWay?.topAwayWinOddIncreased != null) && <h4 className={styles.header}>Bäst odds just nu</h4>}
        {!this.state.isOddsLoading && (this.state.odds?.threeWay?.topBookieNameHomeWin != null
        || this.state.odds?.threeWay?.topDrawOddIncreased != null
        || this.state.odds?.threeWay?.topAwayWinOddIncreased != null && this.state.bettingCompanies?.length > 0) && <BestThreeWaysOdds list={this.state.odds.threeWay} betLinks={this.state.bettingCompanies}/>}
        {
          <>
            {this.absentPlayersLabel && <h4>{this.absentPlayersLabel}</h4>}
            <div className={styles.absentMainContainer}>
              <div
                style={{ maxWidth: 500, width: '100%', background: '#f8f8f9', padding: '1rem 1.25rem 2.5rem 1.25rem' }}>
                <AbsentPlayers
                  teams={this.state.teams instanceof Array ? this.state.teams : []}
                  homeTeam={this.homeTeamAbsence instanceof Array ? this.homeTeamAbsence : []}
                  homeTeamName={this.homeTeam}
                  homeTeamLogo={this.props?.homeTeamLogo}
                  competitorIdHome={this.competitorIdHome}
                  awayTeam={this.awayTeamAbsence instanceof Array ? this.awayTeamAbsence : []}
                  awayTeamName={this.awayTeam}
                  awayTeamLogo={this.props?.awayTeamLogo}
                  competitorIdAway={this.competitorIdAway}
                  sportId={this.sportId}
                />
              </div>
            </div>
          </>
        }
      </div>
    );
  }
}

export default MatchAnalysis;

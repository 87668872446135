import React, { Component } from 'react';
import styles from './spread-list.module.less';
import TranslationStore from 'components/stores/translation-store';
import LogoHelper from 'components/helpers/logo-helper';
import firstImage from 'static/firstX.png';
import secondImage from 'static/xTwo.png';
import versusImage from 'static/twelve.png';

export default class SpreadList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newDCdata: [],
    }
  }

  getOdds(bet){
    if(bet.currentOdds){
      return bet.currentOdds.substr(0,4);
    }
    else if(bet.openingOdds){
      return bet.openingOdds.substr(0,4);
    }

    return "";
  }

  componentDidMount() {
    let count = 0;
    let arrayCount = this.props.list[0].odds.length;
    let newArr = [];

    while(count < arrayCount) {
      let newObj = {
        topBookie: this.props.list[0].odds[count].bookieName,
        firstx: this.props.list[0].odds[count]?.oddWin,
        homeOddIncreasedWin: this.props.list[0].odds[count].oddIncreasedWin,
        twelve: this.props.list[1].odds[count]?.oddWin,
        drawOddIncreasedWin: this.props.list[0].odds[count].oddIncreasedWin,
        xtwo: this.props.list[2].odds[count]?.oddWin,
        awayOddIncreasedWin: this.props.list[0].odds[count].oddIncreasedWin,
        payoutPercentage: this.props.list[0].odds[count].payoutPercentage

      };
      newArr.push(newObj);

      count = count + 1;
    }

    this.setState({ newDCdata:  newArr});
  }

  getTrend(bet){
    if(bet === false){
      return <span className={styles.triangleDown}></span>
    }
    else if(bet === true){
      return <span className={styles.triangleUp}></span>
    }

    return <span className={styles.triangleFiller}></span>
  }

  render(){
    if(!this.props.list || this.props.list.length === 0){
      return <p className={styles.description}>{TranslationStore.getTranslation("match_odds_comparsion_odds_not_available")}</p>;
    }

      return (
        <>
        <div className={styles.topText}>
          <span>Tippa två av tre tecken</span>
        </div>
        <div className={styles.mainTopBlockContainer}>
          <div className={styles.rowTopBlockContainer}>
              <div className={styles.colTopBlockContainer}>
                <div className={styles.column}>
                  <div className={styles.rowTopBlockContainer}>
                    <img src={firstImage}></img>
                  </div>
                  <a href={this.props.betLinks[`${this.props.list[0].topBookie}`]} target="_blank" rel="noopener noreferrer"><img src={LogoHelper.getBettingCompanyLogoUrlByName(this.props.list[0].topBookie)} alt="" className={styles.betLogo2way} /></a>
                  <div className={styles.rowTopBlockContainer}>
                      {this.props.list[0].topOdd.toFixed(2)}
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={styles.rowTopBlockContainer}>
                    <img src={versusImage}></img>
                  </div>
                  <a href={this.props.betLinks[`${this.props.list[1].topBookie}`]} target="_blank" rel="noopener noreferrer"><img src={LogoHelper.getBettingCompanyLogoUrlByName(this.props.list[1].topBookie)} alt="" className={styles.betLogo2way} /></a>
                  <div className={styles.rowTopBlockContainer}>
                    {this.props.list[1].topOdd.toFixed(2)}
                  </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.rowTopBlockContainer}>
                      <img src={secondImage}></img>
                    </div>
                    <a href={this.props.betLinks[`${this.props.list[2].topBookie}`]} target="_blank" rel="noopener noreferrer"><img src={LogoHelper.getBettingCompanyLogoUrlByName(this.props.list[1].topBookie)} alt="" className={styles.betLogo2way} /></a>
                    <div className={styles.rowTopBlockContainer}>
                      {this.props.list[2].topOdd.toFixed(2)}
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div className={styles.preTableText}>
          <span>Bäst odds just nu</span>
        </div>
          <ul className={styles.bestOddsListHeader}>
            <div className={styles.leftColumns}>
              <li className={styles.bestOddsHeaderLabel}></li>
              <li className={styles.bestOddsHeaderPayout}>Payout</li>
            </div>
            <div className={styles.rightColumns}>
              <li className={styles.bestOddsHeaderNumbers}>
                1X
              </li>
              <li className={styles.bestOddsHeaderNumbers}>
                12
              </li>
              <li className={styles.bestOddsHeaderNumbers}>
                X2
              </li>
            </div>
          </ul>

            <ul className={styles.list2way}>
              {this.state.newDCdata.map((el,i) =>                 
                <ul key={i} className={styles.bestOddsListBody}>
                    <li className={styles.listItem2way} key={"list_" + i}>
                    <div className={styles.leftColumns}>
                        <li className={styles.bestOddsHeaderLabel}>
                            <a href={this.props.betLinks[`${el.topBookie}`]} target="_blank" rel="noopener noreferrer"><img src={LogoHelper.getBettingCompanyLogoUrlByName(el.topBookie)} alt="" className={styles.betLogo2way} /></a>
                        </li>

                        <li className={styles.bestOddsHeaderPayout}>
                          <span className={styles.payoutText}>{`${el.payoutPercentage?.toFixed(2) ?? 0}%`}</span>
                        </li>
                    </div>
                    </li>

                    <div className={styles.rightColumns}>
                        <li className={`${styles.bestOddsHeaderNumbers} ${el.firstx == this.props.list[0].topOdd ? styles.bestOdd : ''}`}>
                            <a href={this.props.betLinks[`${el.topBookie}`]} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                              <span className={styles.listItemBet2wayOdds}>{el?.firstx?.toFixed(2)}</span>
                              {this.getTrend(el.homeOddIncreasedWin)}
                            </a>
                        </li>
                        <li className={`${styles.bestOddsHeaderNumbers} ${el.twelve == this.props.list[1].topOdd ? styles.bestOdd : ''}`}>
                            <a href={this.props.betLinks[`${el.topBookie}`]} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                              <span className={styles.listItemBet2wayOdds}>{el?.twelve?.toFixed(2)}</span>
                              {this.getTrend(el.drawOddIncreasedWin)}
                            </a>
                        </li>                
                        <li className={`${styles.bestOddsHeaderNumbers} ${el.xtwo == this.props.list[2].topOdd ? styles.bestOdd : ''}`}>
                            <a href={this.props.betLinks[`${el.topBookie}`]} className={styles.listItemUrl2way} target="_blank" rel="noopener noreferrer">
                              <span className={styles.listItemBet2wayOdds}>{el?.xtwo?.toFixed(2)}</span>
                              {this.getTrend(el.awayOddIncreasedWin)}
                            </a>
                        </li>
                    </div>
                  </ul>
                )}
          </ul>
        </>
      )
  }
}
import React, { Component } from 'react';
import styles from './tournament-scoring-wrapper.module.less';
import get from 'lodash/get';
import TranslationStore from 'components/stores/translation-store';
import TournamentStandingsService from 'services/tournament-standings-service';
import MatchHelper from 'components/helpers/match-helper';
import StandingsScoringLeagueSoccer from 'components/block-elements/standings-scoring-league-soccer/standings-scoring-league-soccer';
import StandingsScoringLeagueHockey from 'components/block-elements/standings-scoring-league-hockey/standings-scoring-league-hockey';
import StandingsBookingsLeague from 'components/block-elements/standings-bookings-league/standings-bookings-league';
import StandingsBookingsLeagueHockey from 'components/block-elements/standings-bookings-league-hockey/standings-bookings-league-hockey';
import Loader from 'components/block-elements/loader-css/loader-css';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import LogoHelper from 'components/helpers/logo-helper';
import mock from '../../../../src/components/helpers/mock-helper';
import Cookies from 'universal-cookie';

const SCORING_LEAGUE_VALUE = 'SCORING_LEAGUE_VALUE';
const BOOKING_LEAGUE_VALUE = 'BOOKING_LEAGUE_VALUE';

export default class TournamentScoringWrapper extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    cookies.set(props?.id, props.alias, { path: '/', maxAge: 345600 });

    this.buttonAlterantives = MatchHelper.isMatchHockey(props.sportId)
      ? [
        {
          buttonText: TranslationStore.getTranslation("standing_table_hockey_scoring_header_filter"),
          value: SCORING_LEAGUE_VALUE,
        },
        {
          buttonText: TranslationStore.getTranslation("standing_table_hockey_booking_header_filter"),
          value: BOOKING_LEAGUE_VALUE,
        },
      ]
      : [
        {
          buttonText: TranslationStore.getTranslation("standing_table_soccer_scoring_header_filter"),
          value: SCORING_LEAGUE_VALUE,
        },
        {
          buttonText: TranslationStore.getTranslation("standing_table_soccer_booking_header_filter"),
          value: BOOKING_LEAGUE_VALUE,
        },
      ];

    this.state = {
      loadingScoringLeague: true,
      loadingBookingsLeague: true,
      activeTab: SCORING_LEAGUE_VALUE,
      scoringLeagueStanding: []
      // playerStandings: mock.playerBombarderd,
      // standingsBookingsSoccer: mock.standingsBookingsSoccer
    };
    // TournamentStandingsService.getTopScorers(this.props.sportEvent.id.substring(this.props.sportEvent.id.lastIndexOf(':') + 1))
    //   .then(response => {
    //     if (!(response instanceof Array && response.length > 0)) {
    //       this.setState({ loadingScoringLeague: false });
    //       return;
    //     }

    //     this.playerStandings = response;
    //     this.setState({ playerStandings: response });
    //     this.setState({ loadingScoringLeague: false });
    //   })
    //   .catch(error => {});

    // TournamentStandingsService.getCardsScore(this.props.sportEvent.id.substring(this.props.sportEvent.id.lastIndexOf(':') + 1))
    //   .then(response => {
    //     if (!(response instanceof Array && response.length > 0)) {
    //       this.setState({ loadingBookingsLeague: false });
    //       return;
    //     }

    //     this.bookingsLeagueStanding = response;
    //     this.setState({ bookingsLeagueStanding: response });
    //     this.setState({ loadingBookingsLeague: false });
    //   })
    //   .catch(error => {});

    this.tournamentId = get(props, 'tournamentId');
    this.sportsId = get(props, 'sportId');
    this.tournamentName = get(props, 'tournamentName');
    this.homeTeamId = get(props, 'homeTeamId');
    this.awayTeamId = get(props, 'awayTeamId');
    this.totalStandings = null;
    this.homeStandings = null;
    this.awayStandings = null;
    this.outcomeRows = null;
    this.bookingsLeagueStanding = null;

    this.highlightedTeams = [this.homeTeamId, this.awayTeamId];

    this.loaderText = TranslationStore.getTranslation("standing_table_loading_text");
    this.logoClass = LogoHelper.getTournamentLogoClass(props.tournamentId);
  }

  componentDidMount() {
    TournamentStandingsService.getTopScorers(this.props?.id.substring(this.props?.id.lastIndexOf(':') + 1), this.props.sportId.substring(this.props.sportId.lastIndexOf(':') + 1))
      .then(response => {
        if (!(response.data instanceof Array && response.data.length > 0)) {
          this.setState({ loadingScoringLeague: false });
          return;
        }
        this.playerStandings = response.data;
        this.setState({ scoringLeagueStanding: response.data });
        this.setState({ loadingScoringLeague: false });
      })
      .catch(error => {})
    TournamentStandingsService.getCardsScore(this.props?.id.substring(this.props?.id.lastIndexOf(':') + 1), this.props.sportId == 'e:sport:5')
      .then(response => {
        if (!(response.data instanceof Array && response.data.length > 0)) {
          this.setState({ loadingBookingsLeague: false });
          return;
        }
        this.bookingsLeagueStanding = response.data;
        this.setState({ standingsBookingsSoccer: response.data });
        this.setState({ loadingBookingsLeague: false });
      })
      .catch(error => {})
  }

  renderActiveTable() {
    if (this.state.loadingScoringLeague) {
      return (
        <div className={'loader-container'}>
          <Loader />
        </div>
      );
    }

    switch (this.state.activeTab) {
      case SCORING_LEAGUE_VALUE:
        if (this.state.loadingScoringLeague) {
          return (
            <div className={'loader-container'}>
              <Loader />
            </div>
          );
        }
        if (MatchHelper.isMatchHockey(this.props.sportId)) {
          return (
            <StandingsScoringLeagueHockey
              key={0}
              standings={this.state.scoringLeagueStanding}
              tournamentId={this.tournamentId}
            />
          );
        }
        else if(MatchHelper.isMatchFootball(this.props.sportId)) {
          return (
            <StandingsScoringLeagueSoccer key={0} standings={this.state.scoringLeagueStanding} tournamentId={this.tournamentId} />
          );
        }

      case BOOKING_LEAGUE_VALUE:
        if (this.state.loadingBookingsLeague) {
          return (
            <div className={'loader-container'}>
              <Loader />
            </div>
          );
        }
        if (MatchHelper.isMatchHockey(this.props.sportId)) {
          return (
            <StandingsBookingsLeagueHockey
              key={1}
              standings={this.bookingsLeagueStanding}
              tournamentId={this.tournamentId}
            />
          );
        } else if(MatchHelper.isMatchFootball(this.props.sportId))
        {
          return (
            <StandingsBookingsLeague key={1} standings={this.state.standingsBookingsSoccer} tournamentId={this.tournamentId} />
          );
        }

      default:
        return (
          <StandingsScoringLeagueSoccer key={2} standings={this.state.scoringLeagueStanding} tournamentId={this.tournamentId} />
        );
    }
  }

  render() {
    return (
      <section className={'block__max-width'}>
        <div>
          {this.tournamentName && (
            <div className={styles.tournamentHeaderWrapper}>
              <div className={this.props.noPadding ? '' : 'block__padded'}>
                <h4>{this.tournamentName}</h4>
              </div>
              <div className={this.logoClass} />
            </div>
          )}
        </div>
        <div className={styles.buttonWrapper}>
          {this.buttonAlterantives.map((button, index) => (
            <UiBtn
              key={index}
              label={button.buttonText}
              onClick={() => this.setState({ activeTab: button.value })}
              className={[
                this.state.activeTab === button.value ? styles.activeButton : styles.inactiveButton,
                index === 0 && styles.firstButton,
                styles.alternativeButton,
              ].join(' ')}
            />
          ))}
        </div>
        <div className={styles.standingsArea}>{this.renderActiveTable()}</div>
      </section>
    );
  }
}

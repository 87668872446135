import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import styles from './horizontal-stacked-bar-chart.module.less';

class HorizontalStackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }
    
  componentDidUpdate() {
    this.myChart.data.datasets[0].data = [this.props.data[0]];
    this.myChart.data.datasets[1].data = [this.props.data[1]];
    this.myChart.update();
  }
    
  componentDidMount() {
    this.myChart = new Chart(this.chartRef.current, {
      type: 'bar',
      data: {
        labels: ['bar'],
        datasets: [
          {
            label: 'Dataset 1',
            data: [this.props.data[0]],
            backgroundColor: this.props.colors[0],
          },
          {
            label: 'Dataset 2',
            data: [this.props.data[1]],
            backgroundColor: this.props.colors[1],
          }
        ]
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false},
          tooltip: {enabled: false},
          
        },
        scales: {
          x: {
            stacked: true,
            display: false,
            grid: {display:false}
          },
          y: {
            stacked: true,
            display: false,
            grid: {display:false}
          }
        }
      }
    });
  }
    
  render() {
    return <div className={styles.canvasContainer}><canvas ref={this.chartRef} /></div>;
  }
}

export default HorizontalStackedBarChart;

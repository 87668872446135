import React, { Component } from 'react';
import styles from './match-teams-list.module.less';

export default class MatchTeamsList extends Component {

  isValidTeamListProperty(attr){
    return attr && (attr === "substitutes" || attr === "players")
  }
  
  render(){
    if(!this.isValidTeamListProperty(this.props.teamListProperty)){
      return "";
    }

    let sortOrder = ["GK","DF","MF","FW"];
    let teams = this.props.teams.map(team => team);

    teams.map((team,i) => {
      team.players.sort((a, b) => {
        
        let indexA = sortOrder.indexOf(a.type);
        let indexB = sortOrder.indexOf(b.type);
        let jerseyNumberA = a.jerseyNumber > 0 || a.jerseyNumber === 0 ? a.jerseyNumber : 9999999;
        let jerseyNumberB = b.jerseyNumber > 0 || b.jerseyNumber === 0 ? b.jerseyNumber : 9999999;

        if (indexA < indexB || (indexA === indexB && jerseyNumberA <= jerseyNumberB)){ return -1;}
        else if(indexA > indexB || (indexA === indexB && jerseyNumberA > jerseyNumberB)){ return 1; }
        else{ return 0; }
      });
      return team;
    });

    return (<div className={styles.playerListWrapper}>
              <h2 className={[styles.playerListHeadline, 'h4'].join(" ")}>{this.props.listHeader}</h2>
              <div className={styles.playerListHomeTeam}>
                { 
                    this.props.competitorIdHome ? 
                    <img alt={`${this.props.homeTeamName} logo`} src={this.props.homeTeamLogo} className={styles.playerListHomeTeamLogotype} /> : "" 
                }
              </div>
              <div className={styles.playerListAwayTeam}>
                { 
                    this.props.competitorIdAway ? 
                    <img alt={`${this.props.awayTeamName}`} src={this.props.awayTeamLogo} className={styles.playerListAwayTeamLogotype} /> : "" 
                }
              </div>
              {
                this.props.teams.map((team,i) => <ul key={this.props.teamListProperty + "_list_" + i} className={[i ? styles.playerListAway : styles.playerListHome ,'secondary-font-regular'].join(" ")}>
                { 
                  team[this.props.teamListProperty].map(player => i === 0 ? <li className={styles.playerName} key={ this.props.teamListProperty + "_list-item-" + player.name}>
                          <span className={styles.playerListItemJerseyNumber}>{player.jerseyNumber}</span>
                          <span className={styles.playerListItemPositionShort}>{player.type}</span>
                          <span className={styles.playerListItemName}>{player.name?.length > 20 ? `${(player.name?.substring(0, 1))}. ${player.name?.split(' ')[1]}` : player.name}</span>
                        </li> : <li className={styles.playerName} key={ this.props.teamListProperty + "_list-item-" + player.name}>
                          <span className={styles.playerListItemName}>{player.name?.length > 20 ? `${(player.name?.substring(0, 1))}. ${player.name?.split(' ')[1]}` : player.name}</span>
                          <span className={styles.playerListItemPositionShort}>{player.type}</span>
                          <span className={styles.playerListItemJerseyNumber}>{player.jerseyNumber}</span>
                  </li>) 
                }</ul>)
              }
          </div>)
  }
} 
import React, { Component } from 'react';
import CircularProgress from 'components/blocks/circle-progress-bar/circle-progress-bar';
import MatchHelper from 'components/helpers/match-helper';
import StatisticMatchBox from 'components/block-elements/statistic-match-box/statistic-match-box';
import styles from './statistic-widget.module.less';
import StatisticService from 'services/statistics-service';
import SportEventService from 'services/sport-event-service';

class StandingWidget extends Component {
  constructor(props) {
    super(props);
    this.isFootballSportId = (props.sportId === "e:sport:1");
    this.state = {
      isPropsReady: false,
      teamsLineups: [],
      firstTeamScore: 0,
      secondTeamScore: 0,
      firstTeamEnemies: [],
      secondTeamEnemies: [],
      maxTeamsInLigue: 20,
      firstTeamPosition: 5,
      secondTeamPosition: 20,
      error: ''
    };


  }

  async componentDidMount() {
    await Promise.all([
     SportEventService.getFormStatistic(this.props.matchId)
      .then(res => {
        this.setState({ firstTeamResult: res.data.homeTeamStatistics, 
          secondTeamResult: res.data.awayTeamStatistics, 
          firstTeamPosition: res.data.homeTeamLeaguePosition, 
          secondTeamPosition: res.data.awayTeamLeaguePosition,
          maxTeamsInLigue: res.data.totalTeams })
      }).catch(err => {

      })
    ]);

    if(this.state.firstTeamResult?.length > 0) {
      this.state.firstTeamResult.map(res => {
  
        if(res.homeTeamId == this.props.competitorIdHome) {
          if(res.homeTeamScore > res.awayTeamScore) {
            this.setState({firstTeamScore: this.state.firstTeamScore + 3})
          } else if (res.homeTeamScore == res.awayTeamScore) {
            this.setState({firstTeamScore: this.state.firstTeamScore + 1})
          }
        } else if (res.awayTeamId == this.props.competitorIdHome) {
          if(res.awayTeamScore > res.homeTeamScore ) {
            this.setState({firstTeamScore: this.state.firstTeamScore + 3})
          } else if (res.awayTeamScore == res.homeTeamScore ) {
            this.setState({firstTeamScore: this.state.firstTeamScore + 1})
          }
        }
      })
    }

    if(this.state.secondTeamResult?.length > 0) {
        this.state.secondTeamResult.map(res => {
    
          if(res.homeTeamId == this.props.competitorIdAway) {
            if(res.homeTeamScore > res.awayTeamScore) {
              this.setState({secondTeamScore: this.state.secondTeamScore + 3})
            } else if (res.homeTeamScore == res.awayTeamScore) {
              this.setState({secondTeamScore: this.state.secondTeamScore + 1})
            }
          } else if(res.awayTeamId == this.props.competitorIdAway) {
            if(res.homeTeamScore < res.awayTeamScore) {
              this.setState({secondTeamScore: this.state.secondTeamScore + 3})
            } else if (res.homeTeamScore == res.awayTeamScore) {
              this.setState({secondTeamScore: this.state.secondTeamScore + 1});
            }
          }
        })
    }


    let firstteamEnemies = [];
    let secondteamEnemies = [];
    if(this.state.firstTeamResult && this.state.firstTeamResult?.length > 0 && this.state.secondTeamResult && this.state.secondTeamResult?.length > 0) {
      this.state.firstTeamResult.map(res => {
        if(res.homeTeamId == this.props.competitorIdHome) {
          firstteamEnemies.push({
            teamName: res.awayTeamName,
            enemyTeamScore: res.awayTeamScore,
            isHomeMatch: true,
            homeTeamScore: res.homeTeamScore,
            eventTime: res.eventTime,
            leagueName: res.leagueName
          })
        }
        else if(res.awayTeamId == this.props.competitorIdHome) {
          firstteamEnemies.push({
            teamName : res.homeTeamName,
            enemyTeamScore: res.homeTeamScore,
            isHomeMatch: false,
            homeTeamScore: res.awayTeamScore,
            eventTime: res.eventTime,
            leagueName: res.leagueName
          })
        }
      })
  
      this.state.secondTeamResult.map(res => {
        if(res.homeTeamId == this.props.competitorIdAway) {
          secondteamEnemies.push({
            teamName: res.awayTeamName,
            enemyTeamScore: res.awayTeamScore,
            isHomeMatch: true,
            homeTeamScore: res.homeTeamScore,
            eventTime: res.eventTime,
            leagueName: res.leagueName
          })
        }
        else {
          secondteamEnemies.push({
            teamName : res.homeTeamName,
            enemyTeamScore: res.homeTeamScore,
            isHomeMatch: false,
            homeTeamScore: res.awayTeamScore,
            eventTime: res.eventTime,
            leagueName: res.leagueName
          })
        }
      })
  
      this.setState({firstTeamEnemies: firstteamEnemies, secondteamEnemies: secondteamEnemies })
    }

  }

  render() {

    return (
      <div>
        { this.state.error.length < 1 ? <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px', width: '250px'}}>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  <div style={{display: 'flex', justifyContent: 'center'}} className={styles.teamLogo}>
                    <img src={this.props.homeTeamLogo} alt={`${this.props.homeTeamName} logo`} className={styles.teamLogoImage} />
                  </div>
                  <div className={styles.topTeamName} style={{textAlign: 'center'}}>{(this.props.homeTeamDisplayName != null && this.props.homeTeamDisplayName.length > 0) ? this.props?.homeTeamDisplayName : this.props.competitorNameHome}</div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={this.props.awayTeamLogo} alt={`${this.props.awayTeamName} logo`} className={styles.teamLogoImage} />
                  </div>
                  <div className={styles.topTeamName} style={{textAlign: 'center'}}>{(this.props.awayTeamDisplayName != null && this.props.awayTeamDisplayName.length > 0) ? this.props?.awayTeamDisplayName : this.props.competitorNameAway}</div>
                </div>
            </div>
          </div>
          <div className={styles.mainText}>
            <h4 className={styles.mainTextBox}>Form</h4>
          </div>
          <div className={styles.ligaPositionContainer} style={{ textAlign: 'center', marginTop: '20px' }}>
            <span className={styles.ligaPositionText}>LIGAPOSITION</span>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
            <CircularProgress
              size={100}
              percentagePlus
              strokeWidth={15}
              percentage={this.state.firstTeamResult ? Math.round(this.state.firstTeamScore / (this.state.firstTeamResult.length * 3) * 100)  : 0}
              color="#ef1816"
              />
            {this.state.firstTeamPosition > 0 && <>
              <div style={{width: "2px", height: "100px", background: '#D8D8D8', marginLeft: '21px'}} />
              <div className={styles.boxStyle} style={{marginTop: `${(this.state.firstTeamPosition * 100 / this.state.maxTeamsInLigue) - 10}px`}}>
                <span>{this.state.firstTeamPosition}</span>
              </div>
            </>}
            
            {this.state.secondTeamPosition > 0 && <>
              <div style={{width: "2px", height: "100px", background: '#D8D8D8', marginLeft: '16px'}} />
              <div className={styles.boxStyle} style={{marginTop: `${(this.state.secondTeamPosition * 100 / this.state.maxTeamsInLigue) - 10}px`, marginRight: '21px'}}>
                <span>{this.state.secondTeamPosition}</span>
              </div>
            </>}
            <CircularProgress
              size={100}
              strokeWidth={15}
              percentage={this.state.secondTeamResult ? Math.round(this.state.secondTeamScore / (this.state.secondTeamResult.length * 3) * 100) : 0}
              color="#ef1816"
              />
          </div>

          <div className={styles.ligaPositionContainer} style={{ textAlign: 'center', marginTop: '20px' }}>
            <span className={styles.ligaPositionText}>{'FEM SENASTE MATCHERNA'}</span>
          </div>

          <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '14px', marginBottom: '40px' }}>
          { (this.state.firstTeamEnemies && this.state.firstTeamEnemies.length > 0) ?  <div id='1' style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                {this.state.firstTeamEnemies.map((val,key) => 
                    <StatisticMatchBox team={val} isLastElement={key == this.state.firstTeamEnemies.length - 1}/>
                ) }
             </div> : null}

             { (this.state.secondteamEnemies && this.state.secondteamEnemies.length > 0) ? <div id='2' style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', textAlign: 'end'}}>
                {this.state.secondteamEnemies.map((val, key) => 
                    <StatisticMatchBox secondTeam team={val} isLastElement={key == this.state.secondteamEnemies.length - 1}/>
                ) }
             </div> : null}
          </div>

        </div> : <div style={{textAlign: 'center'}}><span>{this.state.error}</span></div>}
      </div>
    );
  }
}

export default StandingWidget;

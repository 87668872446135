import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
/*eslint-disable-next-line no-unused-vars*/
import styles from './tab-transition.less';

export const timeout = 100;

export default class TabTransition extends Component {
  render() {
    const { children, direction } = this.props;

    return (
      <TransitionGroup className={'tab-content'}>
        {children instanceof Array &&
          children.length &&
          children.map(item => (
            <CSSTransition
              key={item.key}
              timeout={{
                enter: timeout,
                exit: timeout,
              }}>
              {status => (
                <div
                  className={`transition-wrapper transition-wrapper_${status} transition-wrapper_${
                    direction > 0 ? 'forward_' : 'backward_'}${status}`}
                >
                  {item}
                </div>
              )}
            </CSSTransition>
          ))}
      </TransitionGroup>
    );
  }
}

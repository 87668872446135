import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import MatchHelper from 'components/helpers/match-helper';
import styles from './match-widget.module.less';
import Timeline from 'components/block-elements/timeline/timeline';
import FootballMatchWidget from 'components/block-elements/football-match-widget-statistic/football-match-widget-statistic';
import TranslationStore from 'components/stores/translation-store';
import LandInformationWidget from 'components/block-elements/land-information-widget/land-information-widget';
import SportEventService from 'services/sport-event-service';
import UiBtn from 'components/block-elements/ui-btn/ui-btn';
import EventAggregator from 'components/helpers/event-aggregator';
import ReferatWidget from '../referat-widget/referat-widget';

class MatchWidget extends Component {
  static propTypes = {
    matchId: PropTypes.string.isRequired,
    widgetType: PropTypes.oneOf([
      'player.comparison',
      'match.headToHead',
      'match.statistics',
      'match.scoreboard',
      'season.liveTable',
      'match.verticalTimeline',
      'match.momentum',
      'season.toplists',
      'match.matchList',
      'match.lmtEssential',
      'match.odds',
    ]).isRequired,
    elementId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    const HANDELSER_VALUE = 'HANDELSER';
    const LIVESTATISTIK_VALUE = 'LIVESTATISTIK';
    const REFERAT_VALUE = 'REFERAT';


    this.matchId = MatchHelper.getCleanSportRadarId(props.matchId);
    this.seasonId = MatchHelper.getCleanSportRadarId(props.seasonId);
    this.widgetType = get(props, 'widgetType');
    this.isFootballSportId = (props.sportId === "e:sport:1");
    this.state = {
      isPropsReady: false,
      timelines: [],
      teamsLineups: [],
      timeoutId: null,
      venue: null,
      periodScores: [],
      timelineTimerId: null,
      activeTab: HANDELSER_VALUE
    };

    this.venue = {};

    this.buttonAlterantives = [
      {
        buttonText: "Händelser",
        value: HANDELSER_VALUE,
      },
      {
        buttonText: "LIVESTATISTIK",
        value: LIVESTATISTIK_VALUE,
      },
      {
        buttonText: "REFERAT",
        value: REFERAT_VALUE,
      },
    ];
  }
  
  async componentDidMount() {
    await Promise.all([
      SportEventService.GetSportEventPeriodScore(this.props.matchId)
        .then(res => {
          this.setState({ periodScores: res.data });
        }).catch(err => {
  
        }),
  
      SportEventService.sportEventVenue(this.props.matchId)
      .then(res => {
        const venue = {...res.data}
        this.setState({venue: venue})
      }),
  
      SportEventService.getTimelines(`e:match:${this.matchId}`, this.props.sportId)
      .then(res => {
        this.setState({ timelines: res.data })
      })
    ]);


    const timelineTimeoutId = setInterval(() => {
      SportEventService.getTimelines(`e:match:${this.matchId}`, this.props.sportId)
      .then(res => {
        this.setState({ timelines: res.data })
      });
    }, 5000);

    
    const timeoutId = setInterval(() => {
      
      SportEventService.sportEventVenue(`e:match:${this.matchId}`)
      .then(res => {
        const venue = {...res.data}
        this.setState({venue: venue})
      })
    }, 300000);
    
    this.setState({ timelineTimerId:  timelineTimeoutId, timeoutId: timeoutId});
  }

  componentWillUnmount() {
    clearInterval(this.state.timelineTimerId);
    clearInterval(this.state.timeoutId);
  }

  render() {
    const { wrapperClassName, className, elementId, title, includeTimeline } = this.props;
    return (
      <div className={wrapperClassName}>
        <div className={elementId === 'lineups' ? 'intrinsic-wrapper' : ''}>
          <div className={`intrinsic-item sr-widget ${className ? className : ""}`}
               id={elementId}
               data-sr-widget={this.widgetType}
               data-sr-match-id={this.matchId}
               data-sr-season-id={this.seasonId}
          />
        </div>
        {(this.state.venue !== undefined && this.state.venue !== null && this.state.venue.name) ? <LandInformationWidget referee={this.props.referee} spectators={this.props.spectators} {...this.state}/> : null}

        <div className={styles.buttonWrapper} style={{marginTop: '30px'}}>
          {this.buttonAlterantives.map((button, index) => (
                <UiBtn
                  key={index}
                  label={button.buttonText}
                  onClick={() => {this.setState({ activeTab: button.value }); EventAggregator.publish("trackEvent", { category: 'Tournament tab', action: this.tournamentName, label: button.buttonText });}}
                  className={[
                    this.state.activeTab === button.value ? styles.activeButton : styles.inactiveButton,
                    index === 0 && styles.firstButton, styles.alternativeButton,
                  ].join(' ')}
                />
              ))}
        </div>
        {(this.state.activeTab == 'HANDELSER' && includeTimeline) &&
          <Timeline {...this.props} timeline={this.state.timelines} periodScores={this.state.periodScores}/>
        }
        {this.state.activeTab == 'LIVESTATISTIK' && <FootballMatchWidget 
          {...this.props}
          isMatchPage={true}
          title={TranslationStore.getTranslation("match_tabs_match_events_live_statistics")} /> 
        }
        {(this.state.activeTab == 'REFERAT') &&
          <ReferatWidget {...this.props} />
        }
      </div>
    );
  }
}

export default MatchWidget;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MatchHelper from 'components/helpers/match-helper';
import styles from './football-match-widget-statistic.module.less';
import SportEventService from 'services/sport-event-service';
import TeamsAndResultBar from 'components/block-elements/teams-and-result-bar/teams-and-result-bar';
import DoughnutChart from 'components/block-elements/doughnut-chart/doughnut-chart';
import HorizontalStackedBarChart from 'components/block-elements/horizontal-stacked-bar-chart/horizontal-stacked-bar-chart';
import BarChart from 'components/block-elements/bar-chart/bar-chart';
import goalIcon from 'styles/icons/icons/goal-hockey.png';
import goalieIcon from 'styles/icons/icons/goalie-hockey.png';
import matchNotStartedIcon from 'styles/icons/icons/match_not_started_icon.svg';
import { liveUpdateInterval } from 'components/stores/live-matches-context';
import CircularProgress from 'components/blocks/circle-progress-bar/circle-progress-bar';
import YellowCard from 'styles/icons/icons/yellow-card.png'
import RedCard from 'styles/icons/icons/red-card.png'
import GoalImage from 'styles/icons/icons/goal.png'
import ColumnChart from '../column-chart/column-chart';

class HockeyMatchWidget extends Component {
  static propTypes = {
    matchId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.matchId = props.matchId;
    this._isMounted = false;
    this.widgetLightRedColor = '#f0bec6';
    this.widgetDarkRedColor = '#d20022';
    this.state = {
      homeTeamStatistics: '',
      awayTeamStatistics:''
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateHockeyWidget();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async updateHockeyWidget() {
    if (!this._isMounted) return;
    SportEventService.sportEventSoccerStats(this.matchId)
    .then(res => {
      const matchInfo = { ...res.data };
      const homeTeamStatistics = res.data.teams.find(i => i.qualifier === 'home').statistics;
      const awayTeamStatistics = res.data.teams.find(i => i.qualifier === 'away').statistics;
      this.setState({ homeTeamStatistics: homeTeamStatistics, awayTeamStatistics: awayTeamStatistics }, () => {
        // Continue updating only if the match is not closed
        !this.isMatchClosed() && setTimeout(() => this.updateHockeyWidget(), liveUpdateInterval);
      });
    })
    .catch(error => {})
  }

  isMatchClosed() {
    return (
      this.props?.status &&
      MatchHelper.isMatchClosed(this.props?.status)
    );
  }

  renderPuckPossession(homePuckPossession, awayPuckPossession) {
    return(
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
        {/* <img className={styles.puckPossessionImageWrapper} src={`/images/sportsicons/Fotboll.svg`} alt='puckImage' />
        <div className={styles.titleLabel}>Ball posession</div> */}
        <div style={{marginBottom: '40px'}} className={styles.puckPossessionInnerWrapper}>
          <div className={styles.flexRow}>
            <div className={styles.targetSpan}>KORT</div>
            <div className={styles.flexCol} style={{justifyContent: 'center'}}>
              <div className={styles.flexRow}>
                <div className={styles.cardRowStyle} style={{marginRight: '4px'}}><img className={styles.cardStyle} src={YellowCard}></img></div>
                <div className={styles.cardsCount}>{this.state.homeTeamStatistics.yellow_cards}</div>
              </div>
              <div className={styles.flexRow}>
                <div className={styles.cardRowStyle}><img className={styles.cardStyle} src={RedCard}></img></div>
                <div className={styles.cardsCount}>{this.state.homeTeamStatistics.red_cards}</div>
              </div>
            </div>
          </div>
          <div className={[styles.valueLabel, styles.homePercentLabel].join(' ')}>{homePuckPossession}%</div>
              <div className={`${styles.horizontalBarChartWrapper}`}>
              <CircularProgress
                size={100}
                mobile
                strokeWidth={15}
                percentage={awayPuckPossession}
                color="#ef1816"
                className={styles.circleMobile}
                isBallPercent={true}
                text="BOLL-"
                text1="INNEHAV"
                />
            </div>
          <div className={[styles.valueLabel, styles.awayPercentLabel].join(' ')}>{awayPuckPossession}%</div>
          <div className={styles.flexRow}>
            <div className={styles.targetSpan}>KORT</div>
            <div className={styles.flexCol} style={{justifyContent: 'center'}}>
              <div className={styles.flexRow}>
                <div className={styles.cardRowStyle} style={{marginRight: '4px'}}><img className={styles.cardStyle} src={YellowCard}></img></div>
                <div className={styles.cardsCount}>{this.state.awayTeamStatistics.yellow_cards}</div>
              </div>
              <div className={styles.flexRow}>
                <div className={styles.cardRowStyle}><img className={styles.cardStyle}src={RedCard}></img></div>
                <div className={styles.cardsCount}>{this.state.awayTeamStatistics.red_cards}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.puckPossessionInnerWrapper} ${styles.Prossession}`}>

          <div className={`${styles.flexRow} ${styles.shotsNumbers}`}>
            <div className={styles.targetSpan}>SKOTT UTANFÖR</div>
            <h2 style={{margin: 'auto 0 auto 0', color: '#C1002A'}} className={styles.scoreChart}>{this.state.homeTeamStatistics.shotoff}</h2>
          </div>
            <div className={`${styles.horizontalBarChartWrapper} ${styles.flexRow}`}>
              <span className={styles.upperText}>Skott på mål</span>
                <div className={styles.goalImage} style={{backgroundImage: `url(${GoalImage})`}}>
                  {this.props ? <div className={`${styles.flexCol} ${styles.spaceBetween} ${styles.scoreBlock}`}>
                    <h2 style={{margin: '0'}} className={styles.scoreChart}>{this.state.homeTeamStatistics.shoton}</h2>
                    <h2 style={{margin: '0'}} className={styles.scoreChart}>-</h2>
                    <h2 style={{margin: '0'}} className={styles.scoreChart}>{this.state.awayTeamStatistics.shoton}</h2>
                  </div> : null}
                </div>
            </div>
            <div className={`${styles.flexRow} ${styles.shotsNumbers}`}>
              <div className={styles.targetSpan}>SKOTT UTANFÖR</div>
              <h2 style={{margin: 'auto 0 auto 0', color: '#C1002A'}} className={styles.scoreChart}>{this.state.awayTeamStatistics.shotoff}</h2>
            </div>
            
        </div>
      </div>
    )
  }

  renderShotsOnGoal(homeShotsOnGoal, awayShotsOnGoal, homeGoals, awayGoals) {
    const homeShootingPercentage = homeGoals/homeShotsOnGoal*100;
    const awayShootingPercentage = awayGoals/awayShotsOnGoal*100;
    return(
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
      <div className={styles.titleLabel}>Skott på mål</div>
      <div className={styles.goalShotsOuterWrapper}>
        {this.renderDoughnutChart(homeShootingPercentage, 'SKOTT\nPROCENT')}
        <div className={styles.goalImageWrapper}>
          <img className={styles.image} src={goalIcon} alt="goalImage"></img>
          <div className={styles.goalShotsInnerWrapper}>
            <span className={styles.shotsNumber}>{homeShotsOnGoal}</span>
            <span className={styles.shotsNumber}>{awayShotsOnGoal}</span>
          </div>
        </div>
        {this.renderDoughnutChart(awayShootingPercentage, 'SKOTT\nPROCENT')}
      </div>
      </div>
    )
  }

  renderDoughnutChart(dataPercentage, label) {
    return (
      <div className={[styles.doughnutChartOuterWrapper].join(' ')}>
        <div className={styles.doughnutChartInnerWrapper}>
          <DoughnutChart
            data={[dataPercentage, 100-dataPercentage]}
            colors={[this.widgetDarkRedColor, this.widgetLightRedColor]}/>
          <div className={[styles.doughnutChartInnerLabel].join(' ')}>{Math.round(dataPercentage*100)/100}%</div>
        </div>
        <div className={styles.doughnutChartOuterLabel}>{label}</div>
      </div>
    )
  }

  renderSavedShots(homeShotsOnGoal, awayShotsOnGoal, homeGoals, awayGoals) {
    const homeSavePercentage = (1-(awayGoals/awayShotsOnGoal))*100;
    const awaySavePercentage = (1-(homeGoals/homeShotsOnGoal))*100;
    return(
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
      <div className={styles.titleLabel}>Målvakter</div>
      <div className={styles.goalShotsOuterWrapper}>
        {this.renderDoughnutChart(homeSavePercentage, 'RÄDDNINGS-\nPROCENT')}
        {/* <div className={styles.goalieImageWrapper}>
          <img className={styles.image} src={goalieIcon} alt="goalieImage"></img>
        </div> */}
        {this.renderDoughnutChart(awaySavePercentage, 'RÄDDNINGS-\nPROCENT')}
      </div>
      </div>
    )
  }

  renderBarChart(dataValue1, dataValue2, label){
    return(
      <div>
        <div className={`${styles.barChartInnerWrapper} ${styles.mobileChart}`}>
            <ColumnChart mobile firstTeamScore={dataValue1 ? dataValue1 : 0} secondTeamScore={dataValue2 ? dataValue2 : 0} />
        </div>

        <div className={`${styles.barChartInnerWrapper} ${styles.desktopChart}`}>

            <ColumnChart firstTeamScore={dataValue1 ? dataValue1 : 0} secondTeamScore={dataValue2 ? dataValue2 : 0} />
        </div>

        <div style={{marginTop: '10px', minHeight: '21px'}} className={styles.barChartLabel}>{label.toUpperCase()}</div>
      </div>
    )
  }

  renderOtherStatistics(homeStatistics, awayStatistics){
    return(
      <div className={[styles.outerWrapper, 'block__padded'].join(' ')}>
        <div className={[styles.barChartsGroupWrapper, 'block__padded'].join(' ')}>
          {(homeStatistics.total_shots !== null || awayStatistics.total_shots !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.total_shots, awayStatistics.total_shots, 'Skott\ntotalt')}</div> : null}
          {(homeStatistics.blocked_shots !== null || awayStatistics.blocked_shots !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.blocked_shots, awayStatistics.blocked_shots, 'Blockerade\nskott')}</div> : null}
          {(homeStatistics.saves !== null || awayStatistics.saves !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.saves, awayStatistics.saves, 'Räddningar')}</div> : null}
          {(homeStatistics.corner !== null || awayStatistics.corner !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.corner, awayStatistics.corner, 'Hörnor')}</div> : null}
          {(homeStatistics.freekick !== null || awayStatistics.freekick !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.freekick, awayStatistics.freekick, 'Orsakade\nfrisparkar')}</div> : null}
          {(homeStatistics.offside !== null || awayStatistics.offside !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.offside, awayStatistics.offside, 'Offside')}</div> : null}
          {(homeStatistics.throwin !== null || awayStatistics.throwin !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.throwin, awayStatistics.throwin, 'Inkast')}</div> : null}
          {(homeStatistics.counter !== null || awayStatistics.counter !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.counter, awayStatistics.counter, 'Farliga\nanfall')}</div> : null}
          {(homeStatistics.cross !== null || awayStatistics.cross !== null) ? 
            <div className={styles.barChartOuterWrapper}>{this.renderBarChart(homeStatistics.cross, awayStatistics.cross, 'Kross\npassningar')}</div> : null}
        </div>
      </div>
    )
  }

  isMatchLiveOrClosed(status){
    return status && (MatchHelper.isMatchLive(status) || MatchHelper.isMatchClosed(status));
  }

  render() {
    const { wrapperClassName, title } = this.props;
    const homeScore = this.props ? this.props.homeScore : '';
    const awayScore = this.props ? this.props.awayScore : '';
    const status = this.props && this.props.status ? this.props.status : '';
    const matchIsLiveOrClosed = this.isMatchLiveOrClosed(status)

    return (
      <div className={wrapperClassName}>
        {title && <div className={'block__padded block__max-width'}>
          <h4 className={[styles.title].join(' ')}>{title}</h4>
        </div>}

        {!matchIsLiveOrClosed ?
          <div className={styles.matchNotStartedContainer}>
            <img className={styles.matchNotStartedImage} src={matchNotStartedIcon} alt="matchNotStartedImage"></img>
            <div className={styles.matchNotStartedText}>Matchen har inte börjat</div>
          </div>
          :
          <>
            {/* <TeamsAndResultBar props={{competitorIdHome, competitorIdAway, homeTeamName, awayTeamName, homeScore, awayScore, status, periodScores}} /> */}
            {this.renderPuckPossession(this.state.homeTeamStatistics.possession, this.state.awayTeamStatistics.possession)}
            {/* {this.renderShotsOnGoal(this.state.homeTeamStatistics.shotsOnGoal, this.state.awayTeamStatistics.shotsOnGoal, this.state.homeTeamStatistics.goals, this.state.awayTeamStatistics.goals )} */}
            {/* {this.renderSavedShots(this.state.homeTeamStatistics.shotsOnGoal, this.state.awayTeamStatistics.shotsOnGoal, this.state.homeTeamStatistics.goals, this.state.awayTeamStatistics.goals )} */}
            {this.state.homeTeamStatistics && this.state.awayTeamStatistics ? this.renderOtherStatistics(this.state.homeTeamStatistics, this.state.awayTeamStatistics) : ''}
          </>
        }

        
      </div>
    );
  }
}

export default HockeyMatchWidget;

import React, { Component } from 'react';
import styles from './land-information-widget.module.less';
import LandImage from 'styles/icons/icons/land-preview.png';

const EvenueLandTypes = [{ text:  "Naturgräs, uppvärmd", value: 0 },
    { text: "Hybridgräs, uppvärmd", value: 1 }, 
    { text: "Naturgräs, ej uppvärmd", value: 2 },
    { text: "Hybridgräs, ej uppvärmd", value: 3 },
    { text: "Konstgräs, uppvärmd", value: 4 },
    { text:  "Konstgräs, ej uppvärmd", value: 5}];

const LandInformationWidget = (props) => {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.colContainer}> 
            <div style={{margin: '0', paddingLeft: '0'}} className={'block__padded block__max-width'}>
            <h4 className={[styles.title].join(' ')}>Arenainfo</h4>
            </div>
            <div>
                {!props.isHockey ? <img width='90' src="https://res.cloudinary.com/dzykt0nza/image/upload/v1703559787/MCM/pmoi01quukk9gczui778.png"></img> 
                : <img width='90' src="https://res.cloudinary.com/dzykt0nza/image/upload/v1703559894/MCM/bmbv2ahqxrsgfwszb90b.png"></img>}
            </div>
        </div> 
        <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Arena:</div>
            <div className={styles.rightInfo}>{props.venue.name}</div>
        </div>
        {props.isHockey && <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Invigdes år:</div>
            {(props.venue.openYear !== null) ? <div className={styles.rightInfo}>{props.venue.openYear}</div> : <div className={styles.rightInfo}>-</div>}
            </div>}        
        {!props.isHockey && <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Underlag:</div>
            {(props.venue.basis !== null) ? <div className={styles.rightInfo}>{EvenueLandTypes.find(elt => elt.value == props.venue.basis).text}</div> : <div className={styles.rightInfo}>-</div>}
        </div>}
        <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Publikkapacitet:</div>
            <div className={styles.rightInfo}>{props.venue.capacity}</div>
        </div>
        <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Dagens publiksiffra:</div>
            {(props.spectators !== null) ? <div className={styles.rightInfo}>{props.spectators}</div> : <div className={styles.rightInfo}>-</div>}
        </div>
        <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Domare:</div>
            {(props.referee !== null) ? <div className={styles.rightInfo}>{props.referee}</div> : <div className={styles.rightInfo}>-</div>}
        </div>
        {/* {!props.isHockey ? <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Underlag</div>
            {(props.venue.basis !== null) ? <div className={styles.rightInfo}>{EvenueLandTypes.find(elt => elt.value == props.venue.basis).text}</div> : <div className={styles.rightInfo}>-</div>}
        </div> : <div className={styles.colContainer}> 
            <div className={styles.leftInfo}>Invigdes år:</div>
            {(props.venue.basis !== null) ? <div className={styles.rightInfo}>{props.venue.openYear}</div> : <div className={styles.rightInfo}>-</div>}
        </div>} */}
      </div>
    );
}

export default LandInformationWidget;

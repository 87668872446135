import React, { Component } from 'react';
import styles from './referat-list-component.module.less';
import yellowCard from 'styles/icons/icons/yellow-card.svg';
import RedCard from 'styles/icons/icons/red-card.svg';
import goalBall from 'styles/icons/icons/goal-soccer-ball.png';
import visper from 'styles/icons/icons/viesper.png';
import matchClock from 'styles/icons/icons/matchklocka.png';

class ReferatLIstComponent extends Component {
  constructor(props) {
    super(props);
  }

  // getIcon = (item,sportId,eventIconForSport,contentfulItem) => {
  //   // debugger
  //   return <div className={[styles.eventItem__edge, getHomeOrAwayClass(item.team)].join(' ')}>
  //     {renderMatchTimeForCurrentSport(item, sportId)}
  //     {eventIconForSport && (<img className={styles.eventItem__icon} src={eventIconForSport} alt={`${contentfulItem.title} icon`} />)}
  //   </div>
  // }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.flexCOntainer}>
          <div className={styles.timecontainer}>
            {this.props.event.elapsed > 0 && <span>{this.props.event.elapsed}</span>}
            {this.props.event.elapsedPlus > 0 && <span> + {this.props.event.elapsedPlus}</span>}
            {this.props.event.elapsed > 0 && <span>{this.props.event.time}'</span>}
          </div>

          <div>
            <span className={styles.referatText}>{this.props.event.text}</span>
          </div>
        </div>

        {this.props.event.eventIncidentId == 406 ||
        this.props.event.eventIncidentId == 1190 ||
        this.props.event.eventIncidentId == 1259 ? (
          <div className={styles.iconEventContainer}>
            <img className={styles.iconEvent} src={goalBall}></img>
          </div>
        ) : null}

        {this.props.event.eventIncidentId == 29 ||
        this.props.event.eventIncidentId == 32 ||
        this.props.event.eventIncidentId == 967 ? (
          <div className={styles.iconEventContainer}>
            <img className={styles.iconEvent} src={visper}></img>
          </div>
        ) : null}

        {this.props.event.eventIncidentId == 280 ? (
          <div className={styles.iconEventContainer}>
            <img className={styles.iconEvent} src={matchClock}></img>
          </div>
        ) : null}

        {this.props.event.eventIncidentId == 25 ||
        this.props.event.eventIncidentId == 70 ||
        this.props.event.eventIncidentId == 129 ||
        this.props.event.eventIncidentId == 163 ||
        this.props.event.eventIncidentId == 176 ||
        this.props.event.eventIncidentId == 203 ||
        this.props.event.eventIncidentId == 322 ||
        this.props.event.eventIncidentId == 325 ||
        this.props.event.eventIncidentId == 962 ||
        this.props.event.eventIncidentId == 963 ||
        this.props.event.eventIncidentId == 1012 ||
        this.props.event.eventIncidentId == 1013 ||
        this.props.event.eventIncidentId == 1105 ||
        this.props.event.eventIncidentId == 1162 ||
        this.props.event.eventIncidentId == 1315 ||
        this.props.event.eventIncidentId == 1316 ||
        this.props.event.eventIncidentId == 1339 ? (
          <div className={styles.iconEventContainer}>
            <img className={styles.iconEvent} src={yellowCard}></img>
          </div>
        ) : null}

        {this.props.event.eventIncidentId == 63 ||
        this.props.event.eventIncidentId == 64 ||
        this.props.event.eventIncidentId == 65 ||
        this.props.event.eventIncidentId == 66 ||
        this.props.event.eventIncidentId == 67 ||
        this.props.event.eventIncidentId == 269 ||
        this.props.event.eventIncidentId == 270 ||
        this.props.event.eventIncidentId == 273 ||
        this.props.event.eventIncidentId == 274 ||
        this.props.event.eventIncidentId == 324 ||
        this.props.event.eventIncidentId == 347 ||
        this.props.event.eventIncidentId == 1016 ||
        this.props.event.eventIncidentId == 1017 ||
        this.props.event.eventIncidentId == 1120 ||
        this.props.event.eventIncidentId == 1163 ||
        this.props.event.eventIncidentId == 1314 ||
        this.props.event.eventIncidentId == 1317 ||
        this.props.event.eventIncidentId == 26 ||
        this.props.event.eventIncidentId == 164 ||
        this.props.event.eventIncidentId == 196 ||
        this.props.event.eventIncidentId == 197 ||
        this.props.event.eventIncidentId == 198 ||
        this.props.event.eventIncidentId == 199 ||
        this.props.event.eventIncidentId == 202 ||
        this.props.event.eventIncidentId == 323 ||
        this.props.event.eventIncidentId == 326 ||
        this.props.event.eventIncidentId == 964 ||
        this.props.event.eventIncidentId == 965 ||
        this.props.event.eventIncidentId == 1014 ||
        this.props.event.eventIncidentId == 1015 ? (
          <div className={styles.iconEventContainer}>
            <img className={styles.iconEvent} src={RedCard}></img>
          </div>
        ) : null}

        {this.props.event.type == 3 ? (
          <div className={styles.iconEventContainer}>
            <img className={styles.iconEvent} src={RedCard}></img>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ReferatLIstComponent;

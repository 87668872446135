import React, { Component } from 'react';
import TranslationStore from 'components/stores/translation-store';
import MatchWidget from 'components/block-elements/match-widget/match-widget';
import HockeyMatchWidget from 'components/block-elements/hockey-match-widget/hockey-match-widget';
import styles from './match-details-tab.module.less';
import Cookies from 'universal-cookie';

const MatchDetailsTab = (props) =>{
  const cookies = new Cookies();

  cookies.set(props.matchId, props.alias, { path: '/', maxAge: 345600 });
    return (
      <div className={'block__max-width'}>
        {props.sportId != "e:sport:5" ? <div>
          <MatchWidget
            {...props}
            isMatchPage={true}
            title={TranslationStore.getTranslation("match_tabs_match_events_live_match_tracker")}
            elementId={'live-match-tracker'}
            widgetType={'match.lmtEssential'}
            includeTimeline={true}
          />
        </div> : null}
        <div>
          {/* {this.props.sportId==="sr:sport:4" */}
          {props.sportId==="e:sport:5"
            ?
            <HockeyMatchWidget 
              {...props}
              isMatchPage={true}
              title={TranslationStore.getTranslation("match_tabs_match_events_live_statistics")}
              />
            : null
            // <MatchWidget
            //   {...this.props}
            //   isMatchPage={true}
            //   title={TranslationStore.getTranslation("match_tabs_match_events_live_statistics")}
            //   elementId={'statistics'}
            //   widgetType='match.statistics'
            // />
            }
        </div>
      </div>
    );
}

export default MatchDetailsTab;

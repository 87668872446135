import React from 'react';
import styles from './odds-for-suggestion.module.less';
import LogoHelper from 'components/helpers/logo-helper';
import BokingAgencyStore from 'components/stores/booking-agency-store'

const oddsForSuggestion = props => {
  
  return props.odds && props.odds.length > 0 ? (
    <div className={styles.oddsWrapper}>
      {props.odds.map(item => {
        let image = BokingAgencyStore.getAgencyLogo(item.bookingAgencyId)
        image = image ? image + "?q=70&w=140" : LogoHelper.getBettingCompanyLogoUrlByName(item.bookingAgencyName)
        return item.currentOdds ? (
          item.deepLink ? <a href={item.deepLink} key={item.id} className={styles.oddsItem} target={"_blank"} rel="noopener noreferrer">
            <img
              key={image}
              className={styles.oddsItemImage}
              src={image}
              alt=''
            />
            <span className={styles.oddsValue}>{parseFloat(item.currentOdds).toFixed(2)}</span>
          </a> : <div href={item.deepLink} key={item.id} className={styles.oddsItem} target={"_blank"}>
            <img
              className={styles.oddsItemImage}
              src={image}
              alt=''
            />
            <span className={styles.oddsValue}>{parseFloat(item.currentOdds).toFixed(2)}</span>
          </div>
        ) : null;
      })}
    </div>
  ) : null;
};

export default oddsForSuggestion;

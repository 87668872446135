import React, { Component } from 'react';
import styles from './referat-widget.module.less';
import ReferatLIstComponent from 'components/block-elements/referat-list-component/referat-list-component';
import MatchHelper from 'components/helpers/match-helper';
import matchNotStartedIcon from 'styles/icons/icons/match_not_started_icon.svg';
import CommentsService from 'services/comments-service';
import Loader from 'components/block-elements/loader-css/loader-css';

class ReferatWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isReferatLoading: false
    };
  }

  isMatchLiveOrClosed(status) {
    return status && (MatchHelper.isMatchLive(status) || MatchHelper.isMatchClosed(status));
  }

  async componentDidMount() {
    this.setState({isReferatLoading: true})
    const numericEventId = this.props.matchId.substring(this.props.matchId.lastIndexOf(':') + 1);
    await CommentsService.getComments(numericEventId)
      .then((res) => this.setState({ data: res.data }))
      .catch((err) => {})
      .finally(() => {this.setState({isReferatLoading: false})})
  }

  render() {
    const status =  this.props?.status ? this.props?.status : '';
    const matchIsLiveOrClosed = this.isMatchLiveOrClosed(status);
    return (
      <div className={styles.mainContainer}>
        <h4>Matchreferat</h4>
        {this.state.isReferatLoading && <Loader relative={true}/>}
        {!matchIsLiveOrClosed ? (
          <div className={styles.matchNotStartedContainer}>
            <img className={styles.matchNotStartedImage} src={matchNotStartedIcon} alt='matchNotStartedImage'></img>
            <div className={styles.matchNotStartedText}>Matchen har inte börjat</div>
          </div>
        ) : (
          <div>
            {this.state.data.map((el) => (
              <ReferatLIstComponent key={el.id} event={el} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default ReferatWidget;

import React, { Component } from 'react';
import MatchHelper from 'components/helpers/match-helper';
import StatisticH2HBox from 'components/block-elements/statistic-h2h-box/statistic-h2h-box'
import styles from './statistic-h2h-widget.module.less';
import CircularProgress from 'components/blocks/circle-progress-bar/circle-progress-bar';
import StatisticService from 'services/statistics-service';

class StandingWidget extends Component {
  constructor(props) {
    super(props);

    this.isFootballSportId = (props.sportId === "e:sport:1");
    this.state = {
      isPropsReady: false,
      teamsLineups: [],
      firstTeamScore: (13 / 15 * 100).toFixed(0),
      secondTeamScore: (9 / 15 * 100).toFixed(0),
      firstTeamEnemies: [],
      secondTeamEnemies: [],
      maxTeamsInLigue: 20,
      firstTeamPosition: 5,
      secondTeamPosition: 20,
      score: 0,
      draw: 0,
      firstTeamWin: 0,
      secondTeamWin: 0,
      teamResults: [],
      error: '',
      sortResults: []
    };


  }

  async componentDidMount() {
    await StatisticService.getParticipiantH2H(MatchHelper.getCleanSportRadarId(this.props.competitorIdHome), MatchHelper.getCleanSportRadarId(this.props.competitorIdAway))
     .then(response => this.setState({ teamResults: response.data }))
     .catch(err => {this.setState({error: 'No statistic available in this tournament'})})
     
    let data = [];

    this.state.teamResults.forEach( tr => {
        data.push( {
          awayTeamId: tr.awayTeamId,
          awayTeamName: tr.awayTeamName,
          awayTeamScore: tr.awayTeamScore,
          eventTime: tr.eventTime,
          homeTeamId: tr.homeTeamId,
          homeTeamName: tr.homeTeamName,
          homeTeamScore: tr.homeTeamScore,
          leagueName: tr.leagueName
        })
    } )

    data.reverse();

    this.setState({ sortResults: data });
     data.forEach(tr => {
      if(tr.homeTeamScore > tr.awayTeamScore) {
        if(tr.homeTeamId == this.props.competitorIdHome) {
          this.setState({firstTeamWin: this.state.firstTeamWin + 1})
        } else if(tr.awayTeamId == this.props.competitorIdHome) {
          this.setState({secondTeamWin: this.state.secondTeamWin + 1})
        }
      } else if (tr.homeTeamScore < tr.awayTeamScore) {
        if(tr.homeTeamId == this.props.competitorIdHome) {
          this.setState({secondTeamWin: this.state.secondTeamWin + 1})
        } else if(tr.awayTeamId == this.props.competitorIdHome) {
          this.setState({firstTeamWin: this.state.firstTeamWin + 1})
        }
      } else if (tr.homeTeamScore == tr.awayTeamScore) {
        this.setState({draw: this.state.draw + 1})
      }
     })

     this.setState({score: (this.state.firstTeamWin * 3) + (this.state.secondTeamWin * 3) + (this.state.draw * 2)})
  }

  render() {

    return (
      <div className={styles.main}>
        <div><h4 className={styles.mainText}>Inbördes statistik</h4></div>
        <div className={styles.simpleStatisticContainer}>
          <div className={`${styles.colContainer} ${styles.mr12}`}>
            <img src={this.props.homeTeamLogo} alt={`${this.props.homeTeamName} logo`} className={styles.teamLogo} />
            <div className={styles.teamPercent}>{Math.round((this.state.firstTeamWin * 3 + this.state.draw) / this.state.score * 100)}%</div>
          </div>
          <div className={`${styles.colContainer} ${styles.mobile} ${styles.mr12}`}>
            <CircularProgress               
              size={100}
              isBallPercent
              strokeWidth={15}
              percentage={100 - Math.round((this.state.secondTeamWin * 3 + this.state.draw) / this.state.score * 100) 
              >= Math.round((this.state.firstTeamWin * 3 + this.state.draw) / this.state.score * 100) 
              ? Math.round((this.state.secondTeamWin * 3 + this.state.draw) / this.state.score * 100) 
              : Math.round((this.state.firstTeamWin * 3 + this.state.draw) / this.state.score * 100)}
              text={'SENASTE'}
              text1={'MÖTEN'}
              mobile
              color="#ef1816"/>
            <div className={styles.teamPercent}>
              {`${this.state.firstTeamWin}-${this.state.draw}-${this.state.secondTeamWin}`}</div>
          </div>

          <div className={styles.colContainer}>
            <img src={this.props.awayTeamLogo} alt={`${this.props.awayTeamName} logo`} className={styles.teamLogo} />
            <div className={styles.teamPercent}>{Math.round((this.state.secondTeamWin * 3 + this.state.draw) / this.state.score * 100)}%</div>
          </div>
        </div>
       { (this.state.sortResults && this.state.sortResults.length > 0 && this.state.error <= 0) ?  this.state.sortResults.map(val => <StatisticH2HBox match={val} /> ) : <span>{this.state.error}</span> }
      </div>
    );
  }
}

export default StandingWidget;

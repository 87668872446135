import styles from './teams-and-result-bar.module.less';
import React from 'react';
import TeamLogoImage from 'components/block-elements/team-logo-image/team-logo-image'
import MatchHelper from 'components/helpers/match-helper';

const TeamsAndResultBar = ({ props }) => {
  const {competitorIdHome, competitorIdAway, homeTeamName, awayTeamName, homeScore, awayScore, status, periodScores} = props;
  return (
    <div className={[styles.teams,'block__padded'].join(" ")}>
        <div className={[styles.team].join(' ')}>
          { 
            competitorIdHome ? 
            <TeamLogoImage competitorId={competitorIdHome} className={styles.teamLogo} /> : "" 
          }
          <h6 className={styles.teamName}>{homeTeamName}</h6>
        </div>
        <div className={styles.scores}>
          <span className={'h2'}>
            {homeScore}
            <span className={styles.scoreDivider}>-</span>
            {awayScore}
          </span>
          
          {
            periodScores instanceof Array && periodScores.length ? 
            <ul className={styles.periodScoresList}>{ periodScores.map((periodScore,i) => <li key={"period_score_"+i} className={styles.periodScoresListItem}>{i === 0 ? '(':''}{periodScore.homeScore}-{periodScore.awayScore}{i === (periodScores.length - 1) ? ')':''}</li>)}</ul> : ""
          }
          
          <span className={['font-tiny-bold', styles.matchStatus].join(' ')}>
            {MatchHelper.getMatchStatusText(status)}
          </span>
          
        </div>
        <div className={[styles.team, styles._away].join(' ')}>
          { 
            competitorIdAway ? 
            <TeamLogoImage competitorId={competitorIdAway} className={styles.teamLogo} /> : "" 
          }
          <h6 className={styles.teamName}>{awayTeamName}</h6>
        </div>
      </div>
  );
};

export default TeamsAndResultBar;

import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import styles from './doughnut-chart.module.less';

class DoughnutChart extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
      }
    
    componentDidUpdate() {
        this.myChart.data.datasets[0].data = this.props.data;
        this.myChart.update();
    }
    
    componentDidMount() {
        this.myChart = new Chart(this.chartRef.current, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: (this.props.data.includes(undefined) || this.props.data.includes(NaN)) ? [0, 100] : this.props.data,
                    backgroundColor:this.props.colors}],
            },
            options: { 
                maintainAspectRatio: false,
                cutout: '65%',
                plugins: {
                    legend: { display: false},
                    tooltip: {enabled: false},
                }
            }
        });
    }
    
    render() {
        return <div className={styles.canvasContainer}><canvas ref={this.chartRef} /></div>;
    }
}

export default DoughnutChart;

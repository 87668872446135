import styles from './absent-players.module.less';
import React, { Component } from 'react';
import TranslationStore from 'components/stores/translation-store';

export default class AbsentPlayers extends Component {
  constructor(props) {
    super(props);
    this.homeTeam = [];
    this.awayTeam = [];

    this.injuryStatusLabel = TranslationStore.getTranslation('absent_players_injury_status');
    this.playerRankingLabel = TranslationStore.getTranslation('absent_players_player_ranking');
    this.suspendedLabel = TranslationStore.getTranslation('absent_players_suspended');
    this.injuryLabel = TranslationStore.getTranslation('absent_players_injured');
    this.otherReasonLabel = TranslationStore.getTranslation('absent_players_other_reason');
    this.uncertainLabel = TranslationStore.getTranslation('absent_players_uncertain');
    this.keyPlayerLabel = TranslationStore.getTranslation('absent_players_key_player');
    this.ordinaryPlayerLabel = TranslationStore.getTranslation('absent_players_ordinary_player');
    this.unimportantPlayerLabel = TranslationStore.getTranslation('absent_players_unimportant_player');
    this.longTermInjuryLabel = TranslationStore.getTranslation('absent_players_longterm_injury');
    this.sickLabel = TranslationStore.getTranslation('absent_players_sick');

    this.iconLookup = {
      Avstängd: '/images/injured-icons/suspended.svg',
      Tveksam: '/images/injured-icons/help-circle-outline.svg',
      Skadad: '/images/injured-icons/injured.svg',
      Sjuk: '/images/injured-icons/sick.svg',
      Annat: '/images/injured-icons/other-reason.svg',
      'star-grey': '/images/injured-icons/star-grey.svg',
      'star-yellow': '/images/injured-icons/star-yellow.svg',
      'star-orange': '/images/injured-icons/star-orange.svg',
    };

    this.ratingLookup = {
      1: { number: 1, iconValue: 'star-yellow' },
      2: { number: 2, iconValue: 'star-yellow' },
      3: { number: 3, iconValue: 'star-yellow' },
      Långtidsskadad: { number: 1, iconValue: 'star-orange' },
      Långtidsskadad2: { number: 2, iconValue: 'star-orange' },
      Långtidsskadad3: { number: 3, iconValue: 'star-orange' },
    };
  }

  getArtistName(player) {
    return /\s/.test(player.name) ? null : player.name;
  }

  getPlayerName(player) {
    return player.firstName && player.lastName ? player.firstName + ' ' + player.lastName : player.name;
  }

  concatMissingPlayers() {
    if (this.props.teams instanceof Array && this.props.teams.length) {
      if (
        this.props.homeTeam instanceof Array &&
        this.props.teams[0].missingPlayers instanceof Array &&
        this.props.teams[0].missingPlayers.length
      ) {
        this.homeTeam = this.props.homeTeam.concat(
          this.props.teams[0].missingPlayers.map((player) => {
            return {
              absenceReason: this.getAbsenceReason(player),
              artistName: this.getArtistName(player),
              id: player.playerId ? player.playerId : '',
              name: this.getPlayerName(player),
              displayPlayerName: player.displayPlayerName ? player.displayPlayerName : '',
              position: player.type ? player.type : '',
              rating: this.getRating(player),
              shirtNumber: player.jerseyNumber ? player.jerseyNumber : 0,
            };
          })
        );
      }

      if (
        this.props.awayTeam instanceof Array &&
        this.props.teams.length > 1 &&
        this.props.teams[1].missingPlayers instanceof Array &&
        this.props.teams[1].missingPlayers.length
      ) {
        this.awayTeam = this.props.awayTeam.concat(
          this.props.teams[1].missingPlayers.map((player) => {
            return {
              absenceReason: this.getAbsenceReason(player),
              artistName: this.getArtistName(player),
              id: player.playerId ? player.playerId : '',
              name: this.getPlayerName(player),
              displayPlayerName: player.displayPlayerName ? player.displayPlayerName : '',
              position: player.type ? player.type : '',
              rating: this.getRating(player),
              shirtNumber: player.jerseyNumber ? player.jerseyNumber : 0,
            };
          })
        );
      }
    }
  }

  getAbsenceReason(player) {
    if (player.status === 'doubtful') {
      return 'Tveksam';
    }

    switch (player.reason) {
      case 'ill':
        return 'Sjuk';
      case 'injured':
        return 'Skadad';
      case 'suspended':
        return 'Avstängd';
      case 'longtime':
        return 'Skadad';
      case 'other':
        return 'Annat';
      default:
        return '';
    }
  }

  getRating(player) {
    if (player.reason === 'longtime') {
      if (player.rank === 1) {
        return 'Långtidsskadad';
      } else if (player.rank === 2) {
        return 'Långtidsskadad2';
      } else if (player.rank === 3) {
        return 'Långtidsskadad3';
      }
    }

    return player.rank ? player.rank : '';
  }

  renderStarsOutOfThree(ratingObject) {
    if (!ratingObject) {
      return [];
    }
    const ratingNumber = ratingObject.number;
    const icon = ratingObject.iconValue;
    const toReturn = [];
    for (let x = 1; x <= 3; x++) {
      if (ratingNumber >= x) {
        toReturn.push(<img key={x} className={styles.star} src={this.iconLookup[icon]} alt={''} />);
      } else {
        toReturn.push(<img key={x} className={styles.star} src={this.iconLookup['star-grey']} alt={''} />);
      }
    }
    return toReturn;
  }

  getParsedPlayerRating(rating) {
    if (isNaN(parseInt(rating))) {
      return 0;
    }

    return parseInt(rating);
  }

  renderAbsenceList(list) {
    if (!(list && list.length > 0)) {
      return null;
    }

    list.sort((a, b) => {
      return this.getParsedPlayerRating(a.rating) === this.getParsedPlayerRating(b.rating)
        ? 0
        : this.getParsedPlayerRating(a.rating) < this.getParsedPlayerRating(b.rating)
        ? 1
        : -1;
    });

    return (
      <ul className={styles.absenceList}>
        {list.map((player) => {
          return (
            <li key={player.id}>
                <div className={`${styles.flexContainer} ${styles.columnDirection}`}>
                    <div className={`${styles.flexContainer} ${styles.rowDirection}`}>
                        <div className={styles.shirtNumber}>{player.shirtNumber ? player.shirtNumber : '-'}</div>
                        <div className={styles.playerName}>{player.displayPlayerName}</div>
                    </div>

                    <div className={`${styles.flexContainer} ${styles.rowDirection}`}>
                        <div className={styles.position}>{'(' + player.position + ')'}</div>
                        <div><span className={styles.playerRating}> {this.renderStarsOutOfThree(this.ratingLookup[player.rating])}</span></div>
                        <img src={this.iconLookup[player.absenceReason]} alt={''} className={styles.absenceReason} />
                    </div>
                </div>
            </li>
          );
        })}
      </ul>
    );
  }

  renderAwayAbsenceList(list) {
    if (!(list && list.length > 0)) {
      return null;
    }

    list.sort((a, b) => {
      return this.getParsedPlayerRating(a.rating) === this.getParsedPlayerRating(b.rating)
        ? 0
        : this.getParsedPlayerRating(a.rating) < this.getParsedPlayerRating(b.rating)
        ? 1
        : -1;
    });

    return (
      <ul className={styles.absenceList}>
        {list.map((player) => {
          return (
            <li key={player.id}>
                <div className={`${styles.flexContainer} ${styles.columnDirection}`}>
                    <div className={`${styles.flexContainer} ${styles.rowDirection}`}>
                        <div className={`${styles.playerName} ${styles.mr0}`}>{player.displayPlayerName}</div>
                        <div className={styles.shirtNumber}>{player.shirtNumber ? player.shirtNumber : '-'}</div>
                    </div>

                    <div className={`${styles.flexContainer} ${styles.rowDirection} ${styles.justifyEnd}`}>
                        <img src={this.iconLookup[player.absenceReason]} alt={''} className={styles.absenceReason} />
                        <div className={styles.ml055rem}><span className={`${styles.playerRating} ${styles.mr04rem}`}> {this.renderStarsOutOfThree(this.ratingLookup[player.rating])}</span></div>
                        <div className={`${styles.position} ${styles.mr0}`}>{'(' + player.position + ')'}</div>
                    </div>
                </div>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    this.concatMissingPlayers();

    return (
      <div className={styles.wrapper}>
        <div className={styles.injuryListWrapper}>
          <div className={styles.homeList}>
            <div className={styles.nameAndLogo}>
              {this.props.competitorIdHome ? (
                <img src={this.props.homeTeamLogo} alt={`${this.props.homeTeamName} logo`} className={styles.teamLogo} />
              ) : (
                ''
              )}
              {this.props.homeTeamName && <h6 className={styles.teamName}>{this.props.homeTeamName}</h6>}
            </div>
            {this.renderAbsenceList(this.homeTeam)}
          </div>
          <div className={styles.awayList}>
            <div className={styles.nameAndLogo}>
              {this.props.competitorIdAway ? (
                <img src={this.props.awayTeamLogo} alt={`${this.props.awayTeamName} logo`} className={styles.teamLogo} />
              ) : (
                ''
              )}
              {this.props.awayTeamName && <h6 className={styles.teamName}>{this.props.awayTeamName}</h6>}
            </div>
            <ul className={styles.absenceList}>{this.renderAwayAbsenceList(this.awayTeam)}</ul>
          </div>
        </div>
        <div className={styles.explanations}>
          <div className={styles.playerRankings}>
            {this.playerRankingLabel && <h6 className={styles.explanationName}>{this.playerRankingLabel}</h6>}
            <ul className={styles.explanationList}>
              <li>
                {this.renderStarsOutOfThree(this.ratingLookup['3'])}{' '}
                <span className={styles.listLabel}>{this.keyPlayerLabel}</span>
              </li>
              <li>
                {this.renderStarsOutOfThree(this.ratingLookup['2'])}{' '}
                <span className={styles.listLabel}>{this.ordinaryPlayerLabel}</span>
              </li>
              <li>
                {this.renderStarsOutOfThree(this.ratingLookup['1'])}{' '}
                <span className={styles.listLabel}>{this.unimportantPlayerLabel}</span>
              </li>
              <li>
                {this.renderStarsOutOfThree(this.ratingLookup['Långtidsskadad'])}{' '}
                <span className={styles.listLabel}>{this.longTermInjuryLabel}</span>
              </li>
            </ul>
          </div>
          <div className={styles.injuryStatus}>
            {this.injuryStatusLabel && <h6 className={styles.explanationName}>{this.injuryStatusLabel}</h6>}
            <ul className={styles.explanationList}>
              <li>
                {<img alt={''} className={styles.suspendedExplanation} src={this.iconLookup['Avstängd']} />}{' '}
                <span className={styles.listLabel}>{this.suspendedLabel}</span>
              </li>
              <li>
                {<img alt={''} src={this.iconLookup['Skadad']} />}{' '}
                <span className={styles.listLabel}>{this.injuryLabel}</span>
              </li>
              <li>
                {<img alt={''} src={this.iconLookup['Annat']} />}{' '}
                <span className={styles.listLabel}>{this.otherReasonLabel}</span>
              </li>
              <li>
                {<img alt={''} src={this.iconLookup['Sjuk']} />}{' '}
                <span className={styles.listLabel}>{this.sickLabel}</span>
              </li>
              <li>
                {<img alt={''} src={this.iconLookup['Tveksam']} />}{' '}
                <span className={styles.listLabel}>{this.uncertainLabel}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

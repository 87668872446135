import React, { Component } from 'react';
import styles from './statistic-h2h-box.module.less';
import TeamLogoImage from 'components/block-elements/team-logo-image/team-logo-image';

class StatisticH2HBox extends Component {
  constructor(props) {
    super(props);
  }


  render() {

    return (
      <div className={styles.main}>
        <div className={styles.timeAndLeague}> 
          <span className={styles.dateText}>{(this.props.match.eventTime).substr(0,10)}</span> 
          <div className={styles.divider}>|</div>
          <span className={styles.leagueText}>{this.props.match.leagueName}</span> 
        </div>

        <div className={styles.mainContainer}>
            <div className={styles.addContainer} style={{marginBottom: '4px'}}>
                <div className={styles.teamElement}>
                    <span>{this.props.match.homeTeamScore}</span>
                </div>
                <div style={{display: 'flex',alignSelf: 'center'}}>
                    <TeamLogoImage competitorId={this.props.match.homeTeamId} className={styles.teamLogo} />
                </div>
                <div>
                    <span className={styles.teamName}>{this.props.match.homeTeamName}</span>
                </div>
            </div>

            <div className={styles.addContainer}>
                <div className={styles.teamElement}>
                    <span>{this.props.match.awayTeamScore}</span>
                </div>
                <div style={{display: 'flex',alignSelf: 'center'}}>
                    <TeamLogoImage competitorId={this.props.match.awayTeamId} className={styles.teamLogo} />
                </div>
                <div>
                    <span className={styles.teamName}>{this.props.match.awayTeamName}</span>
                </div>
            </div>
        </div>
        
      </div>
    );
  }
}

export default StatisticH2HBox;
